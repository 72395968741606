










import { Component, Vue, Watch } from "vue-property-decorator";

interface Vector2d {
	x: number;
	y: number;
}

interface DataRect {
	x: number;
	y: number;
	width: number;
	height: number;
	data: string;
	dataLength: number;
	origin: Vector2d;
}

interface Spoke {
	start: Vector2d;
	end: Vector2d;
}

@Component({
	components: {},
	props: {
		data: Object,
		colors: Array,
		border: Boolean,
		rounded: Boolean,
		bordercolor: String,
		textcolor: String,
		spokecolor: String,
		animate: Boolean
	}

})
export default class NWordStar extends Vue {
	// Test data
	public data: any;
	public colors: any;
	public border: any;
	public rounded: any;
	public bordercolor: any;
	public textcolor: any;
	public spokecolor: any;
	public animate: any;

	public maxSpokes = 20;
	public colorTable: any;

	private parent: any;
	private canvas: any;
	private context: any;
	private spokes: Spoke[] = [];
	private rectangles: DataRect[] = [];

	@Watch("data", { deep: true })
	private watchForDataChanges() {
		this.setup();
	}

	/* @Watch("colors", { deep: true })
	private watchForColorChanges() {
		if(typeof this.colors !== "undefined") {
			this.setupColors();
			this.setup();
		}
	} */

	public created() {
		this.setupColors();
	}

	public mounted() {
		this.canvas = this.$refs.wordstar;
		this.parent = this.$refs.wordstarwrapper;
		this.canvas.width = this.parent.clientWidth;
		this.canvas.height = this.parent.clientHeight;
		this.context = this.canvas.getContext("2d");
		window.addEventListener("resize", this.eventResize);
		this.canvas.addEventListener("mousemove", this.eventMouseMove);
		if(typeof this.data !== "undefined" && typeof this.data === "object" && typeof this.data.intents !== "undefined") {
			this.setup();
		}
	}

	public setupColors() {
		if(typeof this.colors !== "undefined" && Array.isArray(this.colors) && this.colors.length > 0) {
			this.colorTable = this.colors;
			return;
		}
		this.colorTable = [
			"rgb(0,150,255)",
			"rgb(255,150,0)",
			"rgb(0,255,150)",
			"rgb(150,255,150)",
			"rgb(0,160,255)",
			"rgb(255,160,0)",
			"rgb(0,255,160)",
			"rgb(160,255,160)",
			"rgb(0,170,255)",
			"rgb(255,170,0)",
			"rgb(0,255,170)",
			"rgb(170,255,170)",
			"rgb(0,180,255)",
			"rgb(255,180,0)",
			"rgb(0,255,180)",
			"rgb(180,255,180)",
			"rgb(0,190,255)",
			"rgb(255,190,0)",
			"rgb(0,255,190)",
			"rgb(190,255,190)",
		];
	}

	public eventResize() {
		// console.log("Resize triggered");
		this.canvas.width = this.parent.clientWidth;
		// Canvas height should not chage.
		// this.canvas.height = this.parent.clientHeight;
		// console.log(`width: ${this.canvas.width}, height: ${this.canvas.height}`);
		this.setup();
	}

	public eventMouseMove(event: any) {
		// console.log("X:", event.offsetX);
		// console.log("Y:", event.offsetY);
		let rectIndex = -1;
		// Check which rect is in the mouse area in reverse creation order
		for(let i = this.rectangles.length - 1; i > -1; i--) {
			if (event.offsetX > this.rectangles[i].x && event.offsetX < this.rectangles[i].x + this.rectangles[i].width && event.offsetY > this.rectangles[i].y && event.offsetY < this.rectangles[i].y + this.rectangles[i].height) {
				// Redraw rectangle on top
				const ctx = this.context;
				ctx.fillStyle = this.colorTable[i % this.colorTable.length];
				ctx.fillRect(this.rectangles[i].x, this.rectangles[i].y, this.rectangles[i].width, this.rectangles[i].height);
				if (typeof this.border !== "undefined" && this.border === true) {
					if (typeof this.bordercolor !== "undefined") {
						ctx.strokeStyle = this.bordercolor;
					}
					ctx.strokeRect(this.rectangles[i].x, this.rectangles[i].y, this.rectangles[i].width, this.rectangles[i].height);
				}
				if (typeof this.textcolor !== "undefined") {
					ctx.fillStyle = this.textcolor;
				} else {
					ctx.fillStyle = "black";
				}
				ctx.fillText(this.rectangles[i].data, (this.rectangles[i].width / 2) + this.rectangles[i].x, (this.rectangles[i].height - 5 ) + this.rectangles[i].y);
			}
		}
	}

	public beforeDestroy() {
		console.log("Destroying instance");
		window.removeEventListener("resize", this.eventResize);
		this.canvas.removeEventListener("mousemove", this.eventMouseMove);
	}

	public setup() {
		this.spokes = [];
		this.rectangles = [];
		let maxLoop = this.maxSpokes;
		const ctx = this.context;
		const centerX = this.canvas.width / 2;
		const centerY = this.canvas.height / 2;
		console.log(`width: ${this.canvas.width}, height: ${this.canvas.height}`);
		ctx.clearRect(0,0,this.canvas.width, this.canvas.height);

		if(this.data.intents.length < this.maxSpokes) {
			maxLoop = this.data.intents.length;
		}
		// Plot points
		for(let i = 0; i < maxLoop; i++) {
			const ang = Math.PI * 2 / maxLoop;
			const spokeLength = (this.canvas.height / 2) - 20;
			const spoke: Spoke = {
				start: {x: centerX, y: centerY},
				end: {x: 0, y: 0}
			};

			let point = {x: centerX, y: centerY - spokeLength};
			if (i === 0) {
				spoke.end.x = point.x;
				spoke.end.y = point.y;
			} else {
				point = this.rotate({x: centerX, y: centerY - spokeLength}, {x: centerX, y: centerY}, ang * i);
				spoke.end.x = Math.floor(point.x);
				spoke.end.y = Math.floor(point.y);
			}
			this.spokes.push(spoke);
		}
		// Create virtual rectangles
		for(let i = 0; i < maxLoop; i++) {
			const metrics = ctx.measureText(this.data.intents[i]);
			const rect: DataRect = {
				x: 0,
				y: 0,
				width: Math.ceil(metrics.width) + 6,
				height: 10 + 6,
				data: this.data.intents[i],
				dataLength: 0,
				origin: {
					x: 0,
					y: 0
				}
			};
			if (this.spokes[i].end.x > centerX && this.spokes[i].end.y < centerY) {
				rect.origin.x = 0;
				rect.origin.y = rect.height;
			} else if (this.spokes[i].end.x > centerX && this.spokes[i].end.y > centerY) {
				rect.origin.x = 0;
				rect.origin.y = 0;
			} else if (this.spokes[i].end.x < centerX && this.spokes[i].end.y > centerY) {
				rect.origin.x = rect.width;
				rect.origin.y = 0;
			} else if (this.spokes[i].end.x < centerX && this.spokes[i].end.y < centerY) {
				rect.origin.x = rect.width;
				rect.origin.y = rect.height;
			} else if (this.spokes[i].end.x > centerX && this.spokes[i].end.y === centerY) {
				rect.origin.y = rect.height / 2;
				rect.origin.x = 0;
			} else if (this.spokes[i].end.x < centerX && this.spokes[i].end.y === centerY) {
				rect.origin.y = rect.height / 2;
				rect.origin.x = rect.width;
			} else if (this.spokes[i].end.x === centerX && this.spokes[i].end.y > centerY) {
				rect.origin.y = 0;
				rect.origin.x = rect.width / 2;
			} else if (this.spokes[i].end.x === centerX && this.spokes[i].end.y < centerY) {
				rect.origin.y = rect.height;
				rect.origin.x = rect.width / 2;
			} else {
				rect.origin.x = rect.width / 2;
				rect.origin.y = rect.height / 2;
			}
			this.rectangles.push(rect);
		}

		if (this.animate === true) {
			// Animation function removed
		} else {
			this.draw();
		}
	}

	public draw() {
		const ctx = this.context;
		const centerX = this.canvas.width / 2;
		const centerY = this.canvas.height / 2;
		ctx.textAlign = "center";
		if (typeof this.spokecolor !== "undefined") {
			ctx.fillStyle = this.spokecolor;
		} else {
			ctx.fillStyle = "black";
		}
		for(const spoke of this.spokes) {
			ctx.beginPath();
			ctx.moveTo(spoke.start.x, spoke.start.y);
			ctx.lineTo(spoke.end.x, spoke.end.y);
			ctx.stroke();
		}
		let i = 0;
		for(const rect of this.rectangles) {
			// Translate the rectangles
			ctx.fillStyle = this.colorTable[i % this.colorTable.length];
			rect.x = this.spokes[i].end.x - rect.origin.x;
			rect.y = this.spokes[i].end.y - rect.origin.y;
			ctx.fillRect(rect.x, rect.y, rect.width, rect.height);
			if (typeof this.border !== "undefined" && this.border === true) {
				if (typeof this.bordercolor !== "undefined") {
					ctx.strokeStyle = this.bordercolor;
				}
				ctx.strokeRect(this.spokes[i].end.x - rect.origin.x, this.spokes[i].end.y - rect.origin.y, rect.width, rect.height);
			}
			if (typeof this.textcolor !== "undefined") {
				ctx.fillStyle = this.textcolor;
			} else {
				ctx.fillStyle = "black";
			}
			ctx.fillText(rect.data, (rect.width / 2) + rect.x, (rect.height - 5 ) + rect.y);
			i++;
		}
		if (this.data.word === "") {
			this.data.word = "ALL";
		}
		const wordMetric = ctx.measureText(this.data.word);
		ctx.fillStyle = this.colorTable[0];
		ctx.beginPath();
		ctx.arc(centerX, centerY, Math.ceil(wordMetric.width + 5), 0, Math.PI * 2, false);
		ctx.fill();
		if (typeof this.border !== "undefined" && this.border === true) {
			if (typeof this.bordercolor !== "undefined") {
				ctx.strokeStyle = this.bordercolor;
			}
			ctx.stroke();
		}
		if (typeof this.textcolor !== "undefined") {
			ctx.fillStyle = this.textcolor;
		} else {
			ctx.fillStyle = "black";
		}
		ctx.fillText(this.data.word, centerX, centerY + (10 / 2));
	}

	public rotate(point: any, center: any, rotate: number, result: any = {}) {
		const vx = point.x - center.x;
		const vy = point.y - center.y;
		const xAx = Math.cos(rotate);
		const xAy = Math.sin(rotate);
		result.x = vx * xAx - vy * xAy + center.x;
		result.y = vx * xAy + vy * xAx + center.y;
		return result;
	}
}
