








































































































import { Component, Vue } from "vue-property-decorator";
import { Card, Icon, Row, Col } from "view-design";
import Axios from "axios";
import Languages from "../libraries/languages";
@Component({
	components: {
		Card,
		Icon,
		Row,
		Col
	}
})
export default class Intent extends Vue {
	public entities: any = [];
	public values: any = [];
	public entity: string = "";
	public entityConfig: any = {};
	public entityDescription: string = "";
	public entityId: string = "";
	public intentDaily: any = [];
	public languages: any = [];
	public totalDocuments: number = 0;
	public totalEntities: number = 0;
	public userEntity: any = {};
	public iso6391 = new Languages();
	public blockPie: any = [];
	public features = [];

	public async created() {
		// Move this to Vuex
		await Axios.get("/admin/entity").then(res => {
			this.entityConfig = res.data.config;
			if(typeof res.data?.frontend?.features !== "undefined") {
				this.features = res.data.frontend.features;
				console.log(this.features);
			}
		});

		if (typeof this.$route.params.intent === "undefined") {
			this.$router.push("/");
		} else {
			this.userEntity = this.$route.params.entity;
			Axios.post("/stats/intent/entities", {
				start: this.$route.params.start,
				end: this.$route.params.end,
				intent: this.$route.params.intent,
				language: this.$route.params.language,
				excludeLanguage: this.$route.params.excludeLanguage,
				country: this.$route.params.country,
				excludeCountry: this.$route.params.excludeCountry
			}).then(res => {
				this.entities = res.data.entities;
				this.languages = res.data.languages;
				this.totalDocuments = res.data.total[0].total;
				this.totalEntities = res.data.entity_total[0].entity_total;
			});

			Axios.post("/stats/intent/daily", {
				start: this.$route.params.start,
				end: this.$route.params.end,
				intent: this.$route.params.intent,
				language: this.$route.params.language,
				excludeLanguage: this.$route.params.excludeLanguage,
				country: this.$route.params.country,
				excludeCountry: this.$route.params.excludeCountry
			}).then(res => {
				this.intentDaily = res.data;
			});

			Axios.post("/stats/blocks", {
				start: this.$route.params.start,
				end: this.$route.params.end,
				intent: this.$route.params.intent,
				language: this.$route.params.language,
				excludeLanguage: this.$route.params.excludeLanguage,
				country: this.$route.params.country,
				excludeCountry: this.$route.params.excludeCountry
			}).then(res => {
				this.blocks(res.data);
			});
		}
	}

	public backToHome() {
		this.$router.replace({
			name: "Advanced Statistics",
			params: { language: this.$route.params.language }
		});
	}

	public entityClick(event: any, chart: any) {
		console.log(chart);
		if (typeof chart[0] !== "undefined" && typeof chart[0]._model !== "undefined") {
			this.entity = chart[0]._model.label;
			this.entityId = this.entityArray.ids[chart[0]._index];

			Axios.post("/stats/intent/entity", {
				start: this.$route.params.start,
				end: this.$route.params.end,
				intent: this.$route.params.intent,
				entity: this.entityId,
				language: this.$route.params.language,
				excludeLanguage: this.$route.params.excludeLanguage,
				country: this.$route.params.country,
				excludeCountry: this.$route.params.excludeCountry
			}).then(res => {
				this.values = res.data;
			});
		}
	}

	public entityDetails(entity: string) {
		if (typeof this.userEntity.config !== "undefined" && typeof this.userEntity.config.entities[entity] === "object") {
			return this.userEntity.config.entities[entity];
		} else {
			return {
				group: "Unknown",
				hide: false,
				name: {
					// @ts-ignore
					en: entity.replace(/_-/g, " ").capitalize(),
					// @ts-ignore
					fi: entity.replace(/_-/g, " ").capitalize()
				}
			};
		}
	}

	public valueDetails(entity: string, value: string) {
		console.log({ entity, value, raw: this.userEntity.config.entities });
		if (this.entity !== "" && typeof this.userEntity.config !== "undefined" && typeof this.userEntity.config.entities[entity] === "object" && typeof this.userEntity.config.entities[entity].values[value] === "object") {
			return this.userEntity.config.entities[entity].values[value];
		} else {
			return {
				hide: false,
				name: {
					// @ts-ignore
					en: value.replace(/_-/g, " ").capitalize(),
					// @ts-ignore
					fi: value.replace(/_-/g, " ").capitalize()
				}
			};
		}
	}

	public get daily() {
		const output: any = {};

		for (const entry of this.intentDaily) {
			output[entry.date] = entry.total;
		}

		return output;
	}

	public get entityArray() {
		const output: any = [];
		const others: any = [this.$t("Others"), 0, "others"];

		for (const entry of this.entities) {
			const entityDetails = this.entityDetails(entry.entity.toLowerCase());
			if (output.length < 10) {
				if (!entityDetails.hide) {
					const percentage: number = parseInt(((entry.total / this.totalEntities) * 100).toFixed(0), 10);

					if (percentage > 0) {
						output.push([entityDetails.name[this.$i18n.locale], percentage, entry.entity]);
					} else {
						others[1] += entry.total;
					}
				}
			} else {
				if (!entityDetails.hide) {
					others[1] += entry.total;
				}
			}
		}
		if (others[1] > 0) {
			others[1] = ((others[1] / this.totalEntities) * 100).toFixed(0);
			output.push(others);
		}

		return {
			data: output,
			ids: output.map((d: any) => d[2])
		};
	}

	public get entityValuesArray() {
		const output: any = [];
		const others: any = [this.$t("Others"), 0, "others"];
		if (this.entity !== "") {
			for (const entry of this.values) {
				const valueDetails = this.valueDetails(this.entityId.toLowerCase(), entry.value.toLowerCase());
				if (output.length < 10) {
					if (!valueDetails.hide) {
						output.push([valueDetails.name[this.$i18n.locale], entry.total, entry.value]);
					}
				} else {
					if (!valueDetails.hide) {
						others[1] += entry.total;
					}
				}
			}
			if (others[1] > 0) {
				output.push(others);
			}
		}

		return {
			data: output,
			ids: output.map((d: any) => d[2])
		};
	}

	public get languageArray() {
		const output: any = [];
		const others: any = [this.$t("Others"), 0];

		for (const langEntry of this.languages) {
			if (output.length < 10) {
				output.push([this.iso6391.getName(langEntry.language), ((langEntry.total / this.totalDocuments) * 100).toFixed(0)]);
			} else {
				others[1] += langEntry.total;
			}
		}

		if (others[1] > 0) {
			others[1] = ((others[1] / this.totalDocuments) * 100).toFixed(0);
			output.push(others);
		}

		return output;
	}

	public blocks(blockData: any) {
		const blocks = [];
		let total = 0;
		if(blockData.length === 0) {
			this.blockPie = [["No Data", 100 ]];
			return;
		}
		console.log(this.entityConfig);
		if(typeof this.entityConfig.blocks === "undefined") {
			console.log("Blocks config is missing");
			this.blockPie = [["No Data", 100]];
			return;
		}
		for(const block of blockData) {
			total += block.blockCount;
		}
		if (total === 0) {
			this.blockPie = [["No Data", 100]];
			return;
		}
		const percentage = 100 / total;
		console.log("Percentage:", percentage);
		for(const block of blockData) {
			if(typeof this.entityConfig.blocks[block.blockId] !== "undefined") {
				block.blockId = this.entityConfig.blocks[block.blockId].name[this.$i18n.locale];
			}
			blocks.push([ block.blockId, (block.blockCount * percentage).toFixed(2) ]);
		}

		// Sort
		blocks.sort((a: any, b: any) => b[1] - a[1]);
		this.blockPie = blocks;
	}
}
