



















































































import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
// import iso6391 from "iso-639-1";
import Languages from "../../libraries/languages";
import {
	Rate,
	Time,
	Poptip,
	Tooltip,
	Split,
	Table,
	Collapse,
	// @ts-ignore
	Panel
} from "view-design";

import Ace from "@/components/ace.vue";

import beautify from "js-beautify";

// import MAceEditor from "vue-m-ace-editor";
// import "brace";
// import "brace/mode/html";
// import "brace/theme/chrome";

@Component({
	components: {
		Ace,
		Split,
		Poptip,
		Rate,
		Time,
		Tooltip,
		Table,
		Collapse,
		Panel
	}
})
export default class Translation extends Vue {
	public loading = true;
	public data: any = {};
	public split = 0.5;
	public iso6391 = new Languages();

	public created() {
		this.loadTranslation(this.$route.params.id);
	}

	public loadTranslation(id: string) {
		this.loading = true;
		Axios.get(`/super/translations/${id}`)
			.then(res => {
				if (typeof res.data === "object") {
					res.data.manual = true;
					if (!res.data.html) {
						this.split = 1;
					} else {
						res.data.translation = beautify.html(res.data.translation.replace(/>/g, ">\n"), {
							indent_size: 1,
							indent_char: "\t",
							max_preserve_newlines: 1,
							preserve_newlines: true,
							indent_scripts: "separate",
							end_with_newline: false,
							wrap_line_length: 110,
							indent_inner_html: false,
							wrap_attributes: "force"
						});
						console.log("beautified", res.data.translation);
					}
					this.data = res.data;
				} else {
					// @ts-ignore
					throw new Error(this.$t("Error communicating with the server."));
				}
				this.loading = false;
			})
			.catch(e => {
				this.loading = false;
				alert(e);
			});
	}

	public save() {
		console.log("Saving...");
		this.loading = true;
		Axios.patch(`/super/translations/${this.$route.params.id}`, this.data)
			.then(res => {
				if (typeof res.data === "object") {
					console.log(res.data);
					this.data = res.data;
				} else {
					// @ts-ignore
					throw new Error(this.$t("Error communicating with the server."));
				}
				this.loading = false;
			})
			.catch(e => {
				this.loading = false;
				alert(e);
			});
	}
}
