































































































































































import { Component, Vue } from "vue-property-decorator";

import nWordStar from "../../components/nWordStar.vue";

import {
	Circle,
	Icon,
	Collapse,
	Footer,
	Menu,
	MenuItem,
	Submenu,
	MenuGroup,
	DatePicker,
	Spin,
	Form,
	FormItem,
	Select,
	Option,
	// @ts-ignore
	Panel,
	Message
} from "view-design";
import Axios from "axios";
@Component({
	components: {
		Footer,
		Menu,
		MenuItem,
		Submenu,
		MenuGroup,
		DatePicker,
		"i-circle": Circle,
		Icon,
		Collapse,
		Form,
		FormItem,
		Select,
		Option,
		// @ts-ignore
		Panel,
		Message,
		nWordStar
	}
})
export default class Experimental extends Vue {
	public entity: any = {};
	public filters: any = {
		date: [] as any,
		language: {
			language: "",
			languageLoaded: "",
			exclude: false,
			excludeLoaded: false,
		},
		country: {
			country: "",
			countryLoaded: "",
			exclude: false,
			excludeLoaded: false
		}
	};
	public dailyWordIntents: any = [];
	public wordData: any = {
		selectedWords: [
			undefined,
			undefined,
			undefined
		],
		words: [],
		regex: ""
	};
	public lastRegex = "";
	public wordstarWord = "";
	public wordstarData: any = {};
	public intent = "general_info_cure";
	public blockPie: any = [];

	public async created() {
		// @ts-ignore
		Spin.show();
		try {
			await Axios.get("/admin/entity").then(res => {
				this.entity = res.data;
				if (typeof this.entity.config?.statistics?.wordintents !== "undefined") {
					console.log("Word config");
					this.wordData.words = this.entity.config.statistics.wordintents;
					this.sortWordIntents();
				}
			});
			await this.loadStats();
		} catch (e: any) {
			if (e.response && e.response.status === 401) {
				alert(this.$t("Unauthorized error"));
				window.localStorage.removeItem("jwt");
				this.$router.replace("login");
			} else {
				alert(e);
			}
		}
		// @ts-ignore
		Spin.hide();
	}

	public loadStats() {
		const promises: any = [];
		promises.push(
			Axios.post("/stats/intent/daily", {
				start: this.filterDateStart,
				end: this.filterDateEnd,
				...(this.filters.language.language !== "" ? { language: this.filters.language.language } : {}),
				excludeLanguage: this.filters.language.exclude,
				...(this.filters.country.country !== "" ? { country: this.filters.country.country } : {}),
				excludeCountry: this.filters.country.exclude,
				regex: this.wordData.regex
			}).then(res => {
				this.dailyWordIntents = res.data;
				// this.dailyWordIntentsFM = res.data;
				this.applyWordstar();
			})
		);
		// Used by block tracking
		promises.push(
			Axios.post("/stats/blocks", {
				start: this.filterDateStart,
				end: this.filterDateEnd,
				...(this.filters.language.language !== "" ? { language: this.filters.language.language } : {}),
				excludeLanguage: this.filters.language.exclude,
				...(this.filters.country.country !== "" ? { country: this.filters.country.country } : {}),
				excludeCountry: this.filters.country.exclude,
				intent: "general_info_cure",
			}).then(res => {
				this.blocks(res.data);
			})
		);

		return Promise.all(promises);
	}

	public sortWordIntents() {
		this.wordData.words.sort((a: any, b: any) => {
			const locale = this.$i18n.locale;
			if (a.name[locale] < b.name[locale]) {
				return -1;
			}
			if (a.name[locale] > b.name[locale]) {
				return 1;
			}

			// names must be equal
			return 0;
		});
	}
	// WordIntents start
	public wordIntentCreate(val: string) {
		const index = this.wordData.words.findIndex((item: any) => item.name.en === val);
		if(index === -1) {
			this.wordData.words.push({ name: {en: val, fi: val}, words: [val]});
		}
	}

	public applyWordIntents() {
		const words: any = [];
		let index;
		let regex = "";
		// Copy words removing duplicates
		this.wordData.selectedWords.forEach(function(value: any){
			if (words.indexOf(value) === -1 ) {
				words.push(value);
			}
		});
		for(const word of words) {
			if (typeof word !== "undefined" && (index = this.wordData.words.findIndex((value: any) => value.name.en === word)) !== -1) {
				let regexPart = "(?=";
				let count = 0;
				for (const item of this.wordData.words[index].words) {
					regexPart += `.* ${item} `;
					count++;
					if (count < this.wordData.words[index].words.length) {
						regexPart += "|";
					} else {
						regexPart += ")";
					}
				}
				regex += regexPart;
			} else if( typeof word !== "undefined" && word !== "") {
				regex += `(?=.*${word})`;
			}
		}
		this.wordData.regex = regex;
		console.log("REGEX:", this.wordData.regex);

		Axios.post("/stats/intent/daily", {
			start: this.filterDateStart,
			end: this.filterDateEnd,
			...(this.filters.language.language !== "" ? { language: this.filters.language.language } : {}),
			excludeLanguage: this.filters.language.exclude,
			...(this.filters.country.country !== "" ? { country: this.filters.country.country } : {}),
			excludeCountry: this.filters.country.exclude,
			regex: this.wordData.regex
		}).then(res => {
			this.dailyWordIntents = res.data;
			this.lastRegex = this.wordData.regex;
		});
	}

	public intentDetails(intent: string) {
		if (typeof this.entity.config.intents[intent] === "object") {
			return this.entity.config.intents[intent];
		} else {
			return {
				group: "Unknown",
				hide: false,
				name: {
					// @ts-ignore
					en: intent.replace(/_-/g, " ").capitalize(),
					// @ts-ignore
					fi: intent.replace(/_-/g, " ").capitalize()
				}
			};
		}
	}

	private get wordIntents() {
		const output: any[] = [];
		const entries: any = {};
		let count: number = 0;
		const intents = [];
		let percentage: number = 0;

		try{
			for (const entry of this.dailyWordIntents) {
				const details = this.intentDetails(entry.intent);
				if (!details.hide) {
					if(typeof entries[entry.intent] !== "undefined") {
						entries[entry.intent].total += entry.total;
					} else {
						entries[entry.intent] = { total: entry.total, name: details.name[this.$i18n.locale] };
					}
				}
			}

			for (const intent of Object.keys(entries)) {
				if (count > 15) {
					break;
				}
				intents.push([entries[intent].name, entries[intent].total]);
				percentage = percentage + entries[intent].total;
				count++;
			}
			percentage = 100 / percentage;

			intents.sort((a: any, b: any) => {
				if (a[1] < b[1]) {
					return 1;
				}
				if (a[1] > b[1]) {
					return -1;
				}
				// names must be equal
				return 0;
			});

			for (const intent of intents) {
				output.push([intent[0], (intent[1] * percentage).toFixed(2)]);
				count++;
			}

			if (output.length === 0) {
				output.push([this.$t("No data for selected word(s), try refining the query"), 100]);
			}
		} catch (e) {
			console.error(e);
		}

		return output;
	}
	// WordIntents end
	// WordStar Start
	public applyWordstar() {
		void Axios.post("/stats/intent/daily", {
			start: this.filterDateStart,
			end: this.filterDateEnd,
			...(this.filters.language.language !== "" ? { language: this.filters.language.language } : {}),
			excludeLanguage: this.filters.language.exclude,
			...(this.filters.country.country !== "" ? { country: this.filters.country.country } : {}),
			excludeCountry: this.filters.country.exclude,
			regex: `(?=.* ${this.wordstarWord})`
		}).then(res => {
			const wordstarData: any = {
				word: this.wordstarWord,
				intents: []
			};
			const intents: any = [];
			for(const item of res.data) {
				const index = intents.findIndex((intent: string) => intent === item.intent);

				if(index !== -1) {
					continue;
				}

				intents.push(item.intent);
			}
			if(typeof this.entity.config.intents === "undefined") {
				console.log("Intents config missing");
				return;
			}
			for(const item of intents) {
				if(typeof this.entity.config.intents[item] !== "undefined" && typeof this.entity.config.intents[item].name[this.$i18n.locale] !== "undefined") {
					if (this.entity.config.intents[item].hide === false) {
						wordstarData.intents.push(this.entity.config.intents[item].name[this.$i18n.locale]);
					}
				} else {
					wordstarData.intents.push(item);
				}
			}
			this.wordstarData = wordstarData;
		});
	}
	// WordStar End

	// Block tracking start
	public intentChange() {
		if(typeof this.intent === "undefined") {
			return;
		}
		void Axios.post("/stats/blocks", {
			start: this.filterDateStart,
			end: this.filterDateEnd,
			...(this.filters.language.language !== "" ? { language: this.filters.language.language } : {}),
			excludeLanguage: this.filters.language.exclude,
			...(this.filters.country.country !== "" ? { country: this.filters.country.country } : {}),
			excludeCountry: this.filters.country.exclude,
			intent: this.intent,
		}).then(res => {
			this.blocks(res.data);
		});
	}

	public blocks(blockData: any) {
		const blocks = [];
		let total = 0;
		if(blockData.length === 0) {
			this.blockPie = [["No Data", 100 ]];
			return;
		}
		if(typeof this.entity === "undefined" || typeof this.entity.config === "undefined" || typeof this.entity.config.blocks === "undefined") {
			console.log("Blocks config is missing");
			this.blockPie = [["No Data", 100]];
			return;
		}
		for(const block of blockData) {
			total += block.blockCount;
		}
		if (total === 0) {
			this.blockPie = [["No Data", 100]];
			return;
		}
		const percentage = 100 / total;
		console.log("Percentage:", percentage);
		for(const block of blockData) {
			if(typeof this.entity.config.blocks[block.blockId] !== "undefined") {
				block.blockId = this.entity.config.blocks[block.blockId].name[this.$i18n.locale];
			}
			blocks.push([ block.blockId, (block.blockCount * percentage).toFixed(2) ]);
		}

		// Sort
		blocks.sort((a: any, b: any) => b[1] - a[1]);
		this.blockPie = blocks;
	}
	// Block tracking end
	private get filterDateStart() {
		if (typeof this.filters.date[0] === "undefined") {
			return "2020-01-01 00:00:00";
		} else {
			return this.filters.date[0] + " 00:00:00";
		}
	}

	private get filterDateEnd() {
		if (typeof this.filters.date[1] === "undefined") {
			const date = new Date();
			return date.getFullYear() + 1 + "-01-01 00:00:00";
		} else {
			return this.filters.date[1] + " 00:00:00";
		}
	}
}
