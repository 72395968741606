















/* eslint-disable @typescript-eslint/no-var-requires */

import { Component, Vue } from "vue-property-decorator";
import MarkdownIt from "markdown-it";

import {
	Rate,
	Time,
	Poptip,
	Tooltip,
	Modal,
	Table,
	Collapse,
	// @ts-ignore
	Panel
} from "view-design";

@Component({
	components: {
		Poptip,
		Rate,
		Time,
		Tooltip,
		Table,
		Modal,
		Collapse,
		Panel
	}
})
export default class Markdown extends Vue {
	public loading = true;
	public input = `
::: block
## test
this is our content
### some other thing
:::

::: block
## test 2
this is our content
ssfsdfsdf
:::
`;
	public md: any;

	public created() {
		this.md = new MarkdownIt({
			linkify: true,
			breaks: true
		})
			.use(require("markdown-it-link-attributes"), {
				attrs: {
					target: "_blank",
					rel: "noopener"
				}
			})
			.use(require("markdown-it-bracketed-spans"))
			.use(require("markdown-it-attrs"), {
				allowedAttributes: ["translate", "class"]
			})
			.use(require("markdown-it-container"), "block");
	}

	public get html() {
		return this.md.render(this.input);
	}
}
