import iso6391 from "iso-639-1";

export default class Language {
	private data: any = {
		"ceb": {
			name: "Cebuano"
		},
		"haw": {
			name: "Hawaiian"
		},
		"hmn": {
			name: "Hmong"
		},
		"zh-CN": {
			name: "Chinese Simplified"
		},
		"zh-TW": {
			name: "Chinese Traditional"
		},
		"iw": {
			name: "Hebrew"
		},
	};

	public getName(code: string) {
		try{
			if (typeof code === "undefined") {
				return "undefined code";
			}
			const name = iso6391.getName(code) || this.data[code].name || code;

			return name;
		} catch (e) {
			console.log("Unknown language: ", code);
			return code;
		}
	}

	public getAllCodes() {
		const codes: any = iso6391.getAllCodes();
		codes.push("ceb");
		codes.push("haw");
		codes.push("hmn");
		codes.push("zh-CN");
		codes.push("zh-TW");

		return codes;
	}
}
