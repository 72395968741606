


























































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import {
	Button,
	ButtonGroup,
	Modal,
	Table,
	Tag,
	Spin,
	Input,
	Form,
	FormItem,
	Card,
	Switch,
	Icon,
	Sider,
	MenuItem,
	Menu,
	Submenu,
	Content,
	Layout,
	Collapse,
	Message,
	Upload,
	Select,
	Option,
	Checkbox,
	CheckboxGroup,
	// @ts-ignore
	List,
	// @ts-ignore
	ListItem,
	// @ts-ignore
	Panel
} from "view-design";

Component.registerHooks(["beforeRouteEnter", "beforeRouteLeave", "beforeRouteUpdate"]);

@Component({
	components: {
		Button,
		ButtonGroup,
		Table,
		Modal,
		Tag,
		Spin,
		"i-input": Input,
		Form,
		FormItem,
		Card,
		Icon,
		Sider,
		Menu,
		MenuItem,
		Submenu,
		Content,
		Layout,
		Collapse,
		Select,
		Option,
		Checkbox,
		CheckboxGroup,
		List,
		ListItem,
		Panel,
		Upload,
		"i-switch": Switch
	}
})
export default class StatsEditor extends Vue {
	public loading = true;
	public entityConfig: any = {
		statistics: {
			wordintents: []
		}
	};
	public modals: any = {
		wordGroup: {
			visible: false,
			name: "",
			finnish: "",
			input: "",
			message: ""
		},
		word: {
			visible: false,
			input: "",
			list: {},
			message: ""
		},
		export: {
			visible: false,
			stats: [
				"wordintents"
			]
		}
	};
	public location: string = "";
	public activeStat: string = "";
	public activeWord: string = "";
	public dirty: boolean = false;

	@Watch("entityConfig", { deep: true })
	private watchForDirtyChanges() {
		if (!this.loading) {
			this.dirty = true;
		}
	}

	public created() {
		this.loadEntity();
	}

	public loadEntity() {
		// @ts-ignore
		Spin.show();
		axios.get("/super/entity").then(res => {
			console.log("Loading:", this.loading);
			if (typeof res.data.config !== "undefined" && typeof res.data.config === "object") {
				// Do a sanity check
				const entityConfig = res.data.config;
				if (typeof entityConfig.intents === "undefined" || typeof entityConfig.intents !== "object") {
					entityConfig.intents = {};
				}
				if (typeof entityConfig.entities === "undefined" || typeof entityConfig.entities !== "object") {
					entityConfig.entities = {};
				}
				if (typeof entityConfig.intents === "undefined" ||  typeof entityConfig.parameters !== "object") {
					entityConfig.parameters = {};
				}
				if (typeof entityConfig.blocks === "undefined" || typeof entityConfig.blocks !== "object") {
					entityConfig.blocks = {};
				}
				if (typeof entityConfig.statistics === "undefined" || typeof entityConfig.statistics !== "object") {
					entityConfig.statistics = { wordintents: [] };
				}
				for (const [_intent, intentData] of Object.entries(entityConfig.intents) as any) {
					if (typeof intentData.name !== "object") {
						intentData.name = { en: "-", fi: "-" };
					}
					if (typeof intentData.description !== "object") {
						intentData.description = { en: "", fi: "" };
					}
				}
				this.entityConfig = entityConfig;
				this.location = res.data.location;
			} else {
				// This is a new Entity
				this.entityConfig = {
					intents: {},
					entities: {},
					parameters: {},
					blocks: {},
					statistics: {}
				};
			}
			// @ts-ignore
			Spin.hide();
		}).catch(err => {
			// @ts-ignore
			Modal.error({
				title: "Error",
				content: `${err} : Try refreshing page, if error persists then contact support`
			});
		});
	}

	public addWordGroup() {
		const wordGroup: any = {
			name: {
				en: "",
				fi: ""
			},
			words: []
		};

		if(this.modals.wordGroup.name === "") {
			this.modals.wordGroup.message = "Group name has not been set";
			this.modals.wordGroup.visible = true;
			// Return because name is not set
			return;
		}

		if(this.entityConfig.statistics.wordintents.findIndex((item: any) => item.name.en.toLowerCase() === this.modals.wordGroup.name.toLowerCase()) !== -1) {
			this.modals.wordGroup.message = "Word Group already exists";
			this.modals.wordGroup.visible = true;
			// Return because group already exists
			return;
		}
		wordGroup.name.en = this.modals.wordGroup.name;

		if(this.modals.wordGroup.finnish === "") {
			wordGroup.name.fi = "Tuntematon";
		} else {
			wordGroup.name.fi = this.modals.wordGroup.finnish;
		}

		if(this.modals.wordGroup.input !== "") {
			const words = this.modals.wordGroup.input.split(",");
			for(const word of words) {
				const newWord = word.replaceAll(" ", "");
				const wordExists = wordGroup.words.findIndex((item: string) => item === newWord );
				if(newWord !== "" && wordExists === -1) {
					wordGroup.words.push(newWord);
				}
			}
		}
		this.entityConfig.statistics.wordintents.push(wordGroup);
		// Cleanup
		this.resetWordGroupModal();
	}

	public resetWordGroupModal() {
		this.modals.wordGroup = { visible: false, name: "", finnish: "", input: "", message: ""};
	}

	public addWords() {
		if(this.modals.word.input === "") {
			this.modals.word.message = "Input is empty";
			this.modals.word.visible = true;
			return;
		}
		const words = this.modals.word.input.split(",");
		for(const word of words) {
			const newWord = word.replaceAll(" ", "");
			const wordExists = this.modals.word.list.words.findIndex((item: string) => item === newWord );
			if(newWord !== "" && wordExists === -1) {
				this.modals.word.list.words.push(newWord);
			}
		}
		// Cleanup
		this.resetWordsModal();
	}

	public resetWordsModal() {
		this.modals.word = { visible: false, input: "", list: {}, message: "" };
	}

	public async save() {
		// @ts-ignore
		Spin.show();
		axios.patch("/super/entity", { config: this.entityConfig })
			.then(res => {
				console.log(res);
				// @ts-ignore
				Message.success({
					content: this.$t("Saved"),
					duration: 10,
					background: true
				});
				this.dirty = false;
			})
			.catch(e => {
				// @ts-ignore
				Modal.error({
					title: this.$t("Error"),
					content: this.$t("Error saving.") + " " + e
				});
			});
		// @ts-ignore
		Spin.hide();
	}

	public exportStats() {
		const stats: any = {};
		const keys = Object.keys(this.entityConfig.statistics);
		for(const key of keys) {
			if(this.modals.export.stats.includes(key)) {
				stats[key] = this.entityConfig.statistics[key];
			}
		}
		const element = document.createElement("a");
		element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(JSON.stringify(stats, undefined, "\t")));
		element.setAttribute("download", `neuvola-${this.location}-blocks-${new Date().toISOString().substr(0, 16).replace(":", "-")}.json`);
		element.style.display = "none";
		document.body.appendChild(element);
		element.click();
		document.body.removeChild(element);
	}

	public resetExportModal() {
		this.modals.export.stats = [];
		const keys = Object.keys(this.entityConfig.statistics);
		for(const key of keys) {
			this.modals.export.stats.push(key);
		}
	}

	public importStats() {
		// @ts-ignore
		Modal.confirm({
			render: (h: any) => [
				h("strong", this.$t("WARNING! Existing statistics configuration will be replaced completely. NOT merged.")),
				h("input", {
					attrs: {
						type: "file",
						autofocus: true,
						placeholder: this.$t("Upload"),
						style: "margin-top: 15px",
						id: "neuvo-stats-upload"
					}
				})
			],
			onOk: () => {
				try {
					// @ts-ignore
					const file = document.getElementById("neuvo-stats-upload").files[0];
					const reader = new FileReader();
					reader.readAsText(file, "UTF-8");
					reader.onload = event => {
						try {
							const stats: any = JSON.parse(event?.target?.result as string);
							const keys = Object.keys(stats);
							for(const key of keys) {
								this.entityConfig.statistics[key] = stats[key];
							}
						} catch (e) {
							alert("Error parsing file. " + e);
						}
					};
					reader.onerror = event => {
						throw event;
					};
					this.dirty = true;
					this.$forceUpdate();
				} catch (e: any) {
					alert(this.$t("Error importing. ") + e);
				}
			}
		});
	}

	public beforeRouteLeave(to: any, from: any, next: any) {
		if (this.dirty) {
			// @ts-ignore
			const answer = window.confirm(this.$t("Do you really want to leave? You have unsaved changes!"));
			if (answer) {
				next();
			} else {
				next(false);
			}
		} else {
			next();
		}
	}

	public updated() {
		this.loading = false;
	}
}
