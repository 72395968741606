import Vue from "vue";
import App from "./App.vue";
import { decode, JwtPayload } from "jsonwebtoken";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import "./plugins/view-design.js";

import Chartkick from "vue-chartkick";
import Chart from "chart.js";

import axios from "axios";
import i18n from "./i18n";

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_DASHBOARD_API;
axios.defaults.headers.post["Content-Type"] = "application/json";

// @ts-ignore
if (typeof String.prototype.capitalize !== "object") {
	// @ts-ignore
	String.prototype.capitalize = function() {
		return this.charAt(0).toUpperCase() + this.slice(1);
	};
}

Chartkick.options = {
	library: {
		animation: { easing: "easeOutQuad" }
	}
};

router.beforeEach(async (to: any, from: any, next) => {
	console.log("beforeEach", to, from);
	if (to.name !== "Login" && to.name !== "Password Reset") {
		console.log("Not in login");
		const refreshToken = window.localStorage.getItem("refreshToken");
		if (refreshToken === null) {
			console.log("refreshToken missing");
			window.localStorage.removeItem("jwt");
			window.localStorage.removeItem("entity");
			router.replace("login");
		} else {
			const refreshDecoded: any = decode(refreshToken);
			if (Math.floor(Date.now() / 1000) > refreshDecoded.exp) {
				console.log("refreshToken expired");
				window.localStorage.removeItem("jwt");
				window.localStorage.removeItem("refreshToken");
				window.localStorage.removeItem("entity");
				router.replace("login");
				return;
			} else {
				let jwt: string | null | void = window.localStorage.getItem("jwt");
				let entity: string | null | void = window.localStorage.getItem("entity");
				let entityIndex = 0;
				let decoded: any | JwtPayload | string;
				let selectAccount = false;
				let accountCount = 0;

				if (jwt !== null) {
					console.log("jwt not null");
					decoded = decode(jwt);
				}

				if (decoded === null || typeof decoded === "undefined" || typeof decoded !== "object" || Math.floor(Date.now() / 1000) > decoded.exp) {
					console.log("jwt expired or invalid");
					jwt = await refreshJWT().catch(console.error);
					if (typeof jwt === "string") {
						decoded = decode(jwt);
					}
				}

				if (typeof jwt === "string" && decoded !== null && typeof decoded === "object" && typeof decoded.entities === "object") {
					// If entity is not set then use the first entity in user entities by default - user gets to select account on login but we need something so the account doesn't zombify
					if (entity === null) {
						console.log("First login!!!!");
						selectAccount = true;
						entity = decoded.entities[0].entityId;
					} else {
						entityIndex = decoded.entities.findIndex((id: any) => id.entityId === entity);
						if (entityIndex === -1) {
							// There is something wrong here, set the entityIndex to 0, very likely a previous login didn't clean up when logged out
							entityIndex = 0;
						}
					}
					axios.defaults.headers.common.Authorization = `Bearer ${jwt}`;
					axios.defaults.headers.common.Entity = entity;
					// We need to store this
					window.localStorage.setItem("entity", entity as string);
					store.commit("setEntity", entity as string);
					// Note: if browser is left open and no activity has occurred a 401 error happens clearing the $store so the set email was moved here from Login.vue
					store.commit("setEmail", decoded.email);
					// get entityName, this is needed for App.vue to display the correct EntityName when account is switched
					getEntityName();
					accountCount = decoded.entities.length;
					if (Array.isArray(decoded.entities[entityIndex].permissions)) {
						to.meta.permissions = decoded.entities[entityIndex].permissions;
						to.meta.entityCount = accountCount;
					}
				} else {
					console.log("jwt");
					window.localStorage.removeItem("jwt");
					router.replace("login");
				}

				// Hack
				console.log("WE AHRE HERE", decoded, to);
				// Only display account switch if there is more than 1 account with this log in
				if (selectAccount && accountCount > 1) {
					router.replace("AccountSwitch");
					return;
				}
				// @ts-ignore
				if (to.name === "Advanced Statistics" && !decoded.entities[entityIndex].permissions.includes("stats")) {
					console.log("GTFO");
					router.replace("content");
					return;
				}
			}
		}
	}
	next();
});

async function refreshJWT(): Promise<string | void> {
	const jwt = window.localStorage.getItem("refreshToken");
	if (jwt !== null) {
		return await axios
			.post(
				"/auth/refresh",
				{},
				{
					headers: {
						Authorization: `Bearer ${jwt}`
					}
				}
			)
			.then(async (res: any) => {
				console.log(res.body, res.data);
				console.log({ res, data: res.data });
				if (res.status === 200 && typeof res.data.jwt === "string") {
					window.localStorage.setItem("jwt", res.data.jwt);
					return res.data.jwt;
				}
			});
	} else {
		return;
	}
}

setInterval(() => {
	refreshJWT();
}, 3500000);

async function getEntityName() {
	return await axios.get("/accountswitch/getentityname").then(res => {
		let entityName: string = "";

		if (res.data.name && res.data.name !== undefined) {
			entityName = res.data.name.charAt(0).toUpperCase() + res.data.name.slice(1);
		} else {
			entityName = "Unknown";
		}
		// Commit name to vuex store
		store.commit("setEntityName", entityName);
	});
}

Vue.use(Chartkick.use(Chart));

new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount("#app");
