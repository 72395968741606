






















































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
	Card,
	Form,
	FormItem,
	Icon,
	Row,
	Col,
	Table,
	DatePicker,
	Button,
	ButtonGroup,
	Modal,
	Collapse,
	// @ts-ignore
	Panel,
	Select,
	Option,
	Spin,
	Input
} from "view-design";
import Axios from "axios";
// import iso6391 from "iso-639-1";
import Languages from "../../libraries/languages";
import { CreateElement } from "vue";
import random from "random";
import * as seedrandom from "seedrandom";
@Component({
	components: {
		Card,
		Form,
		FormItem,
		Icon,
		Row,
		Col,
		Table,
		DatePicker,
		Button,
		ButtonGroup,
		Modal,
		Panel,
		Collapse,
		Select,
		Option,
		Spin,
		Input
	}
})
export default class Reporting extends Vue {
	public iso6391 = new Languages();
	public topicData: any = [];
	public languageData: any = [];
	public userData: any = [];
	public summaryReport: string = "";
	public messageData: any = {
		messageData1: [],
		messageData2: [],
		messageData3: [],
		messageData4: [],
		messageData5: [],
		messageData6: [],
		messageData7: [],
		messageData8: [],
		messageData9: [],
		messageData10: []
	};
	public trendsData: any = [];
	public topicColumns: any = [];
	public messageColumns: any = [];
	public languageColumns: any = [];
	public userColumns: any = [];
	public trendsColumns: any = [];
	public currentDate: Date = new Date();
	public last7Days: Date = new Date();
	public topicNames: any = {
		topicName1: "",
		topicName2: "",
		topicName3: "",
		topicName4: "",
		topicName5: "",
		topicName6: "",
		topicName7: "",
		topicName8: "",
		topicName9: "",
		topicName10: ""
	};
	public userPercentage: number = 0;
	public userChartData: any = [];
	public topicChartData: any = [];
	public summaryReportCard: boolean = false;
	public languageChartData: any = [];
	public seedNumber: number = 647532;
	public seed: string = "647532";

	// Report intent editor
	public isModified: boolean = false;
	public isSaved: boolean = false;
	public sampleDateRange: any = [];
	public currentIntent: string = "";
	public intent: string = "";
	public intentList: any = [];
	public sampleColumns: any = [];
	public sampleData: any = [];
	public messageSamplesData: any = [];

	public openPanel = "Report";

	public async created() {
		// Topic Table

		this.topicColumns = [
			{
				title: "Topic",
				key: "name"
			},
			{
				title: "User Percentage",
				render: (h: any, params: any) => h("span", (this.userPercentage * params.row.count).toFixed(2) + "%")
			},
			{
				title: "Change",
				render: (h: any, params: any) => h("span", this.change(params.row.count, params.row.baseCount).toFixed(2) + "%")
			}
		];

		this.messageColumns = [
			{
				title: "Message",
				render: (h: any, params: any) => {
					const messagesArr = [];
					messagesArr.push(h("span", `${params.row.english}`));
					return h("div", messagesArr);
				}
			}
		];

		this.languageColumns = [
			{
				title: "Language",
				render: (h: any, params: any) => h("span", this.iso6391.getName(params.row.language) || this.getLangName(params.row.language) || params.row.language)
			},
			{
				title: "User Percentage",
				render: (h: any, params: any) => h("span", (this.userPercentage * params.row.count).toFixed(2) + "%")
			},
			{
				title: "Change",
				render: (h: any, params: any) => h("span", this.change(params.row.count, params.row.baseCount).toFixed(2) + "%")
			}
		];

		this.userColumns = [
			{
				title: "Number of unique users",
				render: (h: any, params: any) => h("span", params.row.current)
			},
			{
				title: "Change",
				render: (h: any, params: any) => h("span", this.change(params.row.current, params.row.base).toFixed(2) + "%")
			}
		];

		this.trendsColumns = [
			{
				title: "Topic",
				render: (h: any, params: any) => h("span", params.row.topic)
			},
			{
				title: "Date",
				render: (h: any, params: any) => h("span", params.row.creationDate)
			},
			{
				title: "Peak/Trough",
				render: (h: any, params: any) => h("span", params.row.peak)
			},
			{
				title: "Difference",
				render: (h: any, params: any) => h("span", params.row.diff)
			}
		];

		this.sampleColumns = [
			{
				title: "Sample",
				render: (h: any, params: any) => h("span", params.row.sample)
			},
			{
				title: this.$t("Actions"),
				fixed: "right",
				render: (h: CreateElement, params: any) =>
					h("div", [
						h(
							"Button",
							{
								props: {
									type: "default",
									size: "small"
								},
								on: {
									click: () => {
										this.randSample(params.row);
									}
								}
							},
							this.$t("Change") as string
						),
						h(
							"Button",
							{
								props: {
									type: "error",
									size: "small"
								},
								on: {
									click: () => {
										this.clearRow(params.row);
									}
								}
							},
							this.$t("Remove") as string
						)
					]),
			}
		];
		// Set initial seed
		this.seedNumber = Math.floor(Math.random() * (999999 - 100000) + 100000);
		this.seed = this.seedNumber.toString();
		// Setup Dates
		this.currentDate = new Date();
		this.changeDates();

		this.loadReport();
		// Call stuff for sample editor
		this.loadSampleEditor();
	}

	public async loadReport() {
		// @ts-ignore
		Spin.show();
		await Axios.post("/reporting/users", {
			currentDate: this.currentDate
		}).then(res => {
			this.userData = res.data;
			this.userPercentage = 100 / this.userData[0].current;
			const chartData = [];

			chartData.push(["Last week", res.data[0].current]);
			chartData.push(["Week prior", res.data[0].base]);
			this.userChartData = chartData;
		});

		Axios.post("/reporting/languages", {
			currentDate: this.currentDate
		}).then(res => {
			this.languageData = res.data;

			const chartData = [];
			for (const data of res.data) {
				chartData.push([this.iso6391.getName(data.language) || this.getLangName(data.language) || data.language, data.count]);
			}
			this.languageChartData = chartData;
		});

		await Axios.get("/super/entity").then(res => {
			console.log(res.data);
			if(typeof res.data.config === "object" && typeof res.data.config.parameters === "object" && typeof res.data.config.parameters.motd === "object" && res.data.config.parameters.motd.value.length > 0) {
				this.summaryReport = res.data.config.parameters.motd.value[0].en;
				this.summaryReportCard = true;
			}
		});

		await Axios.post("/reporting/topics", {
			currentDate: this.currentDate
		}).then(res => {
			this.topicData = res.data;
			const chartData = [];
			for (const data of res.data) {
				chartData.push([data.name, data.count]);
			}
			this.topicChartData = chartData;
		});

		const rng = random.clone(seedrandom(this.seed));
		// Setup topics with 5 samples
		for (let i = 0; i < 2; i++) {
			if (this.topicData[i] && this.topicData[i].topicName !== undefined) {
				this.topicNames["topicName"+(i+1)] = this.topicData[i].name;

				await Axios.post("/reporting/getintentexamples", { intentName: this.topicData[i].topicName }).then(res => {
					const sampleData = res.data.examples;
					const messageData = [];

					if (sampleData.length < 5) {
						for (const sample of sampleData) {
							messageData.push({ english: sample.sample });
						}
					} else {
						let max = sampleData.length - 1;
						for (let j = 0; j < 5; j++) {
							const index = rng.int(0, max);
							messageData.push({ english: sampleData[index].sample });
							sampleData.splice(index, 1);
							max--;
						}
					}
					this.messageData["messageData"+(i+1)] = messageData;
				});
			}
		}

		// Setup topics with 3 samples
		for (let i = 2; i < 10; i++) {
			if (this.topicData[i] && this.topicData[i].topicName !== undefined) {
				this.topicNames["topicName"+(i+1)] = this.topicData[i].name;

				await Axios.post("/reporting/getintentexamples", { intentName: this.topicData[i].topicName }).then(res => {
					const sampleData = res.data.examples;
					const messageData = [];

					if (sampleData.length < 3) {
						for (const sample of sampleData) {
							this.messageData["messageData"+(i+1)].push({ english: sample.sample });
						}
					} else {
						let max = sampleData.length - 1;
						for (let j = 0; j < 3; j++) {
							const index = rng.int(0, max);
							messageData.push( { english: sampleData[index].sample });
							sampleData.splice(index, 1);
							max--;
						}
					}
					this.messageData["messageData"+(i+1)] = messageData;
				});
			}
		}

		Axios.post("/reporting/trends", {
			currentDate: this.currentDate
		}).then(res => {
			this.trendsData = res.data.trends;
		});
		// @ts-ignore
		Spin.hide();
	}

	public async loadSampleEditor() {
		// Set default date range
		const dateEnd = new Date();
		const dateStart = new Date(dateEnd);
		dateStart.setFullYear(dateStart.getFullYear() - 1);

		this.sampleDateRange[0] = dateStart;
		this.sampleDateRange[1] = dateEnd;
		await Axios.get("/reporting/intents").then(res => {
			this.intentList = res.data.intents;
			this.intent = this.intentList[0];
			this.currentIntent = this.intentList[0];
		});

		const params: any = {};
		params.intentName = this.intent;

		await Axios.post("/reporting/getintentexamples", params).then(res => {
			this.sampleData = res.data.examples;
		});

		if(this.sampleDateRange.length === 2 && this.sampleDateRange[0] !== "" && this.sampleDateRange[1] !== "") {
			params.startDate = this.sampleDateRange[1];
			params.endDate = this.sampleDateRange[0];
		}

		await Axios.post("/reporting/getmessageexamples", params).then(res => {
			this.messageSamplesData = res.data.messageSamples;
		});
	}

	public getLangName(lang: string) {
		let res;

		switch(lang) {
			case "zh-CN" : res = "Chinese";
				break;
			case "ceb": res = "Cebuano";
				break;
			case "hmn": res = "Hmong";
				break;
			case "heb": res = "Hebrew";
				break;
		}
		// If language not detected then return undefined
		return res;
	}
	public change(v1: number, v2: number) {
		if (v1 === undefined || v2 === undefined || v1 === 0 || v2 === 0) {
			return 100;
		}
		return ((v1 - v2) / v2) * 100;
	}

	public difference(v1: number, v2: number) {
		if (v1 === undefined || v2 === undefined || v1 === 0 || v2 === 0) {
			return NaN;
		}
		return ((v1 - v2) / ((v1 + v2) / 2)) * 100;
	}

	public changeDates() {
		const createDate = (date: Date, day: number) => {
			const modDate = new Date(date);
			modDate.setDate(date.getDate() + day);
			return modDate;
		};

		let dayOfWeek = this.currentDate.getDay();
		if (dayOfWeek === 0) {
			dayOfWeek = dayOfWeek - 6;
		} else {
			dayOfWeek = (dayOfWeek * -1) + 1;
		}

		this.currentDate = new Date(createDate(this.currentDate, (dayOfWeek)));
		this.last7Days = new Date(createDate(this.currentDate, -7));
		this.loadReport();
	}

	public sendReport(publish: string, seed: string) {
		if (publish === undefined || publish === "" || seed === undefined  || seed === "" || seed.length < 6) {
			throw Error("Publish or seed is not defined");
		}

		if (publish === "publish") {
			if(!confirm("Are you sure you want to publish the report? Once commited it is published for everyone to receive")) {
				return;
			}
		}

		Axios.post("/scheduler/settask", {
			language: this.$i18n.locale,
			dateToRun: this.currentDate,
			extraParams: `publish=${publish} seed=${seed}`,
			runOnce: true
		})
			.then(res => {
				if(res.data.success) {
					// @ts-ignore
					Modal.success({
						title: this.$t("Task was created!")
					});
				} else {
					// @ts-ignore
					Modal.error({
						title: this.$t("Task was not created!")
					});
				}
			})
			.catch(e => {
				// @ts-ignore
				Modal.error({
					title: this.$t("Error"),
					content: this.$t("Creating task failed.") + " " + e
				});
			});
	}

	public randAllSamples() {
		this.sampleData = [];
		let maxLoops = 0;
		if(this.messageSamplesData.length < 50) {
			maxLoops = this.messageSamplesData.length;
		} else {
			maxLoops = 50;
		}

		for(let i = 0; i < maxLoops; i++) {
			if(maxLoops < 50) {
				this.sampleData.push({_Id: this.messageSamplesData[i].id, sample: this.messageSamplesData[i].english});
			} else {
				const rnd = Math.floor(Math.random() * this.messageSamplesData.length);

				this.sampleData.push({_Id: this.messageSamplesData[rnd].id, sample: this.messageSamplesData[rnd].english});
			}
		}
		this.isModified = true;
	}

	public randSample(row: any) {
		const rnd = Math.floor(Math.random() * this.messageSamplesData.length);

		this.sampleData[row._index]._Id = this.messageSamplesData[rnd].id;
		this.sampleData[row._index].sample = this.messageSamplesData[rnd].english;
		this.isModified = true;
	}

	public clearAll() {
		if(confirm(`This will clear all entries for ${this.currentIntent}, Click Ok to clear.`)) {
			this.sampleData = [];
			this.isModified = true;
		}
	}

	public clearRow(row: any) {
		this.sampleData.splice(row._index, 1);
		this.isModified = true;
	}

	public saveIntentSamples() {
		// Save samples to selected intent
		// unset modified flag
		Axios.post("/reporting/saveintentexamples", { intentName: this.intent, exampleArray: this.sampleData }).then(res => {
			if (res.data.success !== undefined && res.data.success === true) {
				this.isSaved = true;
				// @ts-ignore
				Modal.success({
					title: this.$t("Saved intent examples") as string,
					render: (h: CreateElement | undefined) => h?.("span", [h?.("span", (this.$t("Saved") as string) + ` ${this.intent} `), h?.("span", (this.$t("successfully.") as string))])
				});
				// this.messageSamplesData = res.data.messageSamples;
				this.isModified = false;
			} else {
				// @ts-ignore
				Modal.error({
					title: this.$t("Saved intent examples") as string,
					render: (h: CreateElement | undefined) => h?.("span", [h?.("span", ` ${this.intent} `), h?.("span", (this.$t("was not saved.") as string))])
				});
			}
		});
	}

	public onRefresh() {
		this.seedNumber = Math.floor(Math.random() * (999999 - 100000) + 100000);
		this.seed = this.seedNumber.toString();
		this.loadReport();
	}

	public onSeedChange() {
		if (this.seedNumber.toString().length === 6) {
			this.seed = this.seedNumber.toString();
			this.loadReport();
		}
	}

	public onIntentChange() {
		let loseChanges = true;
		if(this.isModified) {
			if(!confirm(`The current intent (${this.currentIntent}) has been modified. By continuing you will lose any changes made.`)) {
				loseChanges = false;
				this.intent = this.currentIntent;
			}
		}

		if(loseChanges) {
			const params: any = {};
			params.intentName = this.intent;

			Axios.post("/reporting/getintentexamples", params).then(res => {
				this.sampleData = res.data.examples;
			});

			if(this.sampleDateRange.length === 2 && this.sampleDateRange[0] !== "" && this.sampleDateRange[1] !== "") {
				params.startDate = this.sampleDateRange[1];
				params.endDate = this.sampleDateRange[0];
			}

			Axios.post("/reporting/getmessageexamples", params).then(res => {
				this.messageSamplesData = res.data.messageSamples;
			});
			this.currentIntent = this.intent;
			this.isModified = false;
		}
	}

	public async updateSampleMessages() {
		const params: any = {};
		params.intentName = this.intent;

		if(this.sampleDateRange.length === 2 && this.sampleDateRange[0] !== "" && this.sampleDateRange[1] !== "") {
			params.startDate = this.sampleDateRange[1];
			params.endDate = this.sampleDateRange[0];
		}

		await Axios.post("/reporting/getmessageexamples", params).then(res => {
			this.messageSamplesData = res.data.messageSamples;
		});
	}
}
