





































































































































































import { Component, Vue } from "vue-property-decorator";
import { Icon, Sider, MenuItem, Menu, Submenu, Content, Layout } from "view-design";

@Component({
	components: {
		Icon,
		Sider,
		Menu,
		MenuItem,
		Submenu,
		Content,
		Layout
	}
})
export default class Super extends Vue {}
