





























































































































import { Component, Vue } from "vue-property-decorator";
import {
	Button,
	Layout,
	Header,
	Menu,
	// @ts-ignore
	Submenu,
	MenuItem,
	MenuGroup,
	Icon,
	Content,
	Footer,
	Modal,
	Message,
	Notice,
	Tag
} from "view-design";

@Component({
	components: {
		Button,
		Layout,
		Header,
		Menu,
		// @ts-ignore
		Submenu,
		MenuItem,
		MenuGroup,
		Icon,
		Content,
		Footer,
		Modal,
		Notice,
		Tag
	}
})
export default class App extends Vue {
	public version: any = "DEV";
	public navigation: any = [
		{
			to: "/",
			icon: "ios-pie",
			name: "Advanced Statistics",
			permission: "stats"
		},
		{
			to: "/content",
			icon: "ios-list-box",
			name: "Content",
			permission: "content-changes:approval"
		},
		{
			to: "/admin",
			icon: "ios-key",
			name: "Admin",
			permission: "admin:read"
		},
		{
			to: "/super",
			icon: "ios-construct",
			name: "Super",
			permission: "super:read"
		}
	];
	public refreshing: boolean = false;
	public registration: any = undefined;

	public created() {
		this.version = process.env.VUE_APP_VERSION;
		console.log("Loading stuff to vuex");
		// @ts-ignore
		Notice.config({
			duration: 0,
			top: 120
		});
		document.addEventListener("swUpdated", this.showRefreshUI, { once: true });

		navigator.serviceWorker.addEventListener("controllerchange", () => {
			if (this.refreshing) {
				return;
			}
			this.refreshing = true;
			window.location.assign(window.location.origin);
		});
	}

	public logout() {
		window.localStorage.removeItem("email");
		window.localStorage.removeItem("entity");
		window.localStorage.removeItem("jwt");
		window.localStorage.removeItem("refreshToken");
		// @ts-ignore
		Message.success({
			content: "Logged out.",
			duration: 30,
			closable: true
		});
		this.$router.replace("/login");
	}

	public language(code: string) {
		this.$i18n.locale = code;
		window.localStorage.setItem("language", code);
	}

	public showRefreshUI(e: any) {
		this.registration = e.detail;
		// @ts-ignore
		Notice.info({
			title: this.$t("Update available"),
			render: (h: any) =>
				h(
					Button,
					{
						props: {
							type: "success",
							long: true,
							ghost: true
						},
						on: {
							click: this.refreshApp
						}
					},
					this.$t("Update") as string
				)
		});
	}

	public refreshApp() {
		if (!this.registration || !this.registration.waiting) {
			return;
		}
		this.registration.waiting.postMessage("skipWaiting");
	}
}
