












































import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import JsonViewer from "vue-json-viewer";
import {
	Rate,
	Time,
	Poptip,
	Tooltip,
	Modal,
	Table,
	Collapse,
	// @ts-ignore
	Panel
} from "view-design";

@Component({
	components: {
		JsonViewer,
		Poptip,
		Rate,
		Time,
		Tooltip,
		Table,
		Modal,
		Collapse,
		Panel
	}
})
export default class Conversation extends Vue {
	public loading = true;
	public conversationData: any = [];
	public conversationColumns: any = [];

	public created() {
		this.conversationColumns = [
			{
				title: this.$t("Translated Input"),
				key: "english",
				resizable: true,
				width: 300
			},
			{
				title: this.$t("Original Input"),
				key: "original",
				resizable: true,
				width: 200
			},
			{
				title: this.$t("Entities"),
				key: "entities",
				slot: "json"
			},
			{
				title: this.$t("Intents"),
				key: "intents",
				slot: "intents",
				resizable: true,
				width: 300
			}
		];
		this.loadConversation(this.$route.params.sessionId);
	}

	public loadConversation(sessionId: string) {
		this.loading = true;
		Axios.get(`/super/conversation/${sessionId}`)
			.then(res => {
				if (typeof res.data === "object") {
					console.log(res.data);
					this.conversationData = res.data.map((item: any) => ({ ...item, ...item.input, ...item.output }));
				} else {
					// @ts-ignore
					throw new Error(this.$t("Error communicating with the server."));
				}
				this.loading = false;
			})
			.catch(e => {
				this.loading = false;
				alert(e);
			});
	}
}
