



























































import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import {
	Rate,
	Time,
	Poptip,
	Tooltip,
	Button,
	ButtonGroup,
	Modal,
	Table,
	Tag,
	Spin,
	Input,
	Form,
	FormItem,
	Card,
	Switch,
	Icon,
	Sider,
	MenuItem,
	Menu,
	Submenu,
	Content,
	Layout,
	Collapse,
	// @ts-ignore
	Panel
} from "view-design";

@Component({
	components: {
		Poptip,
		Rate,
		Time,
		Tooltip,
		Button,
		ButtonGroup,
		Table,
		Modal,
		Tag,
		Spin,
		Input,
		Form,
		FormItem,
		Card,
		Icon,
		Sider,
		Menu,
		MenuItem,
		Submenu,
		Content,
		Layout,
		Collapse,
		Panel,
		"i-switch": Switch
	}
})
export default class Feedback extends Vue {
	public loading = true;
	public feedbackData: any = [];
	public feedbackColumns: any = [];

	public created() {
		this.feedbackColumns = [
			{
				title: this.$t("Date"),
				key: "createdAt",
				slot: "createdAt",
				sortable: true,
				width: 200,
				resizable: true
			},
			{
				title: this.$t("Rating"),
				key: "rating",
				slot: "rating",
				sortable: true,
				width: 180
			},
			{
				title: this.$t("Feedback"),
				key: "feedback",
				ellipsis: true,
				tooltip: true,
				resizable: true,
				minWidth: 300
			},
			{
				title: this.$t("Actions"),
				slot: "action",
				width: 150,
				align: "center"
			}
		];
		this.loadLatestFeedback();
	}

	public loadLatestFeedback() {
		this.loading = true;
		Axios.get("/super/feedback/latest", { params: { limit: 1000 } })
			.then(res => {
				if (typeof res.data === "object") {
					console.log(res.data);
					this.feedbackData = res.data;
				} else {
					// @ts-ignore
					throw new Error(this.$t("Error communicating with the server."));
				}
			})
			.catch(e => {
				alert(e);
			})
			.finally(() => {
				this.loading = false;
			});
	}
}
