
























































































































//
// {"motd":{"type":"textarea","label":"MOTD in the dashboard","repeat":true,"translated":true,"value":{"en":"","fi":""}}}

import { Component, Vue, Watch } from "vue-property-decorator";
import Axios from "axios";
import {
	Button,
	ButtonGroup,
	Divider,
	Modal,
	Table,
	Collapse,
	Message,
	// @ts-ignore
	Panel,
	Spin
} from "view-design";

Component.registerHooks(["beforeRouteEnter", "beforeRouteLeave", "beforeRouteUpdate"]);

@Component({
	components: {
		Divider,
		Button,
		ButtonGroup,
		Table,
		Modal,
		Collapse,
		Panel
	}
})
export default class Parameters extends Vue {
	public loading = true;
	public entityConfig: any = {};
	public dirty: boolean = false;

	@Watch("entityConfig", { deep: true })
	private watchForDirtyChanges() {
		if (!this.loading) {
			this.dirty = true;
		}
		this.loading = false;
	}

	public created() {
		this.loadEntity();
	}

	public loadEntity() {
		// @ts-ignore
		Spin.show();
		Axios.get("/super/entity").then(res => {
			if (typeof res.data.config === "object") {
				this.entityConfig = res.data.config;
			} else {
				// @ts-ignore
				throw new Error(this.$t("Error communicating with the server."));
			}
			// @ts-ignore
			Spin.hide();
		});
	}

	public async save() {
		// @ts-ignore
		Spin.show();
		Axios.patch("/super/entity", { config: this.entityConfig })
			.then(res => {
				console.log(res);
				// @ts-ignore
				Message.success({
					content: this.$t("Saved"),
					duration: 10,
					background: true
				});
				this.dirty = false;
			})
			.catch(e => {
				// @ts-ignore
				Modal.error({
					title: this.$t("Error"),
					content: this.$t("Error saving.") + " " + e
				});
			});
		// @ts-ignore
		Spin.hide();
	}

	public beforeRouteLeave(to: any, from: any, next: any) {
		if (this.dirty) {
			// @ts-ignore
			const answer = window.confirm(this.$t("Do you really want to leave? You have unsaved changes!"));
			if (answer) {
				next();
			} else {
				next(false);
			}
		} else {
			next();
		}
	}

}
