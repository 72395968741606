var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"translations"},[_c('i-card',[_c('p',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t("Education"))+" ")]),_c('i-button-group',{attrs:{"slot":"extra","size":"small"},slot:"extra"},[_c('i-button',{attrs:{"type":"success"},on:{"click":function () { return _vm.$router.push('/super/education/new'); }}},[_vm._v(" "+_vm._s(_vm.$t("Add new Education"))+" ")])],1),_c('div',[_c('Table',{attrs:{"border":"","size":"small","loading":_vm.loading,"columns":_vm.educationColumns,"data":_vm.educationsData},scopedSlots:_vm._u([{key:"datetime",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('Tooltip',{attrs:{"placement":"top","transfer":""}},[_c('Time',{attrs:{"time":row[column.key]}}),_c('Time',{attrs:{"slot":"content","time":row[column.key],"type":"datetime"},slot:"content"})],1)]}},{key:"datetimeUpdated",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('Tooltip',{attrs:{"placement":"top","transfer":""}},[(row[column.key])?_c('Time',{attrs:{"time":row[column.key]}}):_vm._e(),(row[column.key])?_c('Time',{attrs:{"slot":"content","time":row[column.key],"type":"datetime"},slot:"content"}):_vm._e()],1)]}},{key:"intents",fn:function(ref){
var row = ref.row;
return _vm._l((row.intents),function(intent){return _c('i-tag',{key:intent},[_vm._v(" "+_vm._s(intent)+" ")])})}},{key:"locations",fn:function(ref){
var row = ref.row;
return _vm._l((row.locations),function(location){return _c('i-tag',{key:location},[_vm._v(" "+_vm._s(location)+" ")])})}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('i-button-group',[_c('i-button',{attrs:{"type":"error","size":"small"},on:{"click":function($event){return _vm.remove(row._id)}}},[_vm._v(" "+_vm._s(_vm.$t("Delete"))+" ")]),_c('i-button',{attrs:{"type":"default","size":"small","to":'/super/education/' + row._id}},[_vm._v(" "+_vm._s(_vm.$t("Edit"))+" ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }