




























































































































import { Component, Vue } from "vue-property-decorator";
import {
	Button,
	ButtonGroup,
	Modal,
	Table,
	Tag,
	Spin,
	Input,
	Form,
	FormItem,
	Card,
	Switch,
	Icon,
	Sider,
	MenuItem,
	Menu,
	Submenu,
	Content,
	Layout,
	Collapse,
	Upload,
	Select,
	Option,
	DatePicker,
	Message,
	// @ts-ignore
	Panel
} from "view-design";
import { CreateElement } from "vue";
import axios from "axios";
import Languages from "../../libraries/languages";

@Component({
	components: {
		Button,
		ButtonGroup,
		Table,
		Modal,
		Tag,
		Spin,
		"i-input": Input,
		Form,
		FormItem,
		Card,
		Icon,
		Sider,
		Menu,
		MenuItem,
		Submenu,
		Content,
		Layout,
		Collapse,
		Select,
		Option,
		DatePicker,
		Panel,
		Upload,
		"i-switch": Switch
	}
})
export default class BlockTracking extends Vue {
	public modals = {
		details: {
			visible: false,
			title: "",
			blockData: {
				opened: [] as any,
				closed: [] as any,
				sessions: [] as any,
				languages: [] as any,
			},
		},
		remap: {
			visible: false,
			toMap: "" as string, // Selected undefined_block
			mapTo: "" as string, // Selected defined_block
		},
		undo: {
			visible: false,
			title: "" as string,
			undoList: [] as any,
			toMap: "" as string,
			mapTo: "" as string,
		}
	};

	public tables = {
		blockCols: [] as any,
		blockData: [] as any,
		undefBlockCols: [] as any,
		undefBlockData: [] as any,
		detectedLanguagesCols: [] as any,
		detectedLanguagesData: [] as any,
	};
	public Languages = new Languages();
	public dateRange: any = [];
	public blockConfig: any = {};
	public openPanel = "DefinedBlocks";

	public async created() {
		await axios.get("/super/entity")
			.then(res => {
				if (typeof res.data.blocks !== "undefined") {
					this.blockConfig = res.data.blocks;
				}
			})
			.catch(_err => {
				// @ts-ignore
				Modal.error({
					title: this.$t("Error getting configuration"),
					content: this.$t("There was an error retrieving the blocks configuration")
				});
			});
		this.setupColumns();
		// Default date month blocktracker started
		this.dateRange[0] = new Date(2021, 9, 1);
		this.dateRange[1] = new Date();
		this.loadBlocks();
	}

	public async loadBlocks() {
		// @ts-ignore
		Spin.show();
		let dateStart = new Date("2021-09-01T00:00:00");
		let dateEnd = new Date();
		if (this.dateRange[0] !== "" && this.dateRange[1] !== "") {
			dateStart = new Date(this.dateRange[0]);
			dateEnd = new Date(this.dateRange[1]);
		}
		axios.post("/blocktracking/", { start: dateStart, end: dateEnd })
			.then((res: any) => {
				if (typeof res.data !== "undefined") {
					this.tables.blockData = res.data.definedBlocks;
					this.tables.undefBlockData = res.data.undefinedBlocks;
					if(typeof res.data.blockConfig !== "undefined") {
						this.blockConfig = res.data.blockConfig;
					}
				}
			})
			.catch(e => {
				// @ts-ignore
				Modal.error({
					title: this.$t("Error fetching details"),
					content: e.message
				});
			});
		// @ts-ignore
		Spin.hide();
	}

	public details(id: any) {
		this.modals.details.visible = true;
		if(typeof this.blockConfig[id] !== "undefined") {
			this.modals.details.title = `${this.$t("Details")} (${this.blockConfig[id].name[this.$i18n.locale]})`;
		} else {
			this.modals.details.title = `${this.$t("Details")} (${id})`;
		}

		let dateStart = new Date("2021-09-01T00:00:00");
		let dateEnd = new Date();
		if (this.dateRange[0] !== "" && this.dateRange[1] !== "") {
			dateStart = new Date(this.dateRange[0]);
			dateEnd = new Date(this.dateRange[1]);
		}
		axios.post("/blocktracking/stats", { start: dateStart, end: dateEnd, blockId: id})
			.then((res: any) => {
				let openedCount = 0;
				let closedCount = 0;
				let sessionCount = 0;
				let languages = [];

				if (typeof res.data.opened !== "undefined" && Array.isArray(res.data.opened)) {
					for(const item of res.data.opened) {
						openedCount = openedCount + item.count;
					}
				}

				if (typeof res.data.closed !== "undefined" && Array.isArray(res.data.closed)) {
					for(const item of res.data.closed) {
						closedCount = closedCount + item.count;
					}
				}

				if (typeof res.data.sessions !== "undefined" && Array.isArray(res.data.sessions) && res.data.sessions.length > 0) {
					sessionCount = res.data.sessions[0].count;
				}

				if (typeof res.data.languages !== "undefined" && Array.isArray(res.data.languages) && res.data.languages.length > 0) {
					languages = res.data.languages;
				}

				this.modals.details.blockData.opened = openedCount;
				this.modals.details.blockData.closed = closedCount;
				this.modals.details.blockData.sessions = sessionCount;
				this.modals.details.blockData.languages = languages;
			})
			.catch((e: any) => {
				// @ts-ignore
				Modal.error({
					title: this.$t("Error fetching details"),
					content: e.message
				});
			});
	}

	public remap(type: string, id: string) {
		if (type === "modal") {
			this.modals.remap.mapTo = "";
			this.modals.remap.toMap = id;
			this.modals.remap.visible = true;
		} else if(type === "execute") {
			console.log(`Remapping ${id} to ${this.modals.remap.mapTo}`);
			const options: any = {
				toMap: id,
				mapTo: this.modals.remap.mapTo,
			};

			axios.post("blocktracking/remap", options)
				.then((res: any) => {
					console.log(res.data.success);
					if(res.data.success === true) {
						// @ts-ignore
						Message.success({
							content: this.$t("Saved"),
							duration: 10,
							background: true
						});
						this.loadBlocks();
					}
				})
				.catch(e => {
					// @ts-ignore
					Modal.error({
						title: this.$t("Error remapping block"),
						content: e.message
					});
				});
		}
	}

	public undoMapping(type: string, id: string, undoList: any) {
		if(type === "modal") {
			if(typeof undoList !== "undefined" && Array.isArray(undoList) && undoList.length > 0) {
				this.modals.undo.title = `${this.$t("Undo block mapping for")} ${this.blockConfig[id].name[this.$i18n.locale]}`;
				this.modals.undo.toMap = id;
				this.modals.undo.undoList = undoList;
				this.modals.undo.visible = true;
			} else {
				// @ts-ignore
				Message.warning({
					content: this.$t("Nothing to undo"),
					duration: 10,
					background: true
				});
			}
		} else if(type === "execute") {
			axios.post("/blocktracking/undo-remap", { toMap: this.modals.undo.toMap, mapTo: this.modals.undo.mapTo})
				.then((res: any) => {
					if(res.data.success === true) {
						// @ts-ignore
						Message.success({
							content: this.$t("Saved"),
							duration: 10,
							background: true
						});
						this.loadBlocks();
					}
				})
				.catch(e => {
					// @ts-ignore
					Modal.error({
						title: this.$t("Error remapping block"),
						content: e.message
					});
				});
		}
	}

	public onClearDate() {
		//
	}

	public apply() {
		console.log(this.dateRange);
		this.loadBlocks();
	}

	private setupColumns() {
		this.tables.blockCols = [
			{
				title: this.$t("Block Name"),
				key: "_id",
				slot: "blockname"
			},
			{
				title: this.$t("Expanded Count"),
				key: "count",
			},
			{
				title: this.$t("Actions"),
				fixed: "right",
				render: (h: CreateElement, params: any) =>
					h("div", [
						h(
							"Button",
							{
								props: {
									type: "default",
									size: "small"
								},
								on: {
									click: () => {
										this.details(params.row._id);
									}
								}
							},
							this.$t("Details") as string
						),
						h(
							"Button",
							{
								props: {
									type: "warning",
									size: "small"
								},
								on: {
									click: () => {
										this.undoMapping("modal", params.row._id, params.row.undoList);
									}
								}
							},
							this.$t("Undo Mappings") as string
						)
					])
			}
		];

		this.tables.undefBlockCols = [
			{
				title: this.$t("Block Name"),
				key: "_id"
			},
			{
				title: this.$t("Expanded Count"),
				key: "count",
			},
			{
				title: this.$t("Actions"),
				fixed: "right",
				render: (h: CreateElement, params: any) =>
					h("div", [
						h(
							"Button",
							{
								props: {
									type: "default",
									size: "small"
								},
								on: {
									click: () => {
										this.details(params.row._id);
									}
								}
							},
							this.$t("Details") as string
						),
						h(
							"Button",
							{
								props: {
									type: "error",
									size: "small"
								},
								on: {
									click: () => {
										this.remap("modal", params.row._id);
									}
								}
							},
							this.$t("Remap") as string
						),
					]),
			}
		];

		this.tables.detectedLanguagesCols = [
			{
				title: this.$t("Language"),
				slot: "language",
				key: "_id"
			},
			{
				title: this.$t("Count"),
				key: "count"
			}
		];
	}
}
