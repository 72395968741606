







































































import { Component, Vue } from "vue-property-decorator";
import { Modal, Notice, Spin, Card, Form, FormItem, Input, Icon, Button } from "view-design";
import sha1 from "sha1";
import Axios from "axios";

@Component({
	components: {
		Card,
		Form,
		FormItem,
		Input,
		Icon,
		Button
	}
})
export default class Login extends Vue {
	public formInline = {
		email: "",
		password: ""
	};
	public email = "";
	public resettingPassword = false;

	public created() {
		// filler
	}

	public handleSubmit() {
		const hash = sha1(this.formInline.password).toUpperCase();
		const queryHash = hash.substring(0, 5);
		fetch("https://api.pwnedpasswords.com/range/" + queryHash)
			.then(res => res.text())
			.then(async data => {
				if (data.includes(hash.substring(5, 41))) {
					// @ts-ignore
					Notice.warning({
						duration: 0,
						title: "Dangerous Password",
						render: (h: any) =>
							h("span", [
								"We've noticed that the password you're using isn't secure. It is recommended that you change it immediately.",
								h("br"),
								h("a", {
									domProps: {
										innerHTML: "You can read more about the security of your password on the HIBP website."
									},
									attrs: {
										href: "https://haveibeenpwned.com/Passwords",
										target: "_blank"
									}
								})
							]),
						desc: " You can read more about the security of your password here: https://haveibeenpwned.com/Passwords"
					});
				}
			})
			.catch(err => console.error(err));
		// @ts-ignore
		Spin.show();
		Axios.post("/auth/login", { ...this.formInline })
			.then(async (res: any) => {
				console.log(res.body, res.data);
				console.log({ res, data: res.data });
				if (res.status === 200 && typeof res.data.jwt === "string") {
					// @ts-ignore
					Spin.hide();
					window.localStorage.setItem("jwt", res.data.jwt);
					window.localStorage.setItem("refreshToken", res.data.refreshToken);
					Axios.defaults.headers.common.Authorization = `Bearer ${res.data.jwt}`;
					window.localStorage.setItem("email", this.formInline.email);
					this.$store.commit("setEmail", this.formInline.email);
					this.$router.replace("./");
				} else {
					console.log(res.data);
					if (res.data.error === "ValidatingError") {
						throw new Error("Invalid email address or password.");
					} else if (res.data.error === "AdminNoPermissions") {
						throw new Error("Permission denied.");
					} else if (res.data.error === "AdminNotFound") {
						throw new Error("No account found for the email address.");
					} else if (res.data.error === "AdminWrongPassword") {
						throw new Error("Incorrect password.");
					} else {
						throw new Error("Unknown error while logging in.");
					}
				}
			})
			.catch(content => {
				// @ts-ignore
				Spin.hide();
				// @ts-ignore
				Modal.error({
					title: "Login Error",
					content
				});
			});
	}

	public resetPassword() {
		this.resettingPassword = true;
	}
	public requestPasswordDone() {
		Axios.post("/password/requestreset", { email: this.email }).then(_res => {
			// @ts-ignore
			Modal.success({
				title: this.$t("An email has been sent.")
			});
			this.email = "";
		});
	}
	public requestPasswordCancel() {
		this.resettingPassword = false;
		this.email = "";
	}
}
