


























import { Component, Vue, Watch } from "vue-property-decorator";
import { Alert, Divider } from "view-design";

@Component({
	components: {
		Alert,
		Divider
	}
})
export default class Nan extends Vue {
	public features: any = [];

	@Watch("$route")
	public onPropertyChanged(_value: string, _oldValue: string) {
		this.features = this.$route.meta?.features;
		console.log("Route changed for Nan");
	}

	public mounted() {
		this.features = this.$route.meta?.features;
	}
}
