
























































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import Chartkick from "vue-chartkick";

import {
	Circle,
	Icon,
	Notice,
	Collapse,
	Footer,
	Menu,
	MenuItem,
	Submenu,
	MenuGroup,
	DatePicker,
	Spin,
	// @ts-ignore
	Panel,
	Form,
	FormItem,
	Select,
	Option,
	Message
} from "view-design";
import Axios from "axios";
import Languages from "../libraries/languages";
// @ts-ignore
import { countries } from "country-data-list";

const emptyFilter = JSON.stringify({
	date: [] as any,
	language: {
		language: "",
		languageLoaded: "",
		exclude: false,
		excludeLoaded: false,
	},
	country: {
		country: "",
		countryLoaded: "",
		exclude: false,
		excludeLoaded: false
	}
});

@Component({
	components: {
		Footer,
		Menu,
		MenuItem,
		Submenu,
		MenuGroup,
		DatePicker,
		"i-circle": Circle,
		Icon,
		Collapse,
		Form,
		FormItem,
		Select,
		Option,
		// @ts-ignore
		Panel,
		Message
	}
})
export default class Home extends Vue {
	public sessionTimer: any;
	public iso6391 = new Languages();
	public languageCodes = this.iso6391.getAllCodes();
	public countryCodes = [];
	public countryList = countries;
	// Structure filters ready for persistance
	public filters: any = {
		date: [] as any,
		language: {
			language: "",
			languageLoaded: "",
			exclude: false,
			excludeLoaded: false,
		},
		country: {
			country: "",
			countryLoaded: "",
			exclude: false,
			excludeLoaded: false
		}
	};
	public excludeLanguage: any = {
		icon: "ios-square-outline",
	};
	public excludeCountry: any = {
		icon: "ios-square-outline",
	};
	public features: any = [];
	public dirtyFilter: boolean = false;
	public clearEnable: boolean = false;
	// public date: any = [];
	public dateOpen: boolean = false;
	public dateOptions: any = {
		shortcuts: []
	};
	public entity: any = {};
	public intents: any = [];
	public intentTotal: any = 0;
	public showOtherIntents: boolean = false;
	public confidences: any = [];
	public confidence = 0;
	public detected = 0;
	public sessions = {
		questionsAvg: 0,
		questionsTotal: 0,
		uniqueSessions: 0
	};
	public dataPoints = 0;
	public users = [];
	public questions = [];
	public dailyIntents: any = [];
	public languages: any = [];
	public expected: any = [];
	public activeSessions: any = [];
	public current = "";
	public currentPercentage: number = 0;
	public dailyWordIntents: any = [];
	public wordData: any = {
		selectedWords: [
			undefined,
			undefined,
			undefined
		],
		words: [],
		regex: ""
	};

	@Watch("filters.language.language")
	public onLanguageFilterChanged(_val: string, _oldVal: string) {
		this.dirtyFilter = true;
	}

	@Watch("filters.language.exclude")
	public onExcludeLanguageStateChange(_val: boolean, _oldVal: boolean) {
		if (this.filters.language.language !== "") {
			this.dirtyFilter = true;
		}
	}

	@Watch("filters.country.country")
	public onCountryFilterChanged(_val: string, _oldVal: string) {
		this.dirtyFilter = true;
	}

	@Watch("filters.country.exclude")
	public onExcludeCountryStateChange(_val: boolean, _oldVal: boolean) {
		if (this.filters.country.country !== "") {
			this.dirtyFilter = true;
		}
	}

	public async created() {
		console.log("CREATED");
		this.filters.language.language = typeof this.$route.params.language !== "undefined" ? this.$route.params.language : "";
		this.dateOptions.shortcuts = [
			{
				text: this.$t("Last 7 days"),
				value() {
					const start = new Date();
					const end = new Date();
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
					return [start, end];
				}
			},
			{
				text: this.$t("Last 30 days"),
				value() {
					const start = new Date();
					const end = new Date();
					start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
					return [start, end];
				}
			},
			{
				text: this.$t("This year"),
				value() {
					const date = new Date();
					const start = new Date(date.getFullYear(), 0, 1);
					const end = new Date(date.getFullYear() + 1, 0, 1);
					return [start, end];
				}
			}
			// TODO: add last week and last month here
		];

		// Deprecated code
		/*
		await Axios.get("/entities/getFeatures").then(res => {
			this.features = res.data;
		});
		*/

		// Add T1 and XX country codes as these are custom but nessecary for the interface
		Object.assign(this.countryList, {
			"00": {
				name: "Local"
			},
			"X1": {
				name: "World_CF"
			},
			"XX": {
				name: "World"
			},
			"T1": {
				name: "Tor client"
			}
		});
		// @ts-ignore
		Spin.show();
		try {
			await Axios.get("/admin/entity").then(res => {
				this.entity = res.data;
				if (typeof this.entity.config?.statistics?.wordintents !== "undefined") {
					this.wordData.words = this.entity.config.statistics.wordintents;
					this.sortWordIntents();
				}
			});
			// Get features
			if(typeof this.entity === "object" && typeof this.entity.frontend === "object" && typeof this.entity.frontend.features !== "undefined") {
				this.features = this.entity.frontend.features;
			} else {
				this.features = [];
			}
			// Get saved filter if it exists
			const savedFilter: any = JSON.parse(window.localStorage.getItem("stats-filter") as string);
			if(savedFilter !== null && new Date(savedFilter.expireAt).valueOf() > new Date().valueOf()) {
				// @ts-ignore
				Message.warning({
					content: this.$t("Data filters are still active"),
					duration: 10,
					closable: true
				});
				this.filters = savedFilter.filters;
				if(this.filters.language.exclude === true) {
					this.excludeLanguage.icon = "ios-checkbox-outline";
				}
				if(this.filters.country.exclude === true) {
					this.excludeCountry.icon = "ios-checkbox-outline";
				}
				await this.applyFilter();
				this.dirtyFilter = false;
			} else {
				await this.loadStats();
			}
			if(this.features.includes("stat-active-chat-sessions")) {
				this.getActiveSessions();
				this.sessionTimer = setInterval(this.getActiveSessions, 60000 * 2);
			}
		} catch (e: any) {
			if (e.response && e.response.status === 401) {
				alert(this.$t("Unauthorized error"));
				window.localStorage.removeItem("jwt");
				this.$router.replace("login");
			} else {
				alert(e);
			}
		}
		console.log("All loaded");
		// @ts-ignore
		Spin.hide();

		// Hack to get scrollbars to appear after login (Spin control causes overflow: "hidden" to be set when Spin.hide() is called on login page)
		document.getElementsByTagName("body")[0].style.overflow = "auto";
	}

	public beforeDestroy() {
		clearInterval(this.sessionTimer);
	}

	public async loadStats() {
		console.log("Loading Stats...");
		const promises: any = [];

		// const languageStart = new Date();
		// const languageEnd = new Date();
		// languageStart.setFullYear(languageStart.getFullYear() - 1);

		promises.push(
			Axios.get("/public/data-points").then(res => {
				this.dataPoints = res.data.find((location: any) => location._id === this.entity.location).dataPoints;
			})
		);

		promises.push(
			Axios.post("/stats/db/languages", {
				start: this.filterDateStart,
				end: this.filterDateEnd
			}).then(res => {
				this.languageCodes = res.data.languages;
			})
		);

		promises.push(
			Axios.post("/stats/db/countries", {
				start: this.filterDateStart,
				end: this.filterDateEnd
			}).then(res => {
				this.countryCodes = res.data.countries;
			})
		);

		promises.push(
			Axios.post("/stats/intent/total", {
				start: this.filterDateStart,
				end: this.filterDateEnd,
				...(this.filters.language.language !== "" ? { language: this.filters.language.language } : {}),
				excludeLanguage: this.filters.language.exclude,
				...(this.filters.country.country !== "" ? { country: this.filters.country.country } : {}),
				excludeCountry: this.filters.country.exclude
			}).then(res => {
				if (typeof res.data.intents !== "undefined" && typeof res.data.total !== "undefined") {
					this.intents = res.data.intents;
					if(res.data.total.length > 0) {
						this.intentTotal = res.data.total[0].total;
					}
				}
			})
		);

		promises.push(
			Axios.post("/stats/intent/expected", {
				start: this.filterDateStart,
				end: this.filterDateEnd,
				...(this.filters.language.language !== "" ? { language: this.filters.language.language } : {}),
				excludeLanguage: this.filters.language.exclude,
				...(this.filters.country.country !== "" ? { country: this.filters.country.country } : {}),
				excludeCountry: this.filters.country.exclude
			}).then(res => {
				this.expected = res.data.map((question: any) => {
					question.confidence = Math.round(question.confidence * 100);
					return question;
				});
			})
		);

		promises.push(
			Axios.post("/stats/session/languages", {
				start: this.filterDateStart,
				end: this.filterDateEnd,
				...(this.filters.country.country !== "" ? { country: this.filters.country.country } : {}),
				excludeCountry: this.filters.country.exclude
			}).then(res => {
				this.languages = res.data;
			})
		);
		this.filters.language.languageLoaded = this.filters.language.language;
		this.filters.language.excludeLoaded = this.filters.language.exclude;

		promises.push(
			Axios.post("/stats/session/questions", {
				start: this.filterDateStart,
				end: this.filterDateEnd,
				...(this.filters.language.language !== "" ? { language: this.filters.language.language } : {}),
				excludeLanguage: this.filters.language.exclude,
				...(this.filters.country.country !== "" ? { country: this.filters.country.country } : {}),
				excludeCountry: this.filters.country.exclude
			}).then(res => {
				if(typeof res.data.total !== "undefined") {
					this.sessions = {
						questionsAvg: Math.round(res.data.total.session_questions_avg * 10) / 10,
						questionsTotal: res.data.total.questions_total,
						uniqueSessions: res.data.total.unique_sessions
					};
				}

				const output: any = [
					{ name: this.$t("Average Questions / Session"), data: {} },
					{ name: this.$t("Questions Total"), data: {} },
					{ name: this.$t("Unique Sessions"), data: {} }
				];

				for (const day of res.data.daily) {
					output[0].data[day.date] = Math.round(day.session_questions_avg * 10) / 10;
					output[1].data[day.date] = day.questions_total;
					output[2].data[day.date] = day.unique_sessions;
				}
				this.questions = output;
			})
		);

		promises.push(
			Axios.post("/stats/users", {
				start: this.filterDateStart,
				end: this.filterDateEnd,
				...(this.filters.language.language !== "" ? { language: this.filters.language.language } : {}),
				excludeLanguage: this.filters.language.exclude,
				...(this.filters.country.country !== "" ? { country: this.filters.country.country } : {}),
				excludeCountry: this.filters.country.exclude
			}).then(res => {
				const users: any = {};
				for (const entry of res.data) {
					users[entry.date] = entry.unique_users;
				}
				this.users = users;
			})
		);

		promises.push(
			Axios.post("/stats/intent/confidence", {
				start: this.filterDateStart,
				end: this.filterDateEnd,
				...(this.filters.language.language !== "" ? { language: this.filters.language.language } : {}),
				excludeLanguage: this.filters.language.exclude,
				...(this.filters.country.country !== "" ? { country: this.filters.country.country } : {}),
				excludeCountry: this.filters.country.exclude
			}).then(res => {
				this.confidences = res.data;
				let total = 0;
				for (const [_intent, confidence] of res.data) {
					// console.log(intent, confidence);
					total += confidence;
					// console.log(total);
				}
				this.confidence = Math.round((total / res.data.length) * 100);
			})
		);

		promises.push(
			Axios.post("/stats/intent/detect", {
				start: this.filterDateStart,
				end: this.filterDateEnd,
				...(this.filters.language.language !== "" ? { language: this.filters.language.language } : {}),
				excludeLanguage: this.filters.language.exclude,
				...(this.filters.country.country !== "" ? { country: this.filters.country.country } : {}),
				excludeCountry: this.filters.country.exclude
			}).then(res => {
				this.detected = Math.round(res.data.detected * 100);
			})
		);

		promises.push(
			Axios.post("/stats/intent/daily", {
				start: this.filterDateStart,
				end: this.filterDateEnd,
				...(this.filters.language.language !== "" ? { language: this.filters.language.language } : {}),
				excludeLanguage: this.filters.language.exclude,
				...(this.filters.country.country !== "" ? { country: this.filters.country.country } : {}),
				excludeCountry: this.filters.country.exclude
			}).then(res => {
				this.dailyIntents = res.data;
				if (this.features.includes("stat-word-intents")) {
					this.dailyWordIntents = res.data;
				}
			})
		);

		return Promise.all(promises);
	}

	public getActiveSessions() {
		Axios.get("/stats/activeconnections").then(res => {
			const chartData = [];
			for(const item of res.data) {
				chartData.push([this.countryList[item.country].name, item.active]);
			}
			this.activeSessions = chartData;
		});
	}

	public showNotice(data: { country: string; intent: string }) {
		const intentString = data.intent.replace(/[_-]/g, " ");
		console.log("Showing notice for ", { data });
		// @ts-ignore
		Notice.info({
			title: this.$t("Intent detected from user in Finland"),
			desc: this.$t("User is communicating with the chatbot: {intentString}", {
				intentString
			}),
			duration: 60
		});
	}

	public async otherIntentClick(event: any, chart: any, ..._others: any) {
		this.intentClickEvent(event, chart, true);
	}

	public async intentClick(event: any, chart: any, ..._others: any) {
		this.intentClickEvent(event, chart, false);
	}

	public async intentClickEvent(event: any, chart: any, isOther = false) {
		if (typeof chart[0] !== "undefined" && typeof chart[0]._model !== "undefined") {
			const key = (isOther) ? this.otherIntents.ids[chart[0]._index] : this.topIntents.ids[chart[0]._index];
			// console.log("INTENT EVENT", event, chart, isOther, key);
			if (key === "others") {
				this.showOtherIntents = !this.showOtherIntents;
			} else if (typeof this.entity.config.intents[key] === "object" && !this.entity.config.intents[key].hide) {
				this.$router.push({
					name: "Intent",
					params: {
						intent: key,
						title: chart[0]._model.label,
						meta: this.entity.config.intents[key],
						language: this.filters.language.language,
						excludeLanguage: this.filters.language.exclude,
						country: this.filters.country.country,
						excludeCountry: this.filters.country.exclude,
						start: this.filterDateStart,
						end: this.filterDateEnd,
						entity: this.entity
					}
				});
			}
		}
	}

	public dateChange(d: any) {
		this.filters.date = d;
		this.dateOpen = false;
		this.dirtyFilter = true;
	}

	public excludeLanguageToggle() {
		if (this.filters.language.exclude === true) {
			this.filters.language.exclude = false;
			this.excludeLanguage.icon = "ios-square-outline";
		} else {
			this.filters.language.exclude = true;
			this.excludeLanguage.icon = "ios-checkbox-outline";
		}
	}

	public excludeCountryToggle() {
		if (this.filters.country.exclude === true) {
			this.filters.country.exclude = false;
			this.excludeCountry.icon = "ios-square-outline";
		} else {
			this.filters.country.exclude = true;
			this.excludeCountry.icon = "ios-checkbox-outline";
		}
	}

	public async applyFilter() {
		const date = new Date();

		if (JSON.stringify(this.filters) !== emptyFilter) {
			this.clearEnable = true;
		}
		this.dirtyFilter = false;
		// @ts-ignore
		Spin.show();

		// set Expiry for 60 minutes
		date.setMinutes(date.getMinutes() + 60);
		// Add filter to localstorage
		if(JSON.stringify(this.filters) !== emptyFilter) {
			window.localStorage.setItem("stats-filter", JSON.stringify({ expireAt: date, filters: this.filters}));
		}

		try {
			await this.loadStats();

			// Clear filter from language chart
			const languageChart = Chartkick.charts["languageChart"];
			const meta = languageChart.chart.getDatasetMeta(0);
			for (const item of meta.data) {
				item.hidden = false;
			}
			// Filter the language in the language chart
			if (this.filters.language.languageLoaded !== "" && this.filters.language.excludeLoaded === true) {
				// const languageChart = Chartkick.charts["languageChart"];
				const languageLoaded = this.iso6391.getName(this.filters.language.languageLoaded);
				const index = languageChart.data.findIndex((data: any) => data[0] === languageLoaded );
				if(index !== -1) {
					// const meta = languageChart.chart.getDatasetMeta(0);
					meta.data[index].hidden = true;
					// languageChart.chart.update();
					console.log(meta);
				}
			}
			// Hide the other language to maximize the circle area of the selected language
			if (this.filters.language.languageLoaded !== "" && this.filters.language.excludeLoaded === false) {
				// const languageChart = Chartkick.charts["languageChart"];
				const index = languageChart.data.findIndex((data: any) => data[0] === this.$t("Others") );
				if(index !== -1) {
					// const meta = languageChart.chart.getDatasetMeta(0);
					meta.data[index].hidden = true;
					// languageChart.chart.update();
				}
			}
			languageChart.chart.update();
		} catch (e) {
			alert(e);
		}
		// @ts-ignore
		Spin.hide();
	}

	public clearFilter() {
		// Clear the filter
		window.localStorage.removeItem("stats-filter");
		this.filters = JSON.parse(emptyFilter);

		// Reset UI
		this.excludeLanguage.icon = "ios-square-outline";
		this.excludeCountry.icon = "ios-square-outline";
		this.clearEnable = false;
		this.dirtyFilter = true;
	}
	// WordIntents start
	public sortWordIntents() {
		this.wordData.words.sort((a: any, b: any) => {
			const locale = this.$i18n.locale;
			if (a.name[locale] < b.name[locale]) {
				return -1;
			}
			if (a.name[locale] > b.name[locale]) {
				return 1;
			}

			// names must be equal
			return 0;
		});
	}

	public wordIntentCreate(val: string) {
		const index = this.wordData.words.findIndex((item: any) => item.name.en === val);
		if(index === -1) {
			this.wordData.words.push({ name: {en: val, fi: val}, words: [val]});
		}
	}

	public applyWordIntents() {
		const words: any = [];
		let index;
		let regex = "";
		// Copy words removing duplicates
		this.wordData.selectedWords.forEach(function(value: any){
			if (words.indexOf(value) === -1 ) {
				words.push(value);
			}
		});
		for(const word of words) {
			if (typeof word !== "undefined" && (index = this.wordData.words.findIndex((value: any) => value.name.en === word)) !== -1) {
				let regexPart = "(?=";
				let count = 0;
				for (const item of this.wordData.words[index].words) {
					regexPart += `.* ${item} `;
					count++;
					if (count < this.wordData.words[index].words.length) {
						regexPart += "|";
					} else {
						regexPart += ")";
					}
				}
				regex += regexPart;
			} else if( typeof word !== "undefined" && word !== "") {
				regex += `(?=.*${word})`;
			}
		}
		this.wordData.regex = regex;
		// console.log("REGEX:", this.wordData.regex);

		Axios.post("/stats/intent/daily", {
			start: this.filterDateStart,
			end: this.filterDateEnd,
			...(this.filters.language.language !== "" ? { language: this.filters.language.language } : {}),
			excludeLanguage: this.filters.language.exclude,
			...(this.filters.country.country !== "" ? { country: this.filters.country.country } : {}),
			excludeCountry: this.filters.country.exclude,
			regex: this.wordData.regex
		}).then(res => {
			this.dailyWordIntents = res.data;
		});
	}

	public intentDetails(intent: string) {
		if (typeof this.entity.config.intents[intent] === "object") {
			return this.entity.config.intents[intent];
		} else {
			return {
				group: "Unknown",
				hide: false,
				name: {
					// @ts-ignore
					en: intent.replace(/_-/g, " ").capitalize(),
					// @ts-ignore
					fi: intent.replace(/_-/g, " ").capitalize()
				}
			};
		}
	}

	private get wordIntents() {
		const output: any[] = [];
		const entries: any = {};
		let count: number = 0;
		const intents = [];
		let percentage: number = 0;

		try{
			for (const entry of this.dailyWordIntents) {
				const details = this.intentDetails(entry.intent);
				if (!details.hide) {
					if(typeof entries[entry.intent] !== "undefined") {
						entries[entry.intent].total += entry.total;
					} else {
						entries[entry.intent] = { total: entry.total, name: details.name[this.$i18n.locale] };
					}
				}
			}

			for (const intent of Object.keys(entries)) {
				if (count > 15) {
					break;
				}
				intents.push([entries[intent].name, entries[intent].total]);
				percentage = percentage + entries[intent].total;
				count++;
			}
			percentage = 100 / percentage;

			intents.sort((a: any, b: any) => {
				if (a[1] < b[1]) {
					return 1;
				}
				if (a[1] > b[1]) {
					return -1;
				}
				// names must be equal
				return 0;
			});

			for (const intent of intents) {
				output.push([intent[0], (intent[1] * percentage).toFixed(2)]);
				count++;
			}

			if (output.length === 0) {
				output.push([this.$t("No data for selected word(s), try refining the query"), 100]);
			}
		} catch (e) {
			console.error(e);
		}

		return output;
	}
	// WordIntents end

	private get allIntents() {
		const output: any = [];
		const othersOutput: any = [];
		const others: any = [this.$t("Others"), 0, "others"];

		for (const intent of this.intents) {
			const intentDetails = this.intentDetails(intent.intent);
			if (output.length < 10) {
				if (!intentDetails.hide) {
					output.push([intentDetails.name[this.$i18n.locale], ((intent.total / this.intentTotal) * 100).toFixed(0), intent.intent]);
				}
			} else {
				if (!intentDetails.hide) {
					others[1] += intent.total;
					othersOutput.push([intentDetails.name[this.$i18n.locale], ((intent.total / this.intentTotal) * 100).toFixed(0), intent.intent]);
				}
			}
		}

		if (others[1] > 0) {
			others[1] = ((others[1] / this.intentTotal) * 100).toFixed(0);
			output.push(others);
		}
		return {
			top: {
				data: output,
				ids: output.map((d: any) => d[2])
			},
			others: {
				data: othersOutput,
				ids: othersOutput.map((d: any) => d[2])
			}
		};
	}

	private get topIntents() {
		return this.allIntents.top;
	}

	private get otherIntents() {
		return this.allIntents.others;
	}

	private get topics() {
		const output: any = [];
		const inc: string[] = [];

		for (const entry of this.dailyIntents) {
			const details = this.intentDetails(entry.intent);
			if (!details.hide) {
				if (inc.length <= 10) {
					if (!inc.includes(entry.intent)) {
						output.push({
							name: details.name[this.$i18n.locale],
							data: {}
						});
						inc.push(entry.intent);
					}
				}

				if (inc.includes(entry.intent)) {
					output[inc.indexOf(entry.intent)].data[entry.date] = entry.total;
				}
			}
		}

		return output;
	}

	private get filterDateStart() {
		if (typeof this.filters.date[0] === "undefined") {
			return "2020-01-01 00:00:00";
		} else {
			return this.filters.date[0] + " 00:00:00";
		}
	}

	private get filterDateEnd() {
		if (typeof this.filters.date[1] === "undefined") {
			const date = new Date();
			return date.getFullYear() + 1 + "-01-01 00:00:00";
		} else {
			return this.filters.date[1] + " 00:00:00";
		}
	}

	private get languageArray() {
		if (this.filters.language.languageLoaded !== "" && this.filters.language.excludeLoaded === false) {
			// Show the selected language as a circle for clarity
			let selected = 0;
			let all = 0;
			for (const langEntry of this.languages) {
				all += langEntry.total;
				if (langEntry.language === this.filters.language.languageLoaded) {
					selected = langEntry.total;
				}
			}
			return [
				[this.iso6391.getName(this.filters.language.languageLoaded), (selected / all) * 100],
				[this.$t("Others"), ((all - selected) / all) * 100]
			];
		}
		const output: any = [];
		const others: any = [this.$t("Others"), 0];
		let sum = 0;
		for (const langEntry of this.languages) {
			if (output.length < 10) {
				sum += langEntry.total;
				output.push([this.iso6391.getName(langEntry.language), langEntry.total]);
			} else {
				sum += langEntry.total;
				others[1] += langEntry.total;
			}
		}

		if (others[1] > 0) {
			output.push(others);
		}

		// Calculate percentages ()
		for (const langEntry of output) {
			langEntry[1] = ((langEntry[1] / sum) * 100);
		}

		return output;
	}

	private get color() {
		let color = "#2db7f5";
		if (this.currentPercentage > 90) {
			color = "#5cb85c";
		}
		return color;
	}
}
