


































































































import { Component, Vue, Watch } from "vue-property-decorator";
import Axios from "axios";
import {
	Button,
	ButtonGroup,
	Modal,
	Table,
	Tag,
	Spin,
	Input,
	Form,
	FormItem,
	Card,
	Switch,
	Icon,
	Sider,
	MenuItem,
	Menu,
	Submenu,
	Content,
	Layout,
	Collapse,
	Message,
	Upload,
	Select,
	Option,
	// @ts-ignore
	Panel
} from "view-design";

Component.registerHooks(["beforeRouteEnter", "beforeRouteLeave", "beforeRouteUpdate"]);

@Component({
	components: {
		Button,
		ButtonGroup,
		Table,
		Modal,
		Tag,
		Spin,
		"i-input": Input,
		Form,
		FormItem,
		Card,
		Icon,
		Sider,
		Menu,
		MenuItem,
		Submenu,
		Content,
		Layout,
		Collapse,
		Select,
		Option,
		Panel,
		Upload,
		"i-switch": Switch
	}
})
export default class Blocks extends Vue {
	public loading = true;
	public entityConfig: any = {};
	public location: string = "";
	// public active = "config-";
	public activeBlock: any = [];
	public dirty: boolean = false;

	@Watch("entityConfig", { deep: true })
	private watchForDirtyChanges() {
		if (!this.loading) {
			this.dirty = true;
		}
	}

	public created() {
		this.loadEntity();
	}

	public updated() {
		this.loading = false;
	}

	public loadEntity() {
		// @ts-ignore
		Spin.show();
		Axios.get("/super/entity").then(res => {
			console.log(res.data);
			if (typeof res.data.config !== "undefined" && typeof res.data.config === "object") {
				// Do a sanity check
				const entityConfig = res.data.config;
				if (typeof entityConfig.intents === "undefined" || typeof entityConfig.intents !== "object") {
					entityConfig.intents = {};
				}
				if (typeof entityConfig.entities === "undefined" || typeof entityConfig.entities !== "object") {
					entityConfig.entities = {};
				}
				if (typeof entityConfig.intents === "undefined" ||  typeof entityConfig.parameters !== "object") {
					entityConfig.parameters = {};
				}
				if (typeof entityConfig.blocks === "undefined" || typeof entityConfig. blocks !== "object") {
					entityConfig.blocks = {};
				}
				for (const [_intent, intentData] of Object.entries(entityConfig.intents) as any) {
					if (typeof intentData.name !== "object") {
						intentData.name = { en: "-", fi: "-" };
					}
					if (typeof intentData.description !== "object") {
						intentData.description = { en: "", fi: "" };
					}
				}
				this.entityConfig = entityConfig;
				this.location = res.data.location;
			} else {
				// This is a new Entity
				this.entityConfig = {
					intents: {},
					entities: {},
					parameters: {},
				};
			}
			// @ts-ignore
			Spin.hide();
		}).catch(err => {
			// @ts-ignore
			Modal.error({
				title: "Error",
				content: `${err} : Try refreshing page, if error persists then contact support`
			});
		});
	}

	public async save() {
		// @ts-ignore
		Spin.show();
		Axios.patch("/super/entity", { config: this.entityConfig })
			.then(res => {
				console.log(res);
				// @ts-ignore
				Message.success({
					content: this.$t("Saved"),
					duration: 10,
					background: true
				});
				this.dirty = false;
			})
			.catch(e => {
				// @ts-ignore
				Modal.error({
					title: this.$t("Error"),
					content: this.$t("Error saving.") + " " + e
				});
			});
		// @ts-ignore
		Spin.hide();
	}

	public addBlock() {
		let input = "";
		// @ts-ignore
		Modal.confirm({
			render: (h: any) =>
				h(Input, {
					props: {
						value: "",
						autofocus: true,
						placeholder: this.$t("Block Key")
					},
					on: {
						input: (val: string) => {
							input = val.toLowerCase();
						}
					}
				}),
			onOk: () => {
				if (input === "") {
					return alert(this.$t("Cannot add a empty block key"));
				}
				if (typeof this.entityConfig.blocks[input] === "object") {
					return alert(this.$t("Block already exists."));
				}
				console.log({ input });
				const newBlock: any = {};
				newBlock[input] = {
					hide: false,
					name: {
						en: "Unknown",
						fi: "Tuntematon"
					},
					description: {
						en: "Unknown",
						fi: "Tuntematon"
					}
				};
				this.entityConfig.blocks = {
					...newBlock,
					...this.entityConfig.blocks
				};
				this.activeBlock = [input];
				this.$forceUpdate();
			}
		});
	}

	public exportBlocks() {
		const element = document.createElement("a");
		element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(JSON.stringify(this.entityConfig.blocks, undefined, "\t")));
		element.setAttribute("download", `neuvola-${this.location}-blocks-${new Date().toISOString().substr(0, 16).replace(":", "-")}.json`);
		element.style.display = "none";
		document.body.appendChild(element);
		element.click();
		document.body.removeChild(element);
	}

	public importBlocks() {
		// @ts-ignore
		Modal.confirm({
			render: (h: any) => [
				h("strong", this.$t("WARNING! Existing blocks will be replaced completely. NOT merged.")),
				h("input", {
					attrs: {
						type: "file",
						autofocus: true,
						placeholder: this.$t("Upload"),
						style: "margin-top: 15px",
						id: "neuvo-blocks-upload"
					}
				})
			],
			onOk: () => {
				try {
					// @ts-ignore
					const file = document.getElementById("neuvo-blocks-upload").files[0];
					const reader = new FileReader();
					reader.readAsText(file, "UTF-8");
					reader.onload = event => {
						try {
							this.entityConfig.blocks = JSON.parse(event?.target?.result as string);
						} catch (e) {
							alert("Error parsing file. " + e);
						}
					};
					reader.onerror = event => {
						throw event;
					};
					this.dirty = true;
					this.$forceUpdate();
				} catch (e: any) {
					alert(this.$t("Error importing. ") + e);
				}
			}
		});
	}

	public beforeRouteLeave(to: any, from: any, next: any) {
		if (this.dirty) {
			// @ts-ignore
			const answer = window.confirm(this.$t("Do you really want to leave? You have unsaved changes!"));
			if (answer) {
				next();
			} else {
				next(false);
			}
		} else {
			next();
		}
	}
}
