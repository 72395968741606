


























































/* eslint-disable @typescript-eslint/no-var-requires */

import { Component, Vue } from "vue-property-decorator";
// import MarkdownIt from "markdown-it";
import Axios from "axios";
import { CreateElement } from "vue";

import {
	Table,
	// @ts-ignore
	Panel,
	Message,
} from "view-design";

@Component({
	components: {
		Table,
		Panel,
		Message,
	},
})
export default class Entities extends Vue {
	public loading = true;

	public entityList: any = [];
	public cols: any = [];

	public async created() {
		this.loadEntities();
		this.cols = [
			{
				title: this.$t("Admins"),
				key: "entityAdminCount",
				render: (h: CreateElement, params: any) => h("div", params.row.entityAdminCount ? params.row.entityAdminCount : "-" )
			},
			{
				title: this.$t("Location"),
				key: "location"
			},
			{
				title: this.$t("LocationName"),
				key: "locationName",
				render: (h: CreateElement, params: any) => h("div", params.row.frontend?.manifest?.name)
			},
			{
				title: this.$t("AssistantId"),
				key: "assistantId",
				render: (h: CreateElement, params: any) => h("div", params.row.frontend?.assistantId)
			},
			{
				title: this.$t("Hostnames"),
				key: "hostnames",
				render: (h: CreateElement, params: any) => h("div", params.row.frontend?.hostnames.join(", "))
			},
			{
				title: this.$t("Actions"),
				slot: "actions",
				width: 200
			}
		];
	}

	public async loadEntities() {
		try {
			const {data} = await Axios.get("entities");
			this.entityList = data;
			this.loading = false;
		} catch (error) {
			this.loading = false;
			// @ts-ignore
			Message.error({
				content: this.$t("Error communicating with the server."),
				duration: 15,
				closable: true
			});
		}
	}

	public async remove(id: string) {
		if (confirm(this.$t("Are you sure you want to delete this enity? This action can not be reverted!") as string)) {
			try {
				await Axios.delete(`/entities/${id}`, {
					headers: {
						Entity: id
					}
				});

				// @ts-ignore
				Message.success({
					content: this.$t("Entity removed successfully!"),
					duration: 15,
					closable: true
				});
				this.loadEntities();
			} catch (e) {
				this.loading = false;
				alert(e);
				// @ts-ignore
				Message.error({
					content: this.$t("Error communicating with the server."),
					duration: 15,
					closable: true
				});
			}
		}
	}

	public async addEntityToAdmin(id: string, location: string) {
		if (confirm(this.$t("Are you sure you want to hijack entity") as string + ` "${location}"?`)) {
			try {
				await Axios.patch(`/entities/hijack/${id}`);
				// @ts-ignore
				Message.success({
					content: this.$t("Entity hijack success, (log out and re-login to modify entity)"),
					duration: 15,
					closable: true
				});
				this.loadEntities();
			} catch (e) {
				this.loading = false;
				alert(e);
				// @ts-ignore
				Message.error({
					content: this.$t("Error communicating with the server."),
					duration: 15,
					closable: true
				});
			}
		}
	}
}
