import Vue from "vue";
import Router from "vue-router";
import AccountSwitch from "./views/AccountSwitch.vue";
import Login from "./views/Login.vue";
import Password from "./views/Password.vue";
import Admin from "./views/Admin.vue";
import Super from "./views/Super/Super.vue";
import Intents from "./views/Super/Intents.vue";
import Feedback from "./views/Super/Feedback.vue";
import Translation from "./views/Super/Translation.vue";
import Translations from "./views/Super/Translations.vue";
import MessagePreview from "./views/Super/MessagePreview.vue";
import Conversation from "./views/Super/Conversation.vue";
import Parameters from "./views/Super/Parameters.vue";
import ContentChanges from "./views/Super/ContentChanges.vue";
import Markdown from "./views/Super/Markdown.vue";
import Audits from "./views/Super/Audits.vue";
import Home from "./views/Home.vue";
import Content from "./views/Content.vue";
// import Statistics from "./views/Statistics.vue";
import Nan from "./views/Nan.vue";
import Intent from "./views/Intent.vue";
import Reporting from "./views/Super/reporting.vue";
import BlockTracking from "./views/Super/BlockTracking.vue";
import PasswordReset from "./views/PasswordReset.vue";
import Entities from "./views/Super/Entities.vue";
import BlocksEditor from "./views/Super/Blocks.vue";
import Entity from "./views/Super/Entity.vue";
import StatsEditor from "./views/Super/Statistics.vue";
import Experimental from "./views/Super/Experimental.vue";
import EducationModules from "./views/Super/EducationModules.vue";
import EducationModule from "./views/Super/EducationModule.vue";

Vue.use(Router);

export default new Router({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		{
			path: "/",
			name: "Advanced Statistics",
			component: Home
		},
		// {
		//   path: '/statistics',
		// 	name: 'Advanced Statistic',
		// 	component: Statistics
		// },
		{
			path: "/accountswitch",
			name: "AccountSwitch",
			component: AccountSwitch
		},
		{
			path: "/login",
			name: "Login",
			component: Login
		},
		{
			path: "/passwordreset",
			name: "Password Reset",
			component: PasswordReset
		},
		{
			path: "/password",
			name: "Password",
			component: Password
		},
		{
			path: "/admin",
			name: "Admin",
			component: Admin
		},
		{
			path: "/super",
			name: "Super",
			component: Super,
			children: [
				{
					path: "parameters",
					component: Parameters
				},
				{
					path: "ai-intents",
					component: Intents
				},
				{
					path: "ai-entities",
					component: Intents
				},
				{
					path: "blockseditor",
					name: "blockseditor",
					component: BlocksEditor
				},
				{
					path: "entities",
					component: Entities
				},
				{
					path: "stats",
					component: StatsEditor
				},
				{
					path: "entities/:id",
					component: Entity
				},
				{
					path: "translations",
					component: Translations
				},
				{
					path: "translations/:id",
					component: Translation
				},
				{
					path: "messagepreview",
					component: MessagePreview
				},
				{
					path: "reporting",
					name: "Reporting",
					component: Reporting
				},
				{
					path: "blocktracking",
					name: "blocktracking",
					component: BlockTracking
				},
				{
					path: "experimental",
					name: "experimental",
					component: Experimental
				},
				{
					path: "questions",
					component: Super
				},
				{
					path: "feedback",
					component: Feedback
				},
				{
					path: "conversation/:sessionId",
					component: Conversation
				},
				{
					path: "audits",
					component: Audits
				},
				{
					path: "content-changes",
					component: ContentChanges
				},
				{
					path: "markdown",
					component: Markdown
				},
				{
					path: "education",
					component: EducationModules
				},
				{
					path: "education/:id",
					component: EducationModule
				}

			]
		},
		{
			path: "/announcements",
			name: "Announcements",
			component: Nan,
			meta: {
				features: ["Allows sending announcements through the app"]
			}
		},
		{
			path: "/impact",
			name: "Benefits & Social Impact",
			component: Nan,
			meta: {
				features: ["Gives one-page view of the type of content and gives an idea of where it can be used to have maximum impact", "Shows social impact based on defined parameters/measurements"]
			}
		},
		{
			path: "/trends",
			name: "Population Trends",
			component: Nan,
			meta: {
				features: ["Gives one-page view of all data collected", "Show interest levels of content topics", "Shows growth rates on knowledge, intentions, and questions"]
			}
		},
		{
			path: "/predictions",
			name: "Predictive Modelling",
			component: Nan,
			meta: {
				features: ["The platform uses collected data to predict future happenings in all collections", "Will give predictions based on usage, intentions and knowledge levels", "Can predict usage levels, growth of influence and growth of knowledge", "Data-based recommendations and point of views on population growth areas and where allocated resources gain the most value", "Social impact toolset"]
			}
		},
		{
			path: "/content",
			name: "Content",
			component: Content,
			meta: {}
		},
		{
			path: "/intent",
			name: "Intent",
			component: Intent
		}
	]
});
