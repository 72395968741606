var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"audits"},[_c('i-card',[_c('p',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t("Audits"))+" ")]),_c('Collapse',[_c('Panel',{attrs:{"hide-arrow":true}},[_vm._v(" "+_vm._s(_vm.$t("Show auditlog types and descriptions"))+" "),_c('p',{attrs:{"slot":"content"},slot:"content"},[_c('Table',{attrs:{"border":"","columns":_vm.auditsTypesColumns,"data":_vm.auditsTypesData,"size":"small"},scopedSlots:_vm._u([{key:"tagType",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('i-tag',{attrs:{"color":_vm.typeColor(row[column.key])}},[_vm._v(" "+_vm._s(row[column.key])+" ")])]}}])})],1)])],1),_c('Divider'),_c('div',[_c('Table',{attrs:{"border":"","tooltip-theme":"light","loading":_vm.loading,"columns":_vm.auditsColumns,"data":_vm.paginatedAuditsData,"size":"small"},scopedSlots:_vm._u([{key:"createdAt",fn:function(ref){
var row = ref.row;
return [_c('Tooltip',{attrs:{"placement":"top","transfer":""}},[_c('Time',{attrs:{"time":row.createdAt}}),_c('Time',{attrs:{"slot":"content","time":row.createdAt,"type":"datetime"},slot:"content"})],1)]}},{key:"tag",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_c('Tooltip',{attrs:{"max-width":"200","placement":"left","content":_vm.typeDescription(row.type)}},[_c('i-tag',{attrs:{"color":_vm.typeColor(row[column.key])}},[_vm._v(" "+_vm._s(row[column.key])+" ")])],1)]}}])}),_c('div',{staticStyle:{"margin":"10px","overflow":"hidden"}},[_c('div',{staticStyle:{"float":"right"}},[_c('Page',{attrs:{"total":_vm.pages,"current":_vm.currentPage},on:{"update:current":function($event){_vm.currentPage=$event}}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }