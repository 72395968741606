







































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-var-requires */

import { Component, Vue, Watch } from "vue-property-decorator";
import { CreateElement } from "vue";
import MarkdownIt from "markdown-it";
import Axios from "axios";
import isValidHostname from "is-valid-hostname";
import _ from "lodash";

import {
	Option,
	Select,
	Button,
	Modal,
	Table,
	Tag,
	Message,
	Checkbox,
	CheckboxGroup,
	Card,
} from "view-design";

interface ButtonInterface {
	label: string;
	context: string;
}

interface BackendEmergencyResponse {
	id: string;
	protocol: string;
	sendTo: string;
}

interface FrontendEmergencyResponse {
	id: string;
	message: string;
}

interface EntityInterface {
	location: string;
	backend: {
		emergencyButton: {
			responses: BackendEmergencyResponse[];
			defProtocol: string;
			defSendTo: string;
		};
	};
	frontend: {
		hostnames: string[];
		locationName: string;
		startBtns: ButtonInterface[];
		languages: {
			default: string;
			top: string[];
		};
		emergencyButton: {
			enabled: boolean;
			text: string;
			message: string;
			displayWelcome: boolean;
			intent: string;
			options: {
				contactName: boolean;
				contactNumber: boolean;
				houseNumber: boolean;
			};
			responses: FrontendEmergencyResponse[];
		};
		assistantId: string;
		manifest: {
			name: string;
			short_name: string;
		};
		welcome: string;
		logo: string;
	};
}

const emptyEntity = JSON.stringify({
	location: "",
	speech: {
		enabled: "",
		allowedLanguages: []
	},
	backend: {
		emergencyButton: {
			responses: [],
			defProtocol: "sms",
			defSendTo: "",
		}
	},
	frontend: {
		hostnames: [],
		timezone: "",
		dashfeatures: [],
		locationName: "",
		startBtns: [],
		languages: {
			default: "",
			top:[]
		},
		emergencyButton: {
			enabled: false,
			text: "Emergency",
			message: "By clicking send you consent to your current location being sent to the relevant service",
			displayWelcome: false,
			intent: "",
			options: {
				contactName: false,
				contactNumber: false,
				houseNumber: false
			},
			responses: []
		},
		assistantId: "",
		manifest: {
			name: "",
			short_name: "",
		},
		welcome: "",
		logo: ""
	}
});

@Component({
	components: {
		Option,
		Select,
		Button,
		Table,
		Modal,
		Tag,
		Message,
		Checkbox,
		CheckboxGroup,
		Card
	},
})

export default class Entities extends Vue {
	public loading = true;
	public dirty = false;
	public languages = [];
	public languagesWithSpeechSupport = [];
	public hostnameToAdd = "";
	public entityDataFromDB: EntityInterface = JSON.parse(emptyEntity);
	public entityData: EntityInterface = JSON.parse(emptyEntity);
	public md: any;
	public config: any = {};

	public buttonSection = {
		btnColumns: {},
		modals: {
			addButton: {
				visible: false,
				label: "",
				context: "",
			},
			editButton: {
				visible: false,
				label: "",
				context: "",
				index: 0,
			}
		}
	};

	public emergencyButtonSection = {
		columns: {},
		responseData: [] as any,
		modals: {
			addResponse: {
				visible: false,
				id: "",
				message: "",
				protocol: "",
				sendTo: "",
				reset() {
					this.visible = false;
					this.id = "";
					this.message = "";
					this.protocol = "";
					this.sendTo = "";
				}
			},
			editResponse: {
				visible: false,
				id: "",
				message: "",
				protocol: "",
				sendTo: "",
				reset() {
					this.visible = false;
					this.id = "";
					this.message = "";
					this.protocol = "";
					this.sendTo = "";
				}
			}
		}
	};

	public ruleValidate = {
		location: [
			{ required: true, message: this.$t("Location cannot be empty"), trigger: "blur" }
		]
	};

	public timezones = [
		"Europe/Andorra",
		"Asia/Dubai",
		"Asia/Kabul",
		"Europe/Tirane",
		"Asia/Yerevan",
		"Antarctica/Casey",
		"Antarctica/Davis",
		"Antarctica/DumontDUrville", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
		"Antarctica/Mawson",
		"Antarctica/Palmer",
		"Antarctica/Rothera",
		"Antarctica/Syowa",
		"Antarctica/Troll",
		"Antarctica/Vostok",
		"America/Argentina/Buenos_Aires",
		"America/Argentina/Cordoba",
		"America/Argentina/Salta",
		"America/Argentina/Jujuy",
		"America/Argentina/Tucuman",
		"America/Argentina/Catamarca",
		"America/Argentina/La_Rioja",
		"America/Argentina/San_Juan",
		"America/Argentina/Mendoza",
		"America/Argentina/San_Luis",
		"America/Argentina/Rio_Gallegos",
		"America/Argentina/Ushuaia",
		"Pacific/Pago_Pago",
		"Europe/Vienna",
		"Australia/Lord_Howe",
		"Antarctica/Macquarie",
		"Australia/Hobart",
		"Australia/Currie",
		"Australia/Melbourne",
		"Australia/Sydney",
		"Australia/Broken_Hill",
		"Australia/Brisbane",
		"Australia/Lindeman",
		"Australia/Adelaide",
		"Australia/Darwin",
		"Australia/Perth",
		"Australia/Eucla",
		"Asia/Baku",
		"America/Barbados",
		"Asia/Dhaka",
		"Europe/Brussels",
		"Europe/Sofia",
		"Atlantic/Bermuda",
		"Asia/Brunei",
		"America/La_Paz",
		"America/Noronha",
		"America/Belem",
		"America/Fortaleza",
		"America/Recife",
		"America/Araguaina",
		"America/Maceio",
		"America/Bahia",
		"America/Sao_Paulo",
		"America/Campo_Grande",
		"America/Cuiaba",
		"America/Santarem",
		"America/Porto_Velho",
		"America/Boa_Vista",
		"America/Manaus",
		"America/Eirunepe",
		"America/Rio_Branco",
		"America/Nassau",
		"Asia/Thimphu",
		"Europe/Minsk",
		"America/Belize",
		"America/St_Johns",
		"America/Halifax",
		"America/Glace_Bay",
		"America/Moncton",
		"America/Goose_Bay",
		"America/Blanc-Sablon",
		"America/Toronto",
		"America/Nipigon",
		"America/Thunder_Bay",
		"America/Iqaluit",
		"America/Pangnirtung",
		"America/Atikokan",
		"America/Winnipeg",
		"America/Rainy_River",
		"America/Resolute",
		"America/Rankin_Inlet",
		"America/Regina",
		"America/Swift_Current",
		"America/Edmonton",
		"America/Cambridge_Bay",
		"America/Yellowknife",
		"America/Inuvik",
		"America/Creston",
		"America/Dawson_Creek",
		"America/Fort_Nelson",
		"America/Vancouver",
		"America/Whitehorse",
		"America/Dawson",
		"Indian/Cocos",
		"Europe/Zurich",
		"Africa/Abidjan",
		"Pacific/Rarotonga",
		"America/Santiago",
		"America/Punta_Arenas",
		"Pacific/Easter",
		"Asia/Shanghai",
		"Asia/Urumqi",
		"America/Bogota",
		"America/Costa_Rica",
		"America/Havana",
		"Atlantic/Cape_Verde",
		"America/Curacao",
		"Indian/Christmas",
		"Asia/Nicosia",
		"Asia/Famagusta",
		"Europe/Prague",
		"Europe/Berlin",
		"Europe/Copenhagen",
		"America/Santo_Domingo",
		"Africa/Algiers",
		"America/Guayaquil",
		"Pacific/Galapagos",
		"Europe/Tallinn",
		"Africa/Cairo",
		"Africa/El_Aaiun",
		"Europe/Madrid",
		"Africa/Ceuta",
		"Atlantic/Canary",
		"Europe/Helsinki",
		"Pacific/Fiji",
		"Atlantic/Stanley",
		"Pacific/Chuuk",
		"Pacific/Pohnpei",
		"Pacific/Kosrae",
		"Atlantic/Faroe",
		"Europe/Paris",
		"Europe/London",
		"Asia/Tbilisi",
		"America/Cayenne",
		"Africa/Accra",
		"Europe/Gibraltar",
		"America/Godthab",
		"America/Danmarkshavn",
		"America/Scoresbysund",
		"America/Thule",
		"Europe/Athens",
		"Atlantic/South_Georgia",
		"America/Guatemala",
		"Pacific/Guam",
		"Africa/Bissau",
		"America/Guyana",
		"Asia/Hong_Kong",
		"America/Tegucigalpa",
		"America/Port-au-Prince",
		"Europe/Budapest",
		"Asia/Jakarta",
		"Asia/Pontianak",
		"Asia/Makassar",
		"Asia/Jayapura",
		"Europe/Dublin",
		"Asia/Jerusalem",
		"Asia/Kolkata",
		"Indian/Chagos",
		"Asia/Baghdad",
		"Asia/Tehran",
		"Atlantic/Reykjavik",
		"Europe/Rome",
		"America/Jamaica",
		"Asia/Amman",
		"Asia/Tokyo",
		"Africa/Nairobi",
		"Asia/Bishkek",
		"Pacific/Tarawa",
		"Pacific/Enderbury",
		"Pacific/Kiritimati",
		"Asia/Pyongyang",
		"Asia/Seoul",
		"Asia/Almaty",
		"Asia/Qyzylorda",
		"Asia/Qostanay", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
		"Asia/Aqtobe",
		"Asia/Aqtau",
		"Asia/Atyrau",
		"Asia/Oral",
		"Asia/Beirut",
		"Asia/Colombo",
		"Africa/Monrovia",
		"Europe/Vilnius",
		"Europe/Luxembourg",
		"Europe/Riga",
		"Africa/Tripoli",
		"Africa/Casablanca",
		"Europe/Monaco",
		"Europe/Chisinau",
		"Pacific/Majuro",
		"Pacific/Kwajalein",
		"Asia/Yangon",
		"Asia/Ulaanbaatar",
		"Asia/Hovd",
		"Asia/Choibalsan",
		"Asia/Macau",
		"America/Martinique",
		"Europe/Malta",
		"Indian/Mauritius",
		"Indian/Maldives",
		"America/Mexico_City",
		"America/Cancun",
		"America/Merida",
		"America/Monterrey",
		"America/Matamoros",
		"America/Mazatlan",
		"America/Chihuahua",
		"America/Ojinaga",
		"America/Hermosillo",
		"America/Tijuana",
		"America/Bahia_Banderas",
		"Asia/Kuala_Lumpur",
		"Asia/Kuching",
		"Africa/Maputo",
		"Africa/Windhoek",
		"Pacific/Noumea",
		"Pacific/Norfolk",
		"Africa/Lagos",
		"America/Managua",
		"Europe/Amsterdam",
		"Europe/Oslo",
		"Asia/Kathmandu",
		"Pacific/Nauru",
		"Pacific/Niue",
		"Pacific/Auckland",
		"Pacific/Chatham",
		"America/Panama",
		"America/Lima",
		"Pacific/Tahiti",
		"Pacific/Marquesas",
		"Pacific/Gambier",
		"Pacific/Port_Moresby",
		"Pacific/Bougainville",
		"Asia/Manila",
		"Asia/Karachi",
		"Europe/Warsaw",
		"America/Miquelon",
		"Pacific/Pitcairn",
		"America/Puerto_Rico",
		"Asia/Gaza",
		"Asia/Hebron",
		"Europe/Lisbon",
		"Atlantic/Madeira",
		"Atlantic/Azores",
		"Pacific/Palau",
		"America/Asuncion",
		"Asia/Qatar",
		"Indian/Reunion",
		"Europe/Bucharest",
		"Europe/Belgrade",
		"Europe/Kaliningrad",
		"Europe/Moscow",
		"Europe/Simferopol",
		"Europe/Kirov",
		"Europe/Astrakhan",
		"Europe/Volgograd",
		"Europe/Saratov",
		"Europe/Ulyanovsk",
		"Europe/Samara",
		"Asia/Yekaterinburg",
		"Asia/Omsk",
		"Asia/Novosibirsk",
		"Asia/Barnaul",
		"Asia/Tomsk",
		"Asia/Novokuznetsk",
		"Asia/Krasnoyarsk",
		"Asia/Irkutsk",
		"Asia/Chita",
		"Asia/Yakutsk",
		"Asia/Khandyga",
		"Asia/Vladivostok",
		"Asia/Ust-Nera",
		"Asia/Magadan",
		"Asia/Sakhalin",
		"Asia/Srednekolymsk",
		"Asia/Kamchatka",
		"Asia/Anadyr",
		"Asia/Riyadh",
		"Pacific/Guadalcanal",
		"Indian/Mahe",
		"Africa/Khartoum",
		"Europe/Stockholm",
		"Asia/Singapore",
		"America/Paramaribo",
		"Africa/Juba",
		"Africa/Sao_Tome",
		"America/El_Salvador",
		"Asia/Damascus",
		"America/Grand_Turk",
		"Africa/Ndjamena",
		"Indian/Kerguelen",
		"Asia/Bangkok",
		"Asia/Dushanbe",
		"Pacific/Fakaofo",
		"Asia/Dili",
		"Asia/Ashgabat",
		"Africa/Tunis",
		"Pacific/Tongatapu",
		"Europe/Istanbul",
		"America/Port_of_Spain",
		"Pacific/Funafuti",
		"Asia/Taipei",
		"Europe/Kiev",
		"Europe/Uzhgorod",
		"Europe/Zaporozhye",
		"Pacific/Wake",
		"America/New_York",
		"America/Detroit",
		"America/Kentucky/Louisville",
		"America/Kentucky/Monticello",
		"America/Indiana/Indianapolis",
		"America/Indiana/Vincennes",
		"America/Indiana/Winamac",
		"America/Indiana/Marengo",
		"America/Indiana/Petersburg",
		"America/Indiana/Vevay",
		"America/Chicago",
		"America/Indiana/Tell_City",
		"America/Indiana/Knox",
		"America/Menominee",
		"America/North_Dakota/Center",
		"America/North_Dakota/New_Salem",
		"America/North_Dakota/Beulah",
		"America/Denver",
		"America/Boise",
		"America/Phoenix",
		"America/Los_Angeles",
		"America/Anchorage",
		"America/Juneau",
		"America/Sitka",
		"America/Metlakatla",
		"America/Yakutat",
		"America/Nome",
		"America/Adak",
		"Pacific/Honolulu",
		"America/Montevideo",
		"Asia/Samarkand",
		"Asia/Tashkent",
		"America/Caracas",
		"Asia/Ho_Chi_Minh",
		"Pacific/Efate",
		"Pacific/Wallis",
		"Pacific/Apia",
		"Africa/Johannesburg"
	];

	@Watch("entityData", { deep: true })
	private watchForDirtyChanges() {
		if(JSON.stringify(this.entityData) === JSON.stringify(this.entityDataFromDB)) {
			this.dirty = false;
		} else {
			this.dirty = true;
		}
	}

	public async created() {
		this.createColumns();

		if(typeof process.env.VUE_APP_LANGUAGES === "undefined") {
			console.error("VUE_APP_LANGUAGES env missing");
			// @ts-ignore
			Message.error({
				content: this.$t("VUE_APP_LANGUAGES env missing"),
				duration: 15,
				closable: true
			});
			return;
		}

		try {
			const languages = await Axios.get(process.env.VUE_APP_LANGUAGES as string, {transformRequest: (noData, headers) => {
				delete headers.common["Authorization"];
				delete headers.common["Entity"];
			}
			});
			this.languages = languages.data.sort((a: any, b: any) => (a.englishName > b.englishName) ? 1 : -1);
			this.languagesWithSpeechSupport = languages.data.filter((language: { speech: boolean }) => language.speech == true).sort((a: any, b: any) => (a.englishName > b.englishName) ? 1 : -1);
		} catch (error) {
			throw this.$t("Error communicating with the server.");
		}

		if(this.$route.params.id !== "add") {
			this.loadEntity();
		} else {
		// Adding new entity
			this.entityData = JSON.parse(emptyEntity);
			this.loading = false;
		}

		this.md = new MarkdownIt({
			linkify: true,
			breaks: true
		})
			.use(require("markdown-it-link-attributes"), {
				attrs: {
					target: "_blank",
					rel: "noopener"
				}
			})
			.use(require("markdown-it-bracketed-spans"))
			.use(require("markdown-it-attrs"), {
				allowedAttributes: ["translate", "class"]
			})
			.use(require("markdown-it-container"), "block");
	}

	public addHostName(hostname: string) {

		if(this.entityData.frontend.hostnames?.includes(hostname) === true) {
			alert("Hostname already added.");
			return;
		}

		if(!isValidHostname(hostname)) {
			alert(` "${hostname}" is not valid hostname`);
			return;
		}

		this.entityData.frontend.hostnames.push(hostname);
		this.hostnameToAdd = "";
	}

	public deleteHostName(hostnameToDelete: string) {
		const index = this.entityData?.frontend?.hostnames.indexOf(hostnameToDelete);
		this.entityData?.frontend?.hostnames.splice(index, 1);
	}

	public async loadEntity() {
		try {
			const { data } = await Axios.get(`entities/${this.$route.params.id}`);
			const configData = await Axios.get(`entities/config/${this.$route.params.id}`);
			if(data === null || configData === null) {
				// @ts-ignore
				Message.error({
					content: this.$t("Entity not found."),
					duration: 15,
					closable: true
				});
				return;
			}
			this.config = configData.data.config;
			if (typeof this.config === "undefined" || typeof this.config.intents === "undefined") {
				this.config = { intents: {} };
			}

			this.entityData = _.merge(JSON.parse(emptyEntity),data);
			this.entityDataFromDB = _.merge(JSON.parse(emptyEntity),data);

			// Setup emergency button response data
			for (const response of this.entityData.frontend.emergencyButton.responses) {
				const index = this.entityData.backend.emergencyButton.responses.findIndex(resp => resp.id === response.id);
				if (index !== -1) {
					const mixedResponse = {
						id: response.id,
						message: response.message,
						protocol: this.entityData.backend.emergencyButton.responses[index].protocol,
						sendTo: this.entityData.backend.emergencyButton.responses[index].sendTo,
					};
					this.emergencyButtonSection.responseData.push(mixedResponse);
				}
			}
			this.loading = false;
		} catch (error) {
			this.loading = false;
			throw this.$t("Error communicating with the server.");
		}
	}

	public saveEntity() {

		(this.$refs["entityFormValidate"] as any).validate(async (valid: boolean) => {
			if (valid) {
				try {

					// Adding new entity
					if(this.$route.params.id === "add") {
						const {data} = await Axios.post("entities", this.entityData);
						this.$router.push(`/super/entities/${data._id}`);
					} else {
						// Updateing existing entity
						await Axios.patch(`entities/${this.$route.params.id}`, this.entityData, {
							headers: {
								Entity: this.$route.params.id
							}
						});
					}

					// @ts-ignore
					Message.success({
						content: this.$t("Entity saved successfully!"),
						duration: 15,
						closable: true
					});

				} catch (error) {
					// @ts-ignore
					Message.error({
						content: this.$t("Error communicating with the server."),
						duration: 15,
						closable: true
					});
					throw this.$t("Error communicating with the server.");
				}

			} else {
				// @ts-ignore
				Message.error({
					content: this.$t("Form not valid, check fields."),
					duration: 15,
					closable: true
				});
			}
		});
	}

	public onFileChange(e: any) {
		// If no file selected
		if(!e.target.files[0]) {
			return;
		}
		const selectedImage = e.target.files[0]; // get first file
		this.createBase64Image(selectedImage);
	}

	public createBase64Image(fileObject: any) {
		const reader = new FileReader();
		reader.onload = e => {
			this.$set(this.entityData.frontend, "logo", e?.target?.result);
		};
		reader.readAsDataURL(fileObject);
	}

	public removeLogo() {
		this.$delete(this.entityData.frontend, "logo");
		(this.$refs.logoFileInput as HTMLInputElement).value = "";
	}

	public get html() {
		return this.md.render(this.entityData.frontend.welcome);
	}

	public async addButton(type: string) {
		if(type === "button") {
			if(this.entityData.frontend.startBtns.length === 3) {
				// @ts-ignore
				Modal.info({
					title: "Cannot Add More Buttons",
					content: "The maximum number of buttons is 3"
				});
				return;
			}
			this.buttonSection.modals.addButton.visible = true;
		} else if (type === "modal") {
			const re = /^[a-z0-9_-]*$/;
			const localLabel = this.buttonSection.modals.addButton.label;
			// lowercase the context
			let localContext = this.buttonSection.modals.addButton.context;
			localContext = localContext.toLowerCase();
			localContext = localContext.replaceAll(" ", "_");
			if(!re.test(localContext)) {
				// @ts-ignore
				Modal.error({
					title: this.$t("Invalid String"),
					content: this.$t("There are invalid characters in the context"),
					onOk: () => {
						this.buttonSection.modals.addButton.visible = true;
					}
				});
				// this.buttonSection.modals.addButton.visible = true;
				return;
			}
			if(this.entityData.frontend.startBtns.findIndex(button => button.label === localLabel) !== -1 || localLabel === "") {
				// @ts-ignore
				Modal.error({
					title: this.$t("Duplicate Or Empty Label"),
					content: this.$t("The label must be unique and not empty"),
					onOk: () => {
						this.buttonSection.modals.addButton.visible = true;
					}
				});
				// this.buttonSection.modals.addButton.visible = true;
				return;
			}
			this.entityData.frontend.startBtns.push({
				label: localLabel,
				context: localContext
			});
		} else {
			// Cancel was clicked
		}
		// Cleanup
		this.buttonSection.modals.addButton.label = "";
		this.buttonSection.modals.addButton.context = "";
	}

	public editButton(type: string, row: any) {
		if(type === "button") {
			// Lets get the index of the button and store it
			this.buttonSection.modals.editButton.index = this.entityData.frontend.startBtns.findIndex(button => button.label === row.label);
			if(this.buttonSection.modals.editButton.index === -1) {
				// @ts-ignore
				Modal.error({
					title: "Cannot Find Button",
					content: "The button could not be found"
				});
				return;
			}
			this.buttonSection.modals.editButton.label = row.label;
			this.buttonSection.modals.editButton.context = row.context;
			this.buttonSection.modals.editButton.visible = true;
			return;
		} else if(type === "modal") {
			console.log("Edit - Modal");
			const re = /^[a-z0-9_-]*$/;
			const localLabel = this.buttonSection.modals.editButton.label;
			// lowercase the context
			let localContext = this.buttonSection.modals.editButton.context;
			localContext = localContext.toLowerCase();
			localContext = localContext.replaceAll(" ", "_");
			if(!re.test(localContext)) {
				// @ts-ignore
				Modal.error({
					title: this.$t("Invalid String"),
					content: this.$t("There are invalid characters in the context"),
					onOk: () => {
						this.buttonSection.modals.editButton.visible = true;
					}
				});
				return;
			}
			if(this.entityData.frontend.startBtns.findIndex((button, index) => button.label === localLabel && index !== this.buttonSection.modals.editButton.index) !== -1 || localLabel === "") {
				// @ts-ignore
				Modal.error({
					title: this.$t("Duplicate Or Empty Label"),
					content: this.$t("The label must be unique and not empty"),
					onOk: () => {
						this.buttonSection.modals.editButton.visible = true;
					}
				});
				return;
			}
			this.entityData.frontend.startBtns[this.buttonSection.modals.editButton.index].label = localLabel;
			this.entityData.frontend.startBtns[this.buttonSection.modals.editButton.index].context = localContext;
		} else {
			// Cancel was clicked
		}
		// Cleanup
		this.buttonSection.modals.editButton.index = 0;
		this.buttonSection.modals.editButton.label = "";
		this.buttonSection.modals.editButton.context = "";
	}

	public deleteButton(row: any) {
		// @ts-ignore
		Modal.confirm({
			title: this.$t("Confirm delete"),
			content: this.$t(`Are you sure you want to delete the button ${row.label}`),
			onOk: () => {
				const index = this.entityData.frontend.startBtns.findIndex(button => button.label === row.label);
				if(index === -1) {
					// @ts-ignore
					Modal.error({
						title: this.$t("Not Found"),
						content: this.$t("The button to be deleted could not be found")
					});
					return;
				}
				this.entityData.frontend.startBtns.splice(index, 1);
			}
		});
	}

	public onResponseAddOk() {
		// Validate
		if(this.validateResponseData("add") === false) {
			// @ts-ignore
			Modal.error({
				title: this.$t("Validation Error"),
				content: this.$t("The response has invalid or missing data")
			});
			this.emergencyButtonSection.modals.addResponse.reset();
			return;
		}
		// Check id does not already exist
		const response = this.emergencyButtonSection.modals.addResponse;
		response.id = response.id.toLowerCase().replaceAll(" ", "_");
		const index = this.emergencyButtonSection.responseData.findIndex((resp: any) => resp.id === response.id);
		if (index !== -1) {
			return;
		}
		this.entityData.backend.emergencyButton.responses.push({ id: response.id, protocol: response.protocol, sendTo: response.sendTo });
		this.entityData.frontend.emergencyButton.responses.push({ id: response.id, message: response.message });
		this.emergencyButtonSection.responseData.push({ id: response.id, message: response.message, protocol: response.protocol, sendTo: response.sendTo });
		this.emergencyButtonSection.modals.addResponse.reset();
	}

	public onResponseEdit(row: any) {
		this.emergencyButtonSection.modals.editResponse.id = row.id;
		this.emergencyButtonSection.modals.editResponse.message = row.message;
		this.emergencyButtonSection.modals.editResponse.protocol = row.protocol;
		this.emergencyButtonSection.modals.editResponse.sendTo = row.sendTo;
		this.emergencyButtonSection.modals.editResponse.visible = true;
	}

	public onResponseEditOk() {
		// Validate then add changes
		if(this.validateResponseData("edit") === false) {
			// @ts-ignore
			Modal.error({
				title: this.$t("Validation Error"),
				content: this.$t("The response has invalid or missing data")
			});
			this.emergencyButtonSection.modals.editResponse.reset();
			return;
		}
		const response = this.emergencyButtonSection.modals.editResponse;
		const backendIndex = this.entityData.backend.emergencyButton.responses.findIndex(resp => resp.id === response.id);
		const frontendIndex = this.entityData.frontend.emergencyButton.responses.findIndex(resp => resp.id === response.id);
		const mixedIndex = this.emergencyButtonSection.responseData.findIndex((resp: any) => resp.id === response.id);

		if (backendIndex === -1 || frontendIndex === -1 || mixedIndex === -1) {
			return;
		}

		this.entityData.backend.emergencyButton.responses[backendIndex].protocol = response.protocol;
		this.entityData.backend.emergencyButton.responses[backendIndex].sendTo = response.sendTo;
		this.entityData.frontend.emergencyButton.responses[backendIndex].message = response.message;
		this.emergencyButtonSection.responseData[mixedIndex].message = response.message;
		this.emergencyButtonSection.responseData[mixedIndex].protocol = response.protocol;
		this.emergencyButtonSection.responseData[mixedIndex].sendTo = response.sendTo;
		this.emergencyButtonSection.modals.editResponse.reset();
	}

	public onResponseDelete(row: any) {
		// @ts-ignore
		Modal.confirm({
			title: this.$t("Confirm delete"),
			content: this.$t(`Are you sure you want to delete the response ${row.id}`),
			onOk: () => {
				const backendIndex = this.entityData.backend.emergencyButton.responses.findIndex(resp => resp.id === row.id);
				const frontendIndex = this.entityData.frontend.emergencyButton.responses.findIndex(resp => resp.id === row.id);
				const mixedIndex = this.emergencyButtonSection.responseData.findIndex((resp: any) => resp.id === row.id);
				if(backendIndex === -1 || frontendIndex === -1 || mixedIndex === -1) {
					// @ts-ignore
					Modal.error({
						title: this.$t("Not Found"),
						content: this.$t("The response to be deleted could not be found")
					});
					return;
				}
				this.entityData.frontend.emergencyButton.responses.splice(frontendIndex, 1);
				this.entityData.backend.emergencyButton.responses.splice(backendIndex, 1);
				this.emergencyButtonSection.responseData.splice(mixedIndex, 1);
			}
		});
	}

	public validateResponseData(type: string) {
		let result = true;
		let responseData;
		// let responseId;

		if (type === "edit") {
			responseData = this.emergencyButtonSection.modals.editResponse;
			// responseId = this.$refs.editResponseId as any;
		} else {
			responseData = this.emergencyButtonSection.modals.addResponse;
			// responseId = this.$refs.addResponseId as any;
		}

		console.log(responseData);
		if (type !== "edit" && responseData.id === "") {
			// responseId.$el.style.background = "#FFAAAA";
			result = false;
		}
		return result;
	}

	private createColumns() {
		this.buttonSection.btnColumns = [
			{
				title: this.$t("Label"),
				key: "label",
			},
			{
				title: this.$t("Context"),
				key: "context",
			},
			{
				title: this.$t("Actions"),
				fixed: "right",
				render: (h: CreateElement, params: any) =>
					h("div", [
						h(
							"Button",
							{
								props: {
									type: "default",
									size: "small"
								},
								on: {
									click: () => {
										this.editButton("button", params.row);
									}
								}
							},
							this.$t("Edit") as string
						),
						h(
							"Button",
							{
								props: {
									type: "error",
									size: "small"
								},
								on: {
									click: () => {
										this.deleteButton(params.row);
									}
								}
							},
							this.$t("Remove") as string
						)
					])
			}
		];
		this.emergencyButtonSection.columns = [
			{
				title: this.$t("ID"),
				key: "id",
			},
			{
				title: this.$t("Message"),
				key: "message",
			},
			{
				title: this.$t("Protocol"),
				key: "protocol",
			},
			{
				title: this.$t("Send To"),
				key: "sendTo",
			},
			{
				title: this.$t("Actions"),
				fixed: "right",
				render: (h: CreateElement, params: any) =>
					h("div", [
						h(
							"Button",
							{
								props: {
									type: "default",
									size: "small"
								},
								on: {
									click: () => {
										this.onResponseEdit(params.row);
									}
								}
							},
							this.$t("Edit") as string
						),
						h(
							"Button",
							{
								props: {
									type: "error",
									size: "small"
								},
								on: {
									click: () => {
										this.onResponseDelete(params.row);
									}
								}
							},
							this.$t("Remove") as string
						)
					])
			}
		];
	}
}
