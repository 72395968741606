





































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-var-requires */
import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import slugify from "slugify";
import MarkdownIt from "markdown-it";
import { v4 as uuidv4 } from "uuid";

import {
	Rate,
	Time,
	Poptip,
	Tooltip,
	Table,
	Collapse,
	// @ts-ignore
	Panel,
	Tabs,
	TabPane,
	CheckboxGroup,
	Checkbox,
	Option,
	Select,
	DropdownItem,
	Message,
	Alert,
	Modal

} from "view-design";

interface EducationOptionInterface {
	id: string;
	option_title: string;
	correct: boolean;
}

interface EducationQuestionInterface {
	id: string;
	question_title: string;
	options: EducationOptionInterface[];
}

interface EducationSectionInterface {
	slug: string;
	title: string;
	questions: EducationQuestionInterface[];
}

interface EducationInterface {
	title: string;
	description: string;
	markdown: string;
	congratsMarkdown: string;
	practiseMarkdown: string;
	intents: string[];
	locations: string[];
	sections: EducationSectionInterface[];
}

@Component({
	components: {
		Poptip,
		Rate,
		Time,
		Tooltip,
		Table,
		Collapse,
		Panel,
		Tabs,
		TabPane,
		CheckboxGroup,
		Checkbox,
		Option,
		Select,
		DropdownItem,
		Message,
		Alert,
		Modal
	}
})
export default class EducationModule extends Vue {

	public errors: any = null;
	public isModified: boolean = false;
	public tabsContextData: any;
	public origEducatonData: EducationInterface = {
		title: "",
		description: "",
		markdown: "",
		congratsMarkdown: "",
		practiseMarkdown: "",
		intents: [],
		locations: [],
		sections: []
	};

	public defaultContentMarkdown = `# Heading
Lorem ipsum dolor sit amet consectetur adipisicing elit.
- First
- Second`;

	public defaultCongratsMarkdown = `# Congrats - Certificate
Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`;

	public defaultPractiseMarkdown = `# Need some practise
Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`;

	public md: any;
	public showMarkdownPreview = {
		content: false,
		congrats: false,
		practise: false
	};

	public intents: string[] = [];
	public entities: any = [];
	public addingNewSection = false;
	public newTabName = "";
	public editTabNameBoolean = false;

	public loading = true;
	public data: EducationInterface = {
		title: "",
		description: "",
		markdown: "",
		congratsMarkdown: "",
		practiseMarkdown: "",
		intents: [],
		locations: [],
		sections: []
	};
	public activeTab = "";

	// public get html() {
	// 	return this.md.render(this.data.markdown as string);
	// }

	@Watch("data", { immediate: true, deep: true })
	public watchData(newData: EducationInterface) {
		const isModified = !(JSON.stringify(this.origEducatonData) === JSON.stringify(newData));
		this.isModified = isModified;

		// Remove possible error when isModified changes to false
		if(!isModified) {
			this.errors = null;
		}
	}

	@Watch("addingNewSection")
	public watchAddingNewSection(addingNewSection: boolean) {
		if (!addingNewSection) {
			return;
		}
		// Add short delay to first modal show up and then set focus input
		setTimeout(() => {
			// @ts-ignore
			this.$refs.addNewSectionInput.focus({ cursor: "start"});
		}, 100);
	}

	@Watch("editTabNameBoolean")
	public watchEditTabNameBoolean(editSectionName: boolean) {
		if (!editSectionName) {
			return;
		}
		// Add short delay to first modal show up and then set focus input
		setTimeout(() => {
			// @ts-ignore
			this.$refs.editSectionName.focus({ cursor: "start"});
		}, 100);
	}

	public async created() {
		this.initMarkdown();
		await this.getIntents();
		await this.getEntities()
		this.loadEducationModule(this.$route.params.id);
		this.activeTab = this.data?.sections[0]?.slug;
	}

	public emptyQuestion(): EducationQuestionInterface {
		const emptyQuestion: EducationQuestionInterface  = {
			id: uuidv4(),
			question_title: "",
			options: [
				{
					id: uuidv4(),
					option_title: "",
					correct: false
				},
				{
					id: uuidv4(),
					option_title: "",
					correct: false
				}
			]
		};
		return emptyQuestion;
	}

	public initMarkdown() {
		this.md = new MarkdownIt({
			linkify: true,
			breaks: true
		})
			.use(require("markdown-it-link-attributes"), {
				attrs: {
					target: "_blank",
					rel: "noopener"
				}
			})
			.use(require("markdown-it-bracketed-spans"))
			.use(require("markdown-it-attrs"), {
				allowedAttributes: ["translate", "class"]
			})
			.use(require("markdown-it-container"), "block");
	}

	public html(markdown: string) {
		return this.md.render(markdown);
		// this.data.markdown as string
	}

	public async getEntities() {
		try {
			const { data } = await axios.get("entities");
			console.log(data);
			this.entities = data;
		} catch (err) {
			console.error(err)
		}
	}

	public async getIntents() {
		try {
			const { data: { config: { intents } } } = await axios.get("/super/entity");
			this.intents = Object.keys(intents);
		} catch (err) {
			console.error(err);
		}
	}

	public async loadEducationModule(id: string) {
		this.loading = true;

		if(id === "new") {
			// If creating new education populate markdown text with default markdown text
			this.data.markdown = this.defaultContentMarkdown;
			this.data.congratsMarkdown = this.defaultCongratsMarkdown;
			this.data.practiseMarkdown = this.defaultPractiseMarkdown;
			this.origEducatonData = JSON.parse(JSON.stringify(this.data));
			this.loading = false;
			return;
		}

		try {
			// Load education by id
			const { data: educationDataFetch } =  await axios.get(`/education/${id}`);
			this.origEducatonData = JSON.parse(JSON.stringify(educationDataFetch));
			this.data = educationDataFetch;
			this.loading = false;
		} catch (err: any) {

			if(err?.response?.status === 404) {
				// @ts-ignore
				Message.error({
					content: this.$t("Education not found"),
					duration: 10,
					background: true
				});
				this.$router.push("/super/education");
				return;
			}
			console.error(err);
			this.loading = false;
		}
	}

	public removeQuestion(indexSection: number, indexQuestion: number) {
		const questionData = this.data.sections[indexSection].questions[indexQuestion];

		// If empty question delete without confirm
		if(JSON.stringify(this.emptyQuestion()) === JSON.stringify(questionData)) {
			this.data.sections[indexSection].questions.splice(indexQuestion, 1);
			return;
		}

		// @ts-ignore
		Modal.confirm({
			title: this.$t("Remove question"),
			content: `${this.$t("Question")}: "${questionData.question_title}"`,
			okText: this.$t("Remove"),
			onOk: () => {
				this.data.sections[indexSection].questions.splice(indexQuestion, 1);
			},
		});
	}

	public addQuestion(indexSection: number) {
		this.data.sections[indexSection].questions.push(JSON.parse(JSON.stringify(this.emptyQuestion())));
	}

	public removeOption(indexSection: number, indexQuestion: number, indexOption: number) {
		this.data.sections[indexSection].questions[indexQuestion].options.splice(indexOption, 1);
	}

	public addOption(indexSection: number, indexQuestion: number) {
		const newOption = {id: uuidv4(), option_title: "", correct: false};
		this.data.sections[indexSection].questions[indexQuestion].options.push(newOption);
	}

	public deleteSection() {
		// @ts-ignore
		Modal.confirm({
			title: `${this.$t("Delete section")} "${this.tabsContextData.label}" ?`,
			okText: this.$t("Delete"),
			onOk: () => {
				const sectionIndex = this.data.sections.findIndex(section => section.slug === this.tabsContextData.name );
				this.data.sections.splice(sectionIndex, 1);
				this.activeTab = this.data?.sections[0]?.slug; // TODO: Set next available tab to active
			},
		});
	}

	public editTabName() {
		// Opens tab name edit modal
		this.editTabNameBoolean = true;
		this.newTabName = this.tabsContextData.label;
	}

	public editSectionName() {
		if(this.newTabName.trim() === "") {
			// If empty section name
			return;
		}

		// When tab name edit modal is submitted
		const sectionIndex = this.data.sections.findIndex(section => section.slug === this.tabsContextData.name );
		this.data.sections[sectionIndex].title = this.newTabName;
		const sectionSlug = slugify(this.newTabName, { replacement: "_", lower: true });
		this.data.sections[sectionIndex].slug = sectionSlug;
		this.activeTab = sectionSlug;

		this.editTabNameBoolean = false;
		this.newTabName = "";
	}

	public addNewSection() {
		if(this.newTabName.trim() === "") {
			// If empty section name return
			return;
		}

		this.addingNewSection = false;
		const sectionSlug = slugify(this.newTabName, { replacement: "_", lower: true });

		// Empty new section
		const section = {
			slug: sectionSlug,
			title: this.newTabName,
			questions: [JSON.parse(JSON.stringify(this.emptyQuestion()))]
		};

		this.data.sections.push(section);
		this.activeTab = sectionSlug;
		this.newTabName = "";
	}

	public cancelAddNewTab() {
		this.addingNewSection = false;
		this.editTabNameBoolean = false;
		this.newTabName = "";
	}

	// On right click to section tab, get contextData (this is used for tab delete / tab edit)
	public handleContextMenu(data: any) {
		this.tabsContextData = data;
	}

	// Save new education or update existing one
	public async save() {
		const id = this.$route.params.id;

		if(id === "new" ) {
			// Save new education
			try {
				const { data: { _id} } =  await axios.post("/education/add", this.data);
				this.$router.push(`/super/education/${_id}`);
				this.errors = null;
				this.origEducatonData = JSON.parse(JSON.stringify(this.data));
				this.isModified = false;
				// @ts-ignore
				Message.success({
					content: this.$t("Saved"),
					duration: 10,
					background: true
				});
				return;
			} catch (err: any) {
				if(err?.response) {
					this.errors = err?.response.data;
					console.error(err.response?.data);
					return;
				}
				// @ts-ignore
				Message.error({
					content: err.message,
					duration: 10,
					background: true
				});
				console.error(err);
				return;
			}
		}

		// Update education
		try {
			this.loading = true;
			await axios.patch(`/education/${id}`, this.data);
			this.errors = null;
			this.origEducatonData = JSON.parse(JSON.stringify(this.data));
			this.isModified = false;
			this.loading = false;

			// @ts-ignore
			Message.success({
				content: this.$t("Saved"),
				duration: 10,
				background: true
			});
		} catch (err: any) {
			this.loading = false;
			if(err?.response) {

				if(err?.response?.status === 404) {
				// @ts-ignore
					Message.error({
						content: this.$t("Education not found"),
						duration: 10,
						background: true
					});
					this.isModified = false;
					this.$router.push("/super/education");
					return;
				}

				this.errors = err?.response.data;
				console.error(err.response?.data);
				return;
			}
			// @ts-ignore
			Message.error({
				content: err.message,
				duration: 10,
				background: true
			});
			return;
		}
	}

	// When leaving page in dirty state, confirm
	public beforeRouteLeave(to: any, from: any, next: any) {
		if (this.isModified) {
			// @ts-ignore
			Modal.confirm({
				title: this.$t("Leave page?"),
				content: this.$t("Do you really want to leave? You have unsaved changes!"),
				onOk: () => {
					next();
				},
				onCancel: () => {
					next(false);
				}
			});
		} else {
			next();
		}
	}
}
