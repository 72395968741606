
































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
// import iso6391 from "iso-639-1";
import Languages from "../../libraries/languages";
// @ts-ignore
import { countries } from "country-data-list";
import JsonViewer from "vue-json-viewer";
import {
	Divider,
	Option,
	Select,
	Rate,
	Time,
	Poptip,
	Tooltip,
	Button,
	ButtonGroup,
	Modal,
	Table,
	Tag,
	Spin,
	Input,
	Form,
	FormItem,
	RadioGroup,
	Radio,
	Card,
	Switch,
	Icon,
	Sider,
	MenuItem,
	Menu,
	Submenu,
	Content,
	Layout,
	Collapse,
	DatePicker,
	Checkbox,
	// @ts-ignore
	Panel,
	Page
} from "view-design";

@Component({
	components: {
		Divider,
		Option,
		Select,
		JsonViewer,
		Poptip,
		Rate,
		Time,
		Tooltip,
		Button,
		ButtonGroup,
		Table,
		Modal,
		Tag,
		Spin,
		Input,
		Form,
		FormItem,
		RadioGroup,
		Radio,
		Card,
		Icon,
		Sider,
		Menu,
		MenuItem,
		Submenu,
		Content,
		Layout,
		Collapse,
		Panel,
		DatePicker,
		Checkbox,
		"i-switch": Switch,
		Page
	}
})
export default class MessagePreview extends Vue {
	public loading = true;
	public iso6391 = new Languages();
	public countries = countries;
	public messagesData: any = [];
	public messagesColumns: any = [];
	public params: any = {
		limit: 200,
		language: "ALL",
		country: "",
		location: "",
		original: true,
		find: "",
		intent: "",
		intentConfidence: "100",
		entity: "",
		entityValue: "",
		dateStart: "",
		dateEnd: ""
	};
	public languageCodes: any = [];
	public countryCodes: any = [];
	public dateRange = [];
	public intentList: any = [];
	public fullIntentList: any = [];
	public entityList: any = [];
	public fullEntityList: any = [];
	public entityValueList: any = [];
	public countryList: any = [];
	public locationList: any = [];
	public overrideLimit = false;
	public limitBoxDisabled = true;
	public disableIntentSearch = false;
	public disableEntitySearch = false;
	public entities: any = {};
	public configs: any = [];

	// Page Control
	public total = 0;
	public currentPage = 1;
	public pageList: any = [];
	public pageSize = 10;
	public pageSizeOpts = [10, 50, 100, 200];

	public async created() {
		this.messagesColumns = [
			{
				title: this.$t("Created"),
				key: "createdAt",
				width: 150,
				resizable: true,
				render: (h: any, params: any) => {
					const createdDate = new Date(params.row.createdAt);
					const datetimeStr = `${createdDate.toLocaleDateString()} ${createdDate.toLocaleTimeString([], { hour12: false })}`;
					return h("span", datetimeStr);
				}
			},
			{
				title: this.$t("Location"),
				key: "location",
				width: 120
			},
			{
				title: this.$t("Language"),
				key: "language",
				width: 100,
				render: (h: any, params: any) => h("span", this.iso6391.getName(params.row.language))
			},
			{
				title: this.$t("Original Text"),
				key: "original",
				resizable: true
			},
			{
				title: this.$t("Translated Text"),
				key: "english"
			},
			{
				title: this.$t("Intent"),
				key: "intent"
			},
			{
				title: this.$t("Confidence"),
				key: "intents",
				render: (h: any, params: any) => {
					const intentsArr = [];
					if (typeof params.row.intents !== "undefined") {
						for (const intent of params.row.intents) {
							const confidence = parseFloat(intent.confidence.$numberDecimal).toLocaleString(undefined, { style: "percent", minimumFractionDigits: 2 });
							intentsArr.push(`${confidence}`);
						}
						return h("span", intentsArr);
					}
				}
			},
			{
				title: this.$t("Entities"),
				key: "intents",
				render: (h: any, params: any) => {
					const intentsArr = [];
					if (typeof params.row.intents !== "undefined") {
						for (const entity of params.row.entities) {
							const confidence = parseFloat(entity.confidence).toLocaleString(undefined, { style: "percent", minimumFractionDigits: 2 });
							intentsArr.push(h("div", { style: { marginTop: "5px", marginBottom: "2px" } }, [h("div", `Entity: ${entity.entity}`), h("div", `Confidence: ${confidence}`), h("div", `Value: ${entity.value}`)]));
						}
						return h("div", intentsArr);
					}
				}
			}
		];
		const languageStart = new Date("2020-01-01T00:00:00Z");
		const languageEnd = new Date();
		languageEnd.setHours(0);
		languageEnd.setMinutes(0);
		languageEnd.setSeconds(0);
		languageEnd.setMilliseconds(0);
		// languageStart.setFullYear(languageStart.getFullYear() - 1);

		console.log("Params", this.$route.query);
		if (typeof this.$route.query.original === "string") {
			if (this.$route.query.original === "false") {
				this.params.original = false;
			}
		}

		if (typeof this.$route.query.regex === "string") {
			this.params.find = this.$route.query.regex;
		}

		if (typeof this.$route.query.location === "string") {
			this.params.location = this.$route.query.location.toLowerCase();
		}
		// Get all entity configs
		await Axios.get("/super/messagepreview/getentityconfigs")
			.then(res => {
				if (typeof res.data === "object") {
					this.configs = res.data;
				} else {
					this.configs = {};
				}
			});

		Axios.post("/super/messagepreview/languages", {
			start: languageStart,
			end: languageEnd
		}).then(res => {
			this.languageCodes = res.data.languages;
		});

		Axios.get("/super/messagepreview/entities").then(res => {
			if (typeof res.data === "object") {
				const entityList = Object.keys(res.data);

				for (const item of entityList) {
					let description = "";
					for (const entity of this.configs) {
						if (typeof entity.config === "undefined" || typeof entity.config.entities === "undefined") {
							continue;
						}
						const keys = Object.keys(entity.config.entities);
						if (keys.includes(item)) {
							description = entity.config.entities[item].name[this.$i18n.locale];
							break;
						}
					}
					if (description === "") {
						description = item;
					}
					this.fullEntityList.push({ name: description, value: item, values: res.data[item]});
					description = "";
				}
				this.fullEntityList.sort((a: any, b: any) => {
					if (a.name < b.name) {
						return -1;
					}
					if (a.name > b.name) {
						return 1;
					}

					// names must be equal
					return 0;
				});
				this.entityList = this.fullEntityList;
			}
		});

		// Add T1 and XX country codes as these are custom but nessecary for the interface
		Object.assign(this.countries, {
			"XX": {
				name: "World"
			},
			"T1": {
				name: "Tor client"
			},
			"X1": {
				name: "CF_World"
			},
			"00": {
				name: "local"
			}
		});

		this.countryCodes = countries.all;
		for (let i = 0; i < this.countryCodes.length; i++) {
			this.countryCodes.id = i;
		}

		Axios.get("/super/messagepreview/intents").then(res => {
			const intentList = res.data;

			for (const item of intentList) {
				let description = "";
				for (const entity of this.configs) {
					if (typeof entity.config === "undefined" || typeof entity.config.intents === "undefined") {
						continue;
					}
					const keys = Object.keys(entity.config.intents);
					if (keys.includes(item)) {
						description = entity.config.intents[item].name[this.$i18n.locale];
						break;
					}
				}
				if (description === "") {
					description = item;
				}
				this.fullIntentList.push({ name: description, value: item});
				description = "";
			}
			this.fullIntentList.sort((a: any, b: any) => {
				if (a.name < b.name) {
					return -1;
				}
				if (a.name > b.name) {
					return 1;
				}

				// names must be equal
				return 0;
			});
			this.intentList = this.fullIntentList;
		});

		Axios.get("/super/messagepreview/countries").then(res => {
			this.countryList = res.data;
		});

		Axios.get("/super/messagepreview/locations").then(res => {
			this.locationList = res.data;
		});

		this.loadMessages();
	}

	public loadMessages() {
		this.loading = true;

		this.params.limit = 400;

		// Remove this parameter if it is empty
		if (this.params.country === "") {
			delete this.params.country;
		}

		const params = { ...this.params };
		if (typeof this.dateRange !== "undefined") {
			params.dateStart = this.dateRange[0];
			params.dateEnd = this.dateRange[1];
		}

		console.table(params);
		Axios.post("/super/messagepreview", params)
			.then(res => {
				if (typeof res.data === "object") {
					this.messagesData = res.data;
					this.total = Object.keys(this.messagesData).length;
					this.pageList = this.getPageList();
				} else {
					// @ts-ignore
					throw new Error(this.$t("Error communicating with the server."));
				}
				this.loading = false;
			})
			.catch(e => {
				this.loading = false;
				// @ts-ignore
				Modal.error({
					title: "Error",
					content: e.message
				});
			});
	}

	public csvExport() {
		// Remove this parameter if it is empty
		if (this.params.country === "") {
			delete this.params.country;
		}

		// TODO: Interface bug causes this to be undefined - fix pending
		if (typeof this.params.entityValue === "undefined") {
			this.params.entityValue = "";
		}

		const params = { ...this.params };
		if (typeof this.dateRange !== "undefined") {
			params.dateStart = this.dateRange[0];
			params.dateEnd = this.dateRange[1];
		}

		Axios.post("/super/messagepreview/csv", params)
			.then(res => {
				const filename = `message-data-export-${Date.now().toString()}.csv`;

				const file = new Blob([res.data], { type: "text/csv" });
				// @ts-ignore
				if (window.navigator.msSaveOrOpenBlob) {
					// @ts-ignore
					window.navigator.msSaveOrOpenBlob(file, filename);
				} else {
					const a = document.createElement("a");
					const url = URL.createObjectURL(file);

					a.href = url;
					a.download = filename;
					document.body.appendChild(a);
					a.click();
					setTimeout(() => {
						document.body.removeChild(a);
						window.URL.revokeObjectURL(url);
					}, 0);
				}
			})
			.catch(e => {
				// @ts-ignore
				Modal.error({
					title: "Error",
					content: e.message
				});
			});
	}

	// get page data
	public getPageList() {
		return this.messagesData.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
	}

	public changePage(p: number) {
		this.currentPage = p;
		this.pageList = this.getPageList();
	}

	public onPageSizeChange(pSize: number) {
		this.pageSize = pSize;
		this.currentPage = 1;
		this.pageList = this.getPageList();
	}

	public onLocationChange() {
		const intentList = [];
		const entityList = [];
		// Get specific intents and entities to this location
		const index = this.configs.findIndex((item: any) => item.location === this.params.location);
		if (index !== -1 && typeof this.configs[index].config !== "undefined" && typeof this.configs[index].config.intents !== "undefined") {
			const intents = Object.keys(this.configs[index].config.intents);
			const config = this.configs[index].config.intents;

			for (const intent of intents) {
				if (typeof config[intent] !== "undefined" && typeof config[intent].name !== "undefined" && typeof config[intent].name[this.$i18n.locale] !== "undefined") {
					intentList.push({ name: config[intent].name[this.$i18n.locale], value: intent});
				} else {
					intentList.push({ name: intent, value: intent });
				}
			}
			intentList.sort((a: any, b: any) => {
				if (a.name < b.name) {
					return -1;
				}
				if (a.name > b.name) {
					return 1;
				}

				// names must be equal
				return 0;
			});
			this.intentList = intentList;
		} else {
			this.intentList = this.fullIntentList;
		}

		if (index !== -1 && typeof this.configs[index].config !== "undefined" && typeof this.configs[index].config.entities !== "undefined") {
			const entities = Object.keys(this.configs[index].config.entities);
			const config = this.configs[index].config.entities;

			for (const entity of entities) {
				if (typeof config[entity] !== "undefined" && typeof config[entity].name !== "undefined" && typeof config[entity].name[this.$i18n.locale] !== "undefined") {
					entityList.push({ name: config[entity].name[this.$i18n.locale], value: entity, values: config[entity].values});
				} else {
					entityList.push({ name: entity, value: entity });
				}
			}
			entityList.sort((a: any, b: any) => {
				if (a.name < b.name) {
					return -1;
				}
				if (a.name > b.name) {
					return 1;
				}

				// names must be equal
				return 0;
			});
			this.entityList = entityList;
		} else {
			this.entityList = this.fullEntityList;
		}
	}

	public onLocationClear() {
		this.intentList = this.fullIntentList;
		this.entityList = this.fullEntityList;
	}

	public onEntityChange() {
		if (typeof this.entityList === "object") {
			if (typeof this.params.entity !== "undefined") {
				this.entityValueList = [];
				const index = this.entityList.findIndex((value: any) => value.name === this.params.entity);
				if (index !== -1 && typeof this.entityList[index] !== "undefined") {
					for (const value of this.entityList[index].values) {
						this.entityValueList.push(value);
					}
				}
			} else {
				this.entityValueList = [];
			}
		}
	}

	public onEntityClear() {
		this.params.entityValue = "";
	}

	public onEmptyIntentSearchChecked() {
		if (this.disableIntentSearch) {
			this.params.intent = "NULL_EMPTY";
			this.params.intentConfidence = "100";
		} else {
			this.params.intent = "";
		}
	}

	public onEmptyEntitySearchChecked() {
		if (this.disableEntitySearch) {
			this.params.entity = "NULL_EMPTY";
			this.params.entityValue = "" as string;
		} else {
			this.params.entity = "";
			this.params.entityValue = "";
		}
	}

	public onLimitBoxChecked() {
		if (this.overrideLimit) {
			// @ts-ignore
			Modal.warning({
				title: this.$t("Database Warning"),
				content: this.$t("Overriding the limit may cause server performance issues if the number of records to be retrieved is higher than 400 in the CSV export.")
			});
			this.limitBoxDisabled = false;
		} else {
			this.limitBoxDisabled = true;
		}
	}
}
