



































































































































import { Component, Vue } from "vue-property-decorator";
import { Alert, Switch, Timeline, TimelineItem } from "view-design";
import Axios from "axios";

@Component({
	components: {
		Alert,
		iSwitch: Switch,
		Timeline,
		TimelineItem
	}
})
export default class Content extends Vue {
	public features: any = [];
	public split = 0.5;
	public year = 2020;
	public changes: any = {};
	public showDone: boolean = false;

	public async created() {
		this.year = new Date().getFullYear();
		// Hack
		await Axios.get(`/content/changes/${this.year - 1}`).then(res => {
			if (typeof res.data === "object") {
				this.changes = res.data.changes.map((change: { year: number }) => {
					change.year = this.year - 1;
					return change;
				});
			}
		});
		Axios.get(`/content/changes/${this.year}`).then(res => {
			if (typeof res.data === "object") {
				this.changes = {
					...this.changes,
					...res.data.changes.map((change: { year: number }) => {
						change.year = this.year;
						return change;
					})
				};
			}
		});
	}

	private accept(item: any) {
		return this.state(item, "accepted");
	}

	private deny(item: any) {
		return this.state(item, "denied");
	}

	private undo(item: any) {
		return this.state(item, "pending");
	}

	private async state(item: any, state: string) {
		item.loading = true;
		this.$forceUpdate();
		const result = await Axios.patch(`/content/changes/${item._id}/state`, { state }).then(res => res.data);
		item.state = result.state;
		item.loading = false;
		this.updatePending();
		this.$forceUpdate();
	}

	private updatePending() {
		for (const week of this.changes) {
			week.pending = typeof week.items.find((item: any) => item.state === "pending") !== "undefined";
		}
	}
}
