



























import { Component, Vue } from "vue-property-decorator";
import { Card, Form, FormItem, Input, Icon, Button, Menu, MenuItem, Spin } from "view-design";
import Axios from "axios";

@Component({
	components: {
		Card,
		Form,
		FormItem,
		Input,
		Icon,
		Button,
		Menu,
		MenuItem
	}
})
export default class AccountSwitch extends Vue {
	public entities = [];
	public selEntity: string = "";

	public async created() {
		// Get list of EntityId's the user has access to
		// Format: [{ EntityId, Entity Name }]
		// @ts-ignore
		Spin.show();
		await Axios.get("/accountswitch/entities").then(async (res: any) => {
			this.entities = res.data[0].entities;
			// @ts-ignore
			Spin.hide();
		});
		this.selEntity = this.$store.state.entity;
	}
	/*
	public async handleSubmit() {
		// @ts-ignore
		Spin.show();
		await Axios.post("/accountswitch/switchentity", { entityId: this.selEntity })
			.then(res => {
				// @ts-ignore
				Spin.hide();
				// get res.data.entityId
				const entityId = res.data.entityId;
				this.$store.commit("setEntity", entityId);
				// put new entityId into $store
				// save it to LocalStorage
				window.localStorage.setItem("entity", entityId as string);
				// Now route to home
				this.$router.replace("/");
			})
			.catch(e => {
				alert(e);
			});
	}
	*/

	public async switchAccount(entity: string) {
		console.log("Selected entity", entity);
		// @ts-ignore
		Spin.show();
		await Axios.post("/accountswitch/switchentity", { entityId: entity })
			.then(res => {
				// @ts-ignore
				Spin.hide();
				// get res.data.entityId
				const entityId = res.data.entityId;
				this.$store.commit("setEntity", entityId);
				// put new entityId into $store
				// save it to LocalStorage
				window.localStorage.setItem("entity", entityId as string);

				console.log(res.data.permissions);
				// Now route to home
				this.$router.replace("/");
			})
			.catch(e => {
				alert(e);
			});
	}
}
