































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Alert, Divider, Card, ButtonGroup, Button, DatePicker, Split, Timeline, TimelineItem, Row, Col, Input } from "view-design";
import Axios from "axios";

@Component({
	components: {
		Alert,
		Divider,
		Card,
		ButtonGroup,
		"i-button": Button,
		Split,
		Timeline,
		TimelineItem,
		"i-row": Row,
		"i-col": Col,
		"i-input": Input,
		DatePicker
	}
})
export default class ContentChanges extends Vue {
	public features: any = [];
	public split = 0.5;
	public year = 2020;
	public changes: any = {};
	public adding: boolean = false;
	public add = {
		state: "pending",
		old: "<em>old</em>",
		new: "<em>new</em>",
		date: new Date(),
		url: "",
		title: "",
		loading: false
	};

	public created() {
		void this.load();
	}

	private async load() {
		this.year = new Date().getFullYear();
		await Axios.get(`/content/changes/${this.year - 1}`).then(res => {
			if (typeof res.data === "object") {
				this.changes = res.data.changes.map((change: { year: number }) => {
					change.year = this.year - 1;
					return change;
				});
			}
		});
		await Axios.get(`/content/changes/${this.year}`).then(res => {
			if (typeof res.data === "object") {
				this.changes = {
					...this.changes,
					...res.data.changes.map((change: { year: number }) => {
						change.year = this.year;
						return change;
					})
				};
			}
		});
	}

	private accept(item: any) {
		return this.state(item, "accepted");
	}

	private deny(item: any) {
		return this.state(item, "denied");
	}

	private undo(item: any) {
		return this.state(item, "pending");
	}

	private async state(item: any, state: string) {
		item.loading = true;
		this.$forceUpdate();
		const result = await Axios.patch(`/content/changes/${item._id}/state`, { state }).then(res => res.data);
		item.state = result.state;
		item.loading = false;
		this.updatePending();
		this.$forceUpdate();
	}

	private async save(item: any) {
		item.loading = true;
		this.$forceUpdate();
		try {
			const result = await Axios.patch(`/content/changes/${item._id}`, item).then(res => res.data);
			item.loading = false;
			item = result;
		} catch (e) {
			if (e.response?.data?.error === "ValidatingError") {
				alert(e.response.data.message + ": " + e.response.data.errors.map((error: any) => error.msg).join());
			} else {
				alert(e.message + " " + e.response.data.message);
			}

			item.loading = false;
		}
		this.updatePending();
		this.$forceUpdate();
	}

	private cancel() {
		this.add = {
			state: "pending",
			old: "<em>old</em>",
			new: "<em>new</em>",
			date: new Date(),
			url: "",
			title: "",
			loading: false
		};
	}
	private async post() {
		this.add.loading = true;
		this.$forceUpdate();
		try {
			void (await Axios.post("/content/changes", this.add).then(res => res.data));
			this.add = {
				state: "pending",
				old: "<em>old</em>",
				new: "<em>new</em>",
				date: new Date(),
				url: "",
				title: "",
				loading: false
			};
			this.adding = false;
		} catch (e) {
			try {
				if (e.response?.data?.error === "ValidatingError") {
					alert(e.response.data.message + ": " + e.response.data.errors.map((error: any) => error.msg).join());
				} else {
					alert(e.message + " " + e.response.data.message);
				}
			} catch (e2) {
				console.error(e2);
			}

			this.add.loading = false;
		}
		await this.load();
		this.updatePending();
		this.$forceUpdate();
	}

	private async remove(item: any) {
		item.loading = true;
		this.$forceUpdate();
		void (await Axios.delete(`/content/changes/${item._id}`).then(res => res.data));
		await this.load();
		this.updatePending();
		this.$forceUpdate();
	}

	private updatePending() {
		for (const week of this.changes) {
			week.pending = typeof week.items.find((item: any) => item.state === "pending") !== "undefined";
		}
	}
}
