



















































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import {
	Option,
	Select,
	Time,
	Tooltip,
	Modal,
	Table,
	Radio,
	Message
} from "view-design";

@Component({
	name: "EducationModules",
	components: {
		Option,
		Select,
		Time,
		Tooltip,
		Table,
		Modal,
		Radio,
		Message
	}
})
export default class EducationModules extends Vue {
	public loading = true;
	public educationsData: any = [];
	public educationColumns: any = [];

	public async created() {
		this.educationColumns = [
			{
				title: this.$t("Created"),
				key: "createdAt",
				slot: "datetime",
				sortable: true,
				width: 150,
				resizable: true
			},
			{
				title: this.$t("Updated"),
				key: "updatedAt",
				slot: "datetimeUpdated",
				sortable: true,
				width: 150,
				resizable: true
			},
			{
				title: this.$t("Title"),
				key: "title",
				sortable: true,
				// width: 200,
				resizable: true
			},
			{
				title: this.$t("Locations"),
				key: "locations",
				slot: "locations",
				// width: 300,
				resizable: true
			},
			{
				title: this.$t("Intents which offers education"),
				key: "intents",
				slot: "intents",
				// width: 300,
				resizable: true
			},
			{
				title: this.$t("Actions"),
				slot: "actions",
				width: 180
			}
		];

		await this.loadEducations();
	}

	public async loadEducations() {
		this.loading = true;
		try {
			const { data: { educations } } =  await axios.get("/education");
			this.educationsData = educations;
			this.loading = false;
		} catch (err) {
			console.error(err);
			this.loading = false;
		}
	}

	public remove(id: string) {
		const title = this.educationsData.find((education: { _id: string }) => education._id === id).title;

		// TODO: Debug why education delete runs twice (related to options request)
		// When deleting education: error message "Education not found, can be already deleted"

		// @ts-ignore
		Modal.confirm({
			title: `${this.$t("Delete education")} "${title}" ?`,
			okText: this.$t("Delete"),
			onOk: async () => {
				try {
					await axios.delete(`/education/${id}`);
					// @ts-ignore
					Message.success({
						content: this.$t("Education deleted"),
						duration: 10,
						background: true
					});
				} catch (err: any) {
					if(err?.response?.status === 404) {
						// this.$router.go(0);
						// @ts-ignore
						Message.error({
							content: this.$t("Education not found, can be already deleted"),
							duration: 10,
							background: true
						});
						return;
					}
					console.error(err);
				}
			}
		});
	}
}
