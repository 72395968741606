































































import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { Modal, Tooltip, Time, Table, CheckboxGroup, Checkbox, Spin, Tag, Input, ModalConfig } from "view-design";
import { CreateElement } from "vue";

@Component({
	components: {
		Table,
		Tooltip,
		Time,
		Modal,
		CheckboxGroup,
		Checkbox
	}
})
export default class Admin extends Vue {
	public loading = true;
	public admins = [];
	public newAdminEmail = "";
	public cols: any = [];
	public modifyingPermissions = false;
	public modifyRow: any = {};
	public modifyPerms: any = [];
	public permissions = {
		"stats": {
			description: "Has permission to view statistics"
		},
		"admin:write": {
			description: "Has permission to create and modify other admins"
		},
		"admin:read": {
			description: "Has permission to view other admins"
		},
		"super:read": {
			description: "Has super user viewing permissions"
		},
		"super:write": {
			description: "Super"
		},
		"content-changes:write": {
			description: "Has permission to create and modify content changes as an admin"
		},
		"content-changes:read": {
			description: "Has permission to view content changes"
		},
		"content-changes:approval": {
			description: "Has permission to approve or deny content changes"
		},
		"reporting": {
			description: "Has permission to run reports"
		}
	};

	public created() {
		this.cols = [
			{
				title: this.$t("Email"),
				key: "email"
			},
			{
				title: this.$t("Created"),
				key: "createdAt",
				slot: "timestamp"
			},
			{
				title: this.$t("Last Modified"),
				key: "updatedAt",
				slot: "timestamp"
			},
			{
				title: this.$t("Permissions"),
				key: "permissions",
				render: (h: CreateElement, params: any) => {
					const entity: string | null | void = window.localStorage.getItem("entity");
					if (entity !== null) {
						const index = params.row.entities.findIndex((ent: any) => ent.entityId === entity);
						const permissionTags = [];
						for (const permission of params.row.entities[index].permissions) {
							permissionTags.push(h(Tag, permission));
						}
						return h("div", permissionTags);
					}
				}
			},
			{
				title: this.$t("Actions"),
				fixed: "right",
				render: (h: CreateElement, params: any) =>
					h("div", [
						h(
							"Button",
							{
								props: {
									type: "default",
									size: "small"
								},
								on: {
									click: () => {
										this.modifyPermissions(params.row);
									}
								}
							},
							this.$t("Modify Permissions") as string
						),
						h(
							"Button",
							{
								props: {
									type: "default",
									size: "small"
								},
								on: {
									click: () => {
										this.resetPassword(params.row._id);
									}
								}
							},
							this.$t("Reset Password") as string
						),
						h(
							"Button",
							{
								props: {
									type: "error",
									size: "small"
								},
								on: {
									click: async () => {
										if (confirm(this.$t("Are you sure you want to remove all of {email} permissions?", { email: params.row.email }) as string)) {
											// @ts-ignore
											Spin.show();
											Axios.delete(`/admin/${encodeURIComponent(params.row._id)}`)
												.then(_res => {
													// @ts-ignore
													Modal.success({
														title: this.$t("User permissions removed!")
													});
													this.loadAdmins();
												})
												.catch(e => {
													// @ts-ignore
													Modal.error({
														title: this.$t("Error"),
														content: this.$t("Revoking user permissions failed.") + " " + e
													});
												});
											// @ts-ignore
											Spin.hide();
										}
									}
								}
							},
							this.$t("Remove") as string
						)
					])
			}
		];
		this.loadAdmins();
	}

	public loadAdmins() {
		Axios.get("/admin").then(res => {
			if (res.data.success === true) {
				this.admins = res.data.admins;
			} else {
				throw this.$t("Error communicating with the server.");
			}
			this.loading = false;
		});
	}

	public addUser(permissions: any = ["stats"]) {
		this.newAdminEmail = "";
		// @ts-ignore
		Modal.confirm({
			okText: this.$t("Ok"),
			cancelText: this.$t("Cancel"),
			render: (h: CreateElement | undefined) =>
				h?.(Input, {
					props: {
						value: this.newAdminEmail,
						autofocus: true,
						placeholder: this.$t("Email address of the new account")
					},
					on: {
						input: (val: string) => {
							this.newAdminEmail = val;
						}
					}
				}),
			onOk: () => {
				// @ts-ignore
				Spin.show();
				Axios.post("/admin/add", {
					email: this.newAdminEmail,
					permissions
				})
					.then(async res => {
						// Hack to wait for the top level modal to close.
						await new Promise(resolve => setTimeout(resolve, 500));
						if (res.data.success === true && res.data.password === undefined) {
							// @ts-ignore
							new Modal.success({
								title: this.$t("Account added") as string,
								render: (h: CreateElement | undefined) => h?.("span", [h?.("span", (this.$t("Email:") as string) + " "), h?.("strong", this.newAdminEmail)])
							});
						} else if (res.data.success === true && res.data.password) {
							// @ts-ignore
							Modal.success({
								title: this.$t("New account created") as string,
								render: (h: CreateElement | undefined) => h?.("span", [h?.("span", (this.$t("Email:") as string) + " "), h?.("strong", this.newAdminEmail), h?.("br"), h?.("span", (this.$t("Password:") as string) + " "), h?.("strong", res.data.password), h?.("br"), h?.("small", this.$t("(This password is only shown once.)") as string)])
							});
						} else {
							// @ts-ignore
							new Modal.info({
								title: this.$t("Account already exists") as string,
								render: (h: CreateElement | undefined) => h?.("span", [h?.("span", (this.$t("Email:") as string) + " "), h?.("strong", this.newAdminEmail)])
							});
						}
						this.loadAdmins();
					})
					.catch(e => {
						if (e.response && e.response.data.error === "ValidatingError") {
							alert(this.$t("Invalid email address"));
						} else {
							alert(e);
						}
					})
					.finally(() => {
						// @ts-ignore
						Spin.hide();
					});
			}
		} as ModalConfig);
	}

	public async resetPassword(id: string) {
		// @ts-ignore
		Spin.show();
		Axios.post(`/admin/reset/${encodeURIComponent(id)}`)
			.then(res => {
				// @ts-ignore
				Modal.success({
					title: this.$t("Password Reset Request"),
					content: this.$t(res.data.message)
				});
			})
			.catch(e => {
				// @ts-ignore
				Modal.error({
					title: this.$t("Error"),
					content: this.$t("Resetting password.") + " " + e
				});
			});
		// @ts-ignore
		Spin.hide();
	}

	public modifyPermissions(row: any) {
		this.modifyingPermissions = true;
		// .entities[row.entities.findIndex((ent: any) => ent.entityId === window.localStorage.getItem("entity"))]
		this.modifyRow = row;
		this.modifyPerms = this.modifyRow.entities[this.modifyRow.entities.findIndex((ent: any) => ent.entityId === window.localStorage.getItem("entity"))].permissions;
	}

	public modifyPermissionsDone() {
		// @ts-ignore
		Spin.show();
		console.log(this.modifyRow);
		Axios.post(`/admin/permissions/${encodeURIComponent(this.modifyRow._id)}`, { permissions: this.modifyPerms })
			.then(_res => {
				this.loadAdmins();
			})
			.catch(e => {
				if (e.response && e.response.data.error === "ValidatingError") {
					alert("ValidatingError");
				} else {
					alert(e);
				}
			})
			.finally(() => {
				// @ts-ignore
				Spin.hide();
				this.modifyRow = {};
			});
	}
	public modifyPermissionsCancel() {
		this.modifyRow = {};
		this.loadAdmins();
	}
}
