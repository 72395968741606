import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		email: "",
		entity: "",
		entityName: ""
	},
	mutations: {
		setEmail(state, email) {
			state.email = email;
		},
		setEntity(state, entity) {
			state.entity = entity;
		},
		setEntityName(state, name) {
			state.entityName = name;
		}
	},
	actions: {}
});
