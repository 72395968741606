
























































import { Component, Vue } from "vue-property-decorator";
import { Modal, Notice, Spin, Card, Form, FormItem, Input, Icon, Button } from "view-design";
import sha1 from "sha1";
import Axios from "axios";

@Component({
	components: {
		Card,
		Form,
		FormItem,
		Input,
		Icon,
		Button
	}
})
export default class ResetPassword extends Vue {
	public formInline = {
		newPassword: "",
		newPasswordRepeat: ""
	};
	public token: string = "";
	public isValidToken = false;

	public created() {
		console.log("Route Query: ", this.$route.query.token);
		this.token = this.$route.query.token as string;
		// If token && verifyToken
		// then set isValidToken = true
		if (this.token && this.token !== "") {
			this.verifyToken();
		}
	}

	public async verifyToken() {
		console.log("verifyToken called");
		await Axios.post("/password/verifytoken", {
			token: this.token
		})
			.then((res: any) => {
				if (res.data.verified && res.data.verified === true) {
					this.isValidToken = true;
				}
			})
			.catch((_error: any) => {
				this.isValidToken = false;
			});
	}

	public changePassword() {
		Axios.post("/password/reset", {
			token: this.token,
			newPassword: this.formInline.newPassword
		})
			.then(async (_res: any) => {
				// @ts-ignore
				Spin.hide();
				// @ts-ignore
				Modal.success({
					title: this.$t("Password changed. Redirecting back to login.")
				});
				this.formInline.newPassword = "";
				this.formInline.newPasswordRepeat = "";
				this.$router.replace("/login");
			})
			.catch((error: any) => {
				let content = error;
				if (typeof error.response.data === "object") {
					if (error.response.data.error === "ValidatingError") {
						content = this.$t("Wrongly formatted password.");
					} else if (error.response.data.error === "AdminNotFound") {
						content = this.$t("Account not found.");
					} else {
						content = this.$t("Unknown error while changing password.");
					}
				}
				console.log(content);
				// @ts-ignore
				Spin.hide();
				// @ts-ignore
				Modal.error({
					title: this.$t("Error changing password"),
					content
				});
			});
	}

	public handleSubmit() {
		// @ts-ignore
		Spin.show();
		const hash = sha1(this.formInline.newPassword).toUpperCase();
		const queryHash = hash.substring(0, 5);
		fetch("https://api.pwnedpasswords.com/range/" + queryHash)
			.then(res => res.text())
			.then(async data => {
				if (data.includes(hash.substring(5, 41))) {
					// @ts-ignore
					Notice.error({
						duration: 0,
						title: "Dangerous Password",
						render: (h: any) =>
							h("span", [
								this.$t("We've noticed that the password you're trying to use isn't secure. If you use the same password elsewhere, it is also recommended that you change those immediately."),
								h("br"),
								h("a", {
									domProps: {
										innerHTML: this.$t("You can read more about the security of your password on the HIBP website.")
									},
									attrs: {
										href: "https://haveibeenpwned.com/Passwords",
										target: "_blank"
									}
								})
							]),
						desc: this.$t(" You can read more about the security of your password here: https://haveibeenpwned.com/Passwords")
					});
					// @ts-ignore
					Spin.hide();
				} else {
					this.changePassword();
				}
			})
			.catch(err => {
				console.error(err);
				this.changePassword();
			});
	}
}
