





































































































































import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
// import iso6391 from "iso-639-1";
import Languages from "../../libraries/languages";
import JsonViewer from "vue-json-viewer";
import {
	Option,
	Select,
	Rate,
	Time,
	Poptip,
	Tooltip,
	Modal,
	Table,
	RadioGroup,
	Radio,
	Collapse,
	Message,
	// @ts-ignore
	Panel
} from "view-design";

@Component({
	components: {
		Option,
		Select,
		JsonViewer,
		Poptip,
		Rate,
		Time,
		Tooltip,
		Table,
		Modal,
		RadioGroup,
		Radio,
		Collapse,
		Panel
	}
})
export default class Translations extends Vue {
	public loading = true;
	public iso6391 = new Languages();
	public translationsData: any = [];
	public translationsColumns: any = [];
	public params: any = {
		limit: 50,
		language: "fi",
		translated: true,
		find: ""
	};
	public manualOnly = false;
	public languageCodes: any = [];

	public created() {
		this.translationsColumns = [
			{
				title: this.$t("Created"),
				key: "createdAt",
				slot: "datetime",
				sortable: true,
				width: 150,
				resizable: true
			},
			{
				title: this.$t("Type"),
				key: "html",
				slot: "type",
				sortable: true,
				width: 100,
				resizable: true
			},
			{
				title: this.$t("Manual"),
				key: "manual",
				sortable: true,
				width: 125,
				resizable: true
			},
			{
				title: this.$t("Input"),
				key: "input",
				slot: "html",
				width: 300,
				resizable: true
			},
			{
				title: this.$t("Translation"),
				key: "translation",
				sortable: true,
				slot: "html"
			},
			{
				title: this.$t("Actions"),
				slot: "actions",
				width: 180
			}
		];
		const languageStart = new Date();
		const languageEnd = new Date();
		languageStart.setFullYear(languageStart.getFullYear() - 1);

		Axios.post("/stats/db/languages", {
			start: languageStart,
			end: languageEnd
		}).then(res => {
			this.languageCodes = res.data.languages;
		});

		this.loadTranslations();
	}

	public loadTranslations() {
		this.loading = true;
		if (this.manualOnly) {
			this.params.manual = true;
		} else {
			delete this.params.manual;
		}
		Axios.get("/super/translations", { params: { ...this.params } })
			.then(res => {
				if (typeof res.data === "object") {
					this.translationsData = res.data;
				} else {
					// @ts-ignore
					throw new Error(this.$t("Error communicating with the server."));
				}
				this.loading = false;
			})
			.catch(e => {
				this.loading = false;
				alert(e);
			});
	}

	public remove(id: string) {
		if (confirm(this.$t("Are you sure you want to delete this translation? This action can not be reverted!") as string)) {
			this.loading = true;
			Axios.delete(`/super/translations/${id}`)
				.then(res => {
					if (res.data._id === id) {
						// @ts-ignore
						Message.success({
							content: this.$t("Translation removed successfully!"),
							duration: 15,
							closable: true
						});
					} else {
						// @ts-ignore
						throw new Error(this.$t("Error communicating with the server."));
					}
					this.loadTranslations();
				})
				.catch(e => {
					this.loading = false;
					alert(e);
				});
		}
	}
}
